
import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/loader.css";
import Pagination from "../../Components/Pagination/Pagination";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { CSVLink } from "react-csv";
import companylogo from "../../assets/images/companylogo.png";
import LocalLoader from "../../Components/Loader/LocalLoader.js"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import { operatorList } from "../../utils/api";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import DatePicker from "react-datepicker";
require('react-datepicker/dist/react-datepicker.css')


const options = [
    { value: "Mobile", label: "Mobile" },
    { value: "DTH", label: "DTH" },
];


const headers = [
    { label: "Operator Name", key: "operatorName" },
    { label: "Operator Code", key: "operatorCode" },
    { label: "Operator Id", key: "operatorId" },
    { label: "Operator Type", key: "operatorType" },
];

const OperatorsTable = () => {
    const [operators, setOperators] = useState([]);
    const [pdfData, setPdfData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalElements, setTotalElements] = useState("");
    const [operatorName, setOperatorName] = useState("")
    const [currPage, setCurrPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [dropdownState, setDropdownState] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("Mobile");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState('');


    const navigate = useNavigate()

    const [errors, setErrors] = useState({
        startDate: "",
        endDate: "",
    });
    const link = useRef();


    const operatorsData = async (e, key = "") => {
        if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
            setErrors((prev) => ({
                ...prev,
                startDate: "From Date can't be greater than to Date",
            }));
            setOperators([])
            return
        }
        setLoading(true);
        const param = {
            fromDate: startDate,
            operatorName: operatorName,
            pageNo: currPage,
            pageSize: pageSize,
            serviceType: dropdownValue,
            toDate: endDate,
        }
        if (key === "CSV" || key === "PDF") {
            if ((key === "CSV")) {
                setDownloadLoader("CSV")
            } else {
                setDownloadLoader("PDF")
            }
            param.pageSize = totalElements;
            param.pageNo = 0;
        }
        if (key === "search") {
            param.pageNo = 0;
        } else {
            setLoading(true)
        }
        if (key === "refresh") {
            dataRefresh()
            param.fromDate = "";
            param.operatorName = "";
            param.serviceType = "";
            param.toDate = "";

        }

        operatorList(param).then((resp) => {
            setLoading(false);
            setDownloadLoader("");

            if (resp?.data?.success) {
                if (key === "CSV") {
                    setPdfData(resp?.data?.data?.content);
                    setTimeout(() => {
                        const csvlink = link.current
                        csvlink.link.click();
                    }, 100)

                } else if (key === "PDF") {
                    operatorsPdfData(resp?.data?.data?.content)
                } else {
                    setOperators(resp?.data?.data?.content);
                    setTotalPages(resp?.data?.data?.totalPages);
                    setTotalElements(resp?.data?.data?.totalElements);
                }
            }
        })
    };

    useEffect(() => {
        operatorsData();
    }, [currPage, operatorName]);


    const operatorsPdfData = (data = []) => {
        // Format of Date
        var currentDate = new Date();
        var formattedDate = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');

        const doc = new jsPDF();

        //Add image in PDF header
        const imgWidth = 25;
        const imgHeight = 9;
        const leftXPos = 14;
        const yPos = 2;
        doc.addImage(companylogo, 'PNG', leftXPos, yPos, imgWidth, imgHeight);

        //Add fileName in the center
        const text = 'Operator List';
        // Calculate the width of the text
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // Get the width of the PDF
        const pdfWidth = doc.internal.pageSize.width;
        const yPos1 = 10;
        // Calculate the X position for center alignment
        const centerXPos = (pdfWidth - textWidth) / 2;
        doc.text(text, centerXPos, yPos1);
        // Calculate the width of the date text
        const dateWidth = doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // Calculate the X position for right alignment
        const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge           
        doc.text(formattedDate, rightXPos, yPos1);


        // Create a table from API data
        const tableData = [];

        data.forEach((item, index) => {
            const rowData = [index + 1, item.operatorName, item.operatorCode, item.operatorId, item.operatorType]; // Customize as needed
            tableData.push(rowData);
        });

        doc.autoTable({
            head: [['S.No', 'Name', 'Code', 'Operator ID', 'Operator Type']], //table header
            body: tableData,
        });

        doc.save('OperatorList.pdf');
    };

    const dataRefresh = () => {

        setStartDate("")
        setEndDate("")
        setOperatorName("")
        setDropdownValue("")

    }
    return (
        <div style={{ padding: "30px" }}>
            <div>
                <div className='operatorlist_head'>
                    <div className='operatorlist_heading'>
                        <div className='operatorlist_left'>
                            <label>Operator List</label>
                        </div>
                        <div className='operatorlist_right'>
                            {downloadLoader !== "PDF" ? <button className='pdf' onClick={(e) => operatorsData(e, "PDF")}
                            >PDF</button> :
                                <button className='pdf'>
                                    <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                </button>
                            }

                            {downloadLoader !== "CSV" ? <button className='xls' onClick={(e) => operatorsData(e, "CSV")}
                            >CSV</button> :
                                <button className='xls'>
                                    <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                </button>
                            }
                            <CSVLink
                                ref={link}
                                data={pdfData}
                                filename="operatorList.csv"
                            >

                            </CSVLink>
                        </div>
                    </div>

                </div>
                <FilterWrapper>
                    <div className='filterBox'>
                        <div className="">
                            <div className="flex align-items gap-20">
                                <div>
                                    <input
                                        type="text"
                                        name="operatorName"
                                        placeholder="Enter operator name"
                                        value={operatorName}
                                        onChange={(e) => setOperatorName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <Dropdown
                                        options={options}
                                        dd_name={"Phone No."}
                                        dropdownValue={dropdownValue}
                                        setDropdownValue={setDropdownValue}
                                        operatorsData={operatorsData}
                                        dropdownState={dropdownState}
                                        setDropdownState={setDropdownState}
                                    />
                                </div>


                                <div className="input-field">
                                    <div className="flex">

                                        <DatePicker
                                            showIcon
                                            className="date-picker2 date_type"
                                            placeholderText="From Date"
                                            selected={startDate}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            yearDropdownItemNumber={100}
                                            scrollableYearDropdown={true}
                                            showYearDropdown
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={date => setStartDate(date)}
                                            maxDate={new Date()}
                                        />

                                    </div>

                                </div>
                                <div className="input-field">
                                    <div className="flex" >

                                        <DatePicker
                                            showIcon
                                            className="date-picker2 date_type"
                                            placeholderText="To Date"
                                            selected={endDate}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            yearDropdownItemNumber={100}
                                            scrollableYearDropdown={true}
                                            showYearDropdown
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={date => setEndDate(date)}
                                            maxDate={new Date()}

                                        />
                                    </div>
                                </div>
                                <div >
                                    <button className="userlist_btn" onClick={(e) =>
                                        operatorsData(e, "search")}
                                    >search</button>
                                </div>
                                <div >
                                    <button className='refresh-btn'
                                        onClick={(e) => operatorsData(e, "refresh")}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </FilterWrapper>

            </div>
            <div className='transaction_table'>
                <table id="customers">
                    <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Operator ID</th>
                        <th>Operator Icon</th>
                        <th>Operator Type</th>
                        <th>Actions</th>
                    </tr>
                    {loading && <tr>
                        <td colSpan={14}>
                            <LocalLoader />
                        </td>
                    </tr>

                    }

                    {!loading && operators && operators?.map((item, i) => (
                        <tr className="border" key={item.transactionId}>
                            <td>{(currPage * pageSize) + i + 1}</td>
                            <td>{item?.operatorName}</td>
                            <td>{item?.operatorCode}</td>
                            <td>{item?.operatorId}</td>
                            <td>
                                <img style={{ width: '40px', borderRadius: "5px" }} src={item.operatorImage} alt="" />
                            </td>
                            <td>{item?.operatorType || 'N/A'}</td>
                            <td className="user_action_btn">
                                <button
                                    className=""
                                    style={{ marginRight: "5px" }}
                                >
                                    <i class="fa-solid fa-pen-to-square update"
                                        onClick={() => navigate("/editoperatorList", { state: item })}
                                    ></i>
                                </button>
                                <button
                                // onClick={() => changeStatus}
                                >
                                    <i class="fa-regular fa-lightbulb"
                                    ></i>
                                </button>
                                {/* Approved */}
                            </td>
                        </tr>
                    ))}
                    {
                        operators && (operators.length < 1) &&
                        <>
                            <tr>
                                <td colSpan={14} style={{ textAlign: "center", color: "red", height: "120px", fontSize: "20px" }}>No Data Found</td>
                            </tr>
                        </>
                    }

                </table>
            </div>
            {totalPages > 1 &&
                <Pagination
                    items={operators}
                    itemsPerPage={pageSize}
                    setitemsPerPage={setPageSize}
                    currentPage={currPage}
                    setcurrentPage={setCurrPage}
                    totalPages={totalPages}
                />}

        </div>

    );
};

export default OperatorsTable;
