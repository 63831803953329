import React from "react";
import IconAeps from "../../assets/images/IconAeps";
import AadharIcon from "../../assets/images/AadharIcon.jpg";
import Recharge from "../../assets/images/Recharge.jpg";
import Bills from "../../assets/images/Bills.jpg";
import payout from "../../assets/images/Payout.jpg";
import utiregistration from "../../assets/images/UTI_Registration.jpg";
import uticoupon from "../../assets/images/UTi_Coupon.jpg";
import dmr from "../../assets/images/DMR.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { getServiceDetail } from "../../utils/api";
import { isEmpty } from "../../utils/common";
import { sum } from "pdf-lib";


export default function PaymentCards(serviceData) {


    const [serviceDetails,setServiceDetails] = useState({});
    const [bppsRechargeCount,setBbpsRechargeCount] = useState(0);
    const [sumBbpsRecharge, setSumBbpsRecharge] = useState(0);
    const [sumPrepaidRecharge,setSumPrepaidRecharge] = useState(0);
    const [countPrepaidRecharge,setCountPrepaidRecharge] =useState(0);

    
   
    
    let aepsamount=serviceData?.serviceData?.aepsTransaction?.cash_withdrawal?.amount;  
    let sendmoney = serviceData?.serviceData?.moneyTransfer?.send_money?.amount;
    let payOut = serviceData?.serviceData?.moneyTransfer?.pay_out?.amount;
    let settlement = serviceData?.serviceData?.moneyTransfer?.settlement?.amount;
    let creditCardBill = serviceData?.serviceData?.bbpsCollection?.credit_card_bill?.amount;
    let prepaidRecharge = serviceData?.serviceData?.rechargeCollection?.prepaid_recharge?.amount;
    let prepaidRechargeCount = serviceData?.serviceData?.rechargeCollection?.prepaid_recharge?.count
    




    let bbpsDataSum =serviceData?.serviceData?.bbpsCollection;
    const sumOfBbpsRecharge =()=>{

        if(!isEmpty(bbpsDataSum)){
        let bbpsdata=Object.values(bbpsDataSum)
        let sum = 0,count= 0;
        bbpsdata.map((index)=>{
            sum=sum+index.amount;
            count = count +index.count;

        })
        let finalSum = sum - (serviceData?.serviceData?.bbpsCollection?.credit_card_bill?.amount) 
        let finalCount = count - (serviceData?.serviceData?.bbpsCollection?.credit_card_bill?.count)      
        setSumBbpsRecharge(finalSum);
        setBbpsRechargeCount(finalCount);
        return finalSum, finalCount;

     }     else{
        setBbpsRechargeCount(0);
        setSumBbpsRecharge(0)
     }
     
    }
   

    // let prepaidRecharge = serviceData?.serviceData?.rechargeCollection
    // const sumOfPrepaidRecharge =()=>{
    //     if(!isEmpty(prepaidRecharge)){
    //         let prepaidData=Object.values(prepaidRecharge)
    //         let prepaidSum = 0,prepaidCount= 0;
    //         prepaidData.map((index)=>{
    //             prepaidSum=prepaidSum+index.amount;
    //             prepaidCount = prepaidCount +index.count;
    
    //         })
            
    //         setSumPrepaidRecharge(prepaidSum);
    //         setCountPrepaidRecharge(prepaidCount);
    //         return prepaidSum, prepaidCount;
    
    //      }else{
    //         setSumPrepaidRecharge(0)
    //         setCountPrepaidRecharge(0)
    //      }
    // }
   
    useEffect(()=>{
        sumOfBbpsRecharge();
        // sumOfPrepaidRecharge();
    })

    return(
        <div className="payment-cards">
            <div className="payment-card cursor">
                <div className="payment-title" style={{fontWeight:"600"}}>AEPS</div>
                <div className="bottom-info">
                <div>
                <div className="payment-count" style={{fontSize:"12px"}}><span style={{color:"black"}}>
                    Amount : </span>
                    ₹ {isEmpty(aepsamount)
                    ?"0":
                    (Math.round(aepsamount * 100) / 100).toFixed(2)}</div>
                     <div className="payment-count" style={{fontSize:"12px"}} >
                        <span style={{color:"black"}}>Count : </span>
                        {isEmpty(serviceData?.serviceData?.aepsTransaction?.cash_withdrawal?.count)?"0":
                    (serviceData?.serviceData?.aepsTransaction?.cash_withdrawal?.count)} 
                        </div>
                </div>
                    <div className="payment-logo"><IconAeps /></div>
                </div>
            </div>
            <div className="payment-card">
                <div className="payment-title" style={{fontWeight:"600"}}>Credit Card</div>
                <div className="bottom-info">
                <div>
                <div className="payment-count" style={{fontSize:"12px"}}><span style={{color:"black"}}>
                    Amount : </span>
                    ₹ {isEmpty(creditCardBill)
                    ?"0":
                    (Math.round(creditCardBill * 100) / 100).toFixed(2)}</div>
                     <div className="payment-count" style={{fontSize:"12px"}} >
                        <span style={{color:"black"}}>Count : </span>   
                        {isEmpty(serviceData?.serviceData?.bbpsCollection?.credit_card_bill?.count)?"0":
                    (serviceData?.serviceData?.bbpsCollection?.credit_card_bill?.count)}                 
                        </div>
                </div>
                    <div className="payment-logo"><img src={AadharIcon}></img></div>
                </div>
            </div>
           
            <div className="payment-card">
                <div className="payment-title" style={{fontWeight:"600"}}>Prepaid Recharge</div>
                <div className="bottom-info">
                <div>
                <div className="payment-count" style={{fontSize:"12px"}}><span style={{color:"black"}}>
                    Amount : </span>
                    
                    ₹ {isEmpty(prepaidRecharge)
                    ?"0":
                    (Math.round(prepaidRecharge * 100) / 100).toFixed(2)}</div>
                     <div className="payment-count" style={{fontSize:"12px"}} >
                        <span style={{color:"black"}}>Count : </span>
                        {isEmpty(prepaidRechargeCount)?"0":
                    (prepaidRechargeCount)} 
                        </div>
                </div>
                    <div className="payment-logo"><img src={Recharge}></img></div>
                </div>
            </div>
            <div className="payment-card">
                <div className="payment-title" style={{fontWeight:"600"}}>BBPS</div>
                <div className="bottom-info">
                <div>
                <div className="payment-count" style={{fontSize:"12px"}}><span style={{color:"black"}}>
                    Amount : </span>
                    ₹ {isEmpty(sumBbpsRecharge)
                    ?"0":
                    (Math.round((sumBbpsRecharge) * 100) / 100).toFixed(2)
                    }</div>
                     <div className="payment-count" style={{fontSize:"12px"}} >
                        <span style={{color:"black"}}>Count : </span>           
                        {isEmpty(bppsRechargeCount)
                        ?"0" : (bppsRechargeCount)}         
                        </div>
                </div>
                    <div className="payment-logo"><img src={Bills}></img></div>
                </div>
            </div>
          
            <div className="payment-card">
                <div className="payment-title" style={{fontWeight:"600"}}>Payout</div>
                <div className="bottom-info">
                    <div>
                <div className="payment-count" style={{fontSize:"12px"}} >
                    <span style={{color:"black"}}>
                    Amount : </span>
                    ₹ {isEmpty(payOut)
                    ?"0":
                    (Math.round(payOut * 100) / 100).toFixed(2)}</div>
                     <div className="payment-count" style={{fontSize:"12px"}} >
                    <span style={{color:"black"}}>Count : </span>
                    {isEmpty(serviceData?.serviceData?.moneyTransfer?.pay_out?.count)?"0":
                    (serviceData?.serviceData?.moneyTransfer?.pay_out?.count)} 
                     </div>
                     </div>
                    <div className="payment-logo"><img src={payout}></img></div>
                </div>
            </div>
           
           
            <div className="payment-card">
                <div className="payment-title" style={{fontWeight:"600"}}>Send Money</div>
                <div className="bottom-info">
                <div>
                <div className="payment-count" style={{fontSize:"12px"}} ><span style={{color:"black"}}>
                    Amount : </span>
                    ₹ {isEmpty(sendmoney)
                    ?"0":
                    (Math.round(sendmoney * 100) / 100).toFixed(2)}</div>
                     <div className="payment-count" style={{fontSize:"12px"}} >
                        <span style={{color:"black"}}>Count : </span>
                        {isEmpty(serviceData?.serviceData?.moneyTransfer?.send_money?.count)?"0"
                        :(serviceData?.serviceData?.moneyTransfer?.send_money?.count)} 
                        </div>
                     </div>
                    <div className="payment-logo"><img src={dmr}></img></div>
                </div>
            </div>
            <div className="payment-card">
                <div className="payment-title" style={{fontWeight:"600"}}>Settlement Balance</div>
                <div className="bottom-info">
                    <div>
                <div className="payment-count" style={{fontSize:"12px"}} >
                    <span style={{color:"black"}}>
                    Amount : </span>
                    ₹ {isEmpty(settlement)
                    ?"0":
                    (Math.round(settlement * 100) / 100).toFixed(2)}
                    </div>
                     <div className="payment-count" style={{fontSize:"12px"}} >
                    <span style={{color:"black"}}>Count :
                     </span>
                     {isEmpty(serviceData?.serviceData?.moneyTransfer?.settlement?.count)?"0"
                        :(serviceData?.serviceData?.moneyTransfer?.settlement?.count)} 
                     </div>
                     </div>
                    <div className="payment-logo"><img src={utiregistration}></img></div>
                </div>
            </div>
           
        </div>
        
    )
}