import React, { useState } from "react";
import totalmember from "../../assets/images/total_member.png"
import { serviceList } from "../../utils/api";
import "../../pages/overview/styles/overview.scss"


export default function TotalMembers() {

    const [isToggled, setIsToggled] = useState(false);
    const [services, setServices] = useState([])


    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    

    const handleservicesData = async (e) => {

        const param = {
            fromDate: "",
            pageNo: 0,
            pageSize: 20,
            serviceName: "",
            toDate: ""
        }


        serviceList(param).then((resp) => {

            if (resp?.data?.success) {
                setServices(resp?.data?.data?.content);
            } else {
            }

        })

    };
    return (
        <div className="total_members">
            <div className='total_members_pic-with_right_text'>
                <div className='pic'>
                    <img src={totalmember}></img>
                </div>
                <div className='text-info'>
                    <span>Total Members</span>
                    <strong>0</strong>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>AEPS</strong>
                    <div className={`custom-toggle toggle_button`}>
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>Aadhar Pay</strong>
                    <div className={`custom-toggle toggle_button`} >
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>Recharge</strong>
                    <div className={`custom-toggle toggle_button`}>
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>Bill</strong>
                    <div className={`custom-toggle toggle_button`}>
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>DMR</strong>
                    <div className={`custom-toggle toggle_button`}>
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>DMT</strong>
                    <div className={`custom-toggle toggle_button`}>
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>Payout</strong>
                    <div className={`custom-toggle toggle_button`} >
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
            <div className="members-list-wrapper">
                <div className="members-list">
                    <strong>UTI Pan</strong>
                    <div className={`custom-toggle toggle_button`}>
                        <div className="slider"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}