
import React, { useEffect, useState } from "react";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Pagination from "../../Components/Pagination/Pagination";
import { CSVLink } from "react-csv";
import { CSVDownload } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";



const options = [
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
];

const featureData =[
    {ServiceName:"Mobile",ServiceCode:"Mb",ServiceCreatedBy:"ABC",ServiceCreatedAt:"20-12-2022"},
    {ServiceName:"DTH",ServiceCode:"DTH",ServiceCreatedBy:"ABC",ServiceCreatedAt:"20-06-2022"},
    {ServiceName:"AEPS",ServiceCode:"AEPS",ServiceCreatedBy:"ABC",ServiceCreatedAt:"20-12-2023"},
    {ServiceName:"MoneyTransfer",ServiceCode:"MT",ServiceCreatedBy:"ABC",ServiceCreatedAt:"02-12-2021"},
    {ServiceName:"Mobile",ServiceCode:"MB",ServiceCreatedBy:"ABC",ServiceCreatedAt:"10-10-2022"},
    {ServiceName:"AEPS",ServiceCode:"AEPS",ServiceCreatedBy:"ABC",ServiceCreatedAt:"20-12-2022"},
    {ServiceName:"EMI",ServiceCode:"EMI",ServiceCreatedBy:"ABC",ServiceCreatedAt:"20-12-2022"},
    {ServiceName:"AEPS",ServiceCode:"AEPs",ServiceCreatedBy:"ABC",ServiceCreatedAt:"20-12-2022"},
]

function FeatureManagement() {

    const [totalPages, setTotalPages] = useState(null);
    const [currPage, setCurrPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [service, setService] = useState("");

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [values,setValues] =useState(false);
    const [errors, setErrors] = useState({
        startDate: "",
        endData: "",
    });
    const navigate = useNavigate();

    const disableFutureDate = () => {
        let today, date1;
        today = new Date();
        date1 = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      
        return date1
    }

    const changeStatus = () =>{
        setValues(!values);
        
    }


    const updateStatus = ()=>{
        Swal.fire({
            title: "Are you sure? you want to update status",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#00BAF2',
            confirmButtonText: 'Yes, I am sure!',
            cancelButtonText: "No, cancel it!",
            closeOnConfirm: false,
            closeOnCancel: false
        
         },
        //  function(isConfirm){
        
        //    if (isConfirm){
        //      Swal("Status updated successfully", "success");
        
        //     } else {
        //       Swal("Cancelled", "Your imaginary file is safe :)", "error");
        //          e.preventDefault();
        //     }
        //  }
         );
    }
    return (
        <div>
            <div className='userlist_main_container'>
                <div className='userlist_head'>
                    <div className='userlist_heading'>
                        <div className='userlist_left'>
                            <label>Service Management</label>
                        </div>
                        <div className='userlist_right'>
                            <button className='pdf'
                            //  onClick={(e) => userlistPdfData(e, "PDF")}

                            >PDF</button>
                            <button className='xls btn-success'
                            // onClick={(e) => userlistPdfData(e, "CSV")}
                            >CSV </button>
                        </div>
                    </div>

                </div>
                <div className='userlist_container'>
                    <table className="userlist_filter_data">
                        <tr >
                            <td className="filter_row">
                                <label >FilterBy</label>
                            </td>
                            <td className="filter_row">
                                <select style={{ marginLeft: "20px" }}>
                                    <option>select</option>
                                    <option>Service</option>
                                </select>
                            </td>
                            <td className="filter_row">
                                <input style={{ marginLeft: "2px" }}
                                    type="text"
                                // name="detailData"
                                // placeholder="search here"
                                // value={detailData}
                                // onChange={(e) => setDetailData(e.target.value)}
                                />
                            </td>
                            <td className="filter_row">
                                <button className="search_btn"
                                // onClick={() => handleApiData()}
                                >Search
                                </button>
                            </td>

                            <td className="filter_row">
                                <label style={{ marginLeft: "25px" }}>FilterBy</label>
                            </td>
                            <div style={{ backgroundColor: "#F4F8F8", marginLeft: "10px" }}>
                                <td style={{ backgroundColor: "#F4F8F8" }}>
                                    <input style={{ backgroundColor: "#F4F8F8" }}
                                        type="date"
                                        name=""
                                        onChange={(e) => setStartDate(e.target.value)}
                                        max={disableFutureDate()}
                                    />
                                </td>
                                <td style={{ backgroundColor: "#F4F8F8" }}>
                                    <label style={{ backgroundColor: "#F4F8F8", fontSize: "12px", fontFamily: "Noto sans", fontWeight: "bold" }}>To</label>
                                </td>
                                <td>
                                    <input style={{ backgroundColor: "#F4F8F8" }}
                                        type="date"
                                        name=""
                                        onChange={(e) => setEndDate(e.target.value)}
                                        max={disableFutureDate()}
                                    />
                                </td>
                            </div>
                            <td className="filter_row">
                                <button className="userlist_btn1"
                                    // onClick={() => handleApiData()}
                                    style={{ marginLeft: "15px" }}>GO</button>
                            </td >
                            <td className="filter_row">
                                <button className="userlist_btn" style={{ marginLeft: "5%" }}
                                onClick={() => navigate('/addnewfeature')}
                                >Add New Feature</button>
                            </td>
                        </tr>

                    </table>
                </div>

            </div>
            <div className="userlist_table">
                <table id="customers">
                    <tr>
                        <th>S.No</th>
                        <th>Service Name</th>
                        <th>Service Code</th>
                        <th>Service Created By</th>
                        <th>Service Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    {featureData && featureData.map((val, ind) => {
                            return (
                                <tr className="table_border" key={Math.random() * 1000000}>
                                    <td>{ind + 1}</td>
                                    <td>{val.ServiceName}</td>
                                    <td>{val.ServiceCode}</td>
                                    <td>{val.ServiceCreatedBy}</td>
                                    <td>{val.ServiceCreatedAt}</td>
                                    <td>

                                        <div
                                            className={`${val.status
                                                ? "active_status"
                                                : "inactive_status"
                                                }`}
                                        >
                                            {val.status ? "Active" : "InActive"}
                                            {/* {values ? "Active" : "InActive"}  */}
                                            
                                            
                                        </div>
                                    </td>

                                    <td className="user_action_btn">
                                            <button
                                                className=""
                                                style={{ marginRight: "5px" }}
                                            >
                                                <i class="fa-solid fa-pen-to-square update" 
                                                // onClick={() => navigate("/editserviceList",{state:val})}
                                                ></i>
                                            </button>
                                            <button onClick={updateStatus} >
                                            <i class="fa-regular fa-lightbulb" 
                                           ></i>
                                                 
                                                 
                                            </button>

                                            {/* Approved */}
                                        </td>
                                </tr>
                            )

                        })}

                        {
                            featureData && (featureData.length < 1) &&
                            <>
                                <tr>
                                    <td colSpan={14} style={{textAlign:"center",color:"red",height:"120px",fontSize:"20px"}}>No Data Found</td> 
                                </tr>
                            </>
                        }

                </table>

            </div>
            <Pagination
                items={service}
                itemsPerPage={pageSize}
                setitemsPerPage={setPageSize}
                currentPage={currPage}
                setcurrentPage={setCurrPage}
                totalPages={totalPages}
            />
        </div>
    )
}

export default FeatureManagement
