import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import LosSetup from '../pages/LosSetup/LosSetup';


const ProtectedRoute = ({Component}) => {


    // const {user} = useSelector((state)=> state.user)
    
    useEffect(() => {
        let user = sessionStorage.getItem('token');

        if(!user){
        
            <Navigate to={"/"}/>
        }
    })


    

  return (
    <div>
      <LosSetup Component={Component} />
         
    </div>
  )
}

export default ProtectedRoute