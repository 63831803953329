import React from "react";

function IconAeps() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.064"
      height="37.609"
      viewBox="0 0 28.064 37.609"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#00baf2"
            d="M0 0H28.064V37.609H0z"
            data-name="Rectangle 532"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 241">
        <g clipPath="url(#clip-path)" data-name="Group 240">
          <path
            fill="#00baf2"
            d="M19.8 16.109c8.335 0 13.422 4.472 14.828 11.806a18.2 18.2 0 01.321 5.407c-.068.622-.1 1.322-.951 1.251s-.788-.8-.77-1.4a20.117 20.117 0 00-.923-6.933c-1.363-4.158-4.037-6.947-8.4-7.9-.6-.131-1.569-.532-1.765.278-.176.727.809.809 1.412.966 5.076 1.319 7.885 4.66 8.442 9.858a15.34 15.34 0 01-.442 5.832A12.831 12.831 0 0030.96 38a7.393 7.393 0 01-2.46 4.731.943.943 0 01-1.412.131.883.883 0 01-.04-1.322 13.627 13.627 0 002.527-4.894 22.69 22.69 0 00.69-4.728 14.481 14.481 0 00-.317-3.717c-1-4.662-4.655-6.708-8.642-7.012A10.765 10.765 0 0011.3 26.046c-.155.214-.3.434-.472.638a.815.815 0 01-1.1.278.792.792 0 01-.254-1.186 11.024 11.024 0 012.6-3.269 12.719 12.719 0 018.269-3.243c.485.005 1.106.044 1.027-.7-.071-.666-.624-.464-1.073-.439a20.648 20.648 0 00-9.262 3.109 8.841 8.841 0 01-.8.43.776.776 0 01-1.013-.24.839.839 0 01.042-1.141 2.16 2.16 0 01.509-.444c3.518-2.2 7.275-3.675 10.033-3.735"
            data-name="Path 357"
            transform="translate(-6.965 -12.453)"
          ></path>
          <path
            fill="#002970"
            d="M37.243 70.651c-.869 0-1.739-.011-2.608 0-1.208.019-1.493.353-1.582 1.812-.057.927.473 1.524 1.536 1.552 1.888.05 3.779.019 5.668.032 1.292.009 1.373.143.989 1.391a2.49 2.49 0 01-2.251 1.938 19.472 19.472 0 01-5.866-.225 3.865 3.865 0 01-3.358-4.3 23.026 23.026 0 011.969-8.328c1.739-3.54 5.479-4.3 8.851-3.364 2.1.581 3.153 2.776 2.8 5.33a12.537 12.537 0 01-.409 1.764 2.885 2.885 0 01-3.018 2.383c-.9.042-1.813.007-2.72.007v.011m-.224-3.4v.01c.563 0 1.127.006 1.69 0s1.13.069 1.307-.723a1.826 1.826 0 00-.581-2.124c-1.609-1.129-4.765.206-5.1 2.113-.088.5.058.72.546.724.713.006 1.427 0 2.14 0"
            data-name="Path 358"
            transform="translate(-23 -46.992)"
          ></path>
          <path
            fill="#00baf2"
            d="M12.62 107.79a10.092 10.092 0 006.231-1.87c.155-.107.316-.207.478-.3.471-.28.952-.355 1.313.134.423.574.066 1-.387 1.335a12.523 12.523 0 01-6.122 2.489 12.616 12.616 0 01-10.772-3.336C-.08 102.882-.6 98.681.558 94.174c.139-.539.415-1.043 1.09-.9s.707.73.626 1.271a15.451 15.451 0 00-.362 4.832 9.831 9.831 0 009.738 8.411h.971"
            data-name="Path 359"
            transform="translate(0 -72.085)"
          ></path>
          <path
            fill="#00baf2"
            d="M22.653 52.255a1.256 1.256 0 01-.537-1.653 9.713 9.713 0 0113.37-5.313 8.212 8.212 0 014.17 5.523 2.086 2.086 0 01.054.562c0 .568-.118 1.105-.776 1.2s-.96-.407-1.037-.959a6.911 6.911 0 00-1.616-3.315 4.516 4.516 0 00-2.819-1.756c-4.032-.7-7.384.149-9.422 4.35-.3.616-.6 1.24-1.388 1.362z"
            data-name="Path 360"
            transform="translate(-17.001 -34.261)"
          ></path>
          <path
            fill="#00baf2"
            d="M25.731 0a21.574 21.574 0 0112.723 3.947c.569.39 1.22.848.654 1.639-.515.719-1.148.327-1.691-.042a20.977 20.977 0 00-4.807-2.511 17.807 17.807 0 00-12.736.148c-.141.054-.284.1-.425.157-.558.213-1.081.184-1.354-.41-.312-.678.121-1.089.681-1.328a19.813 19.813 0 012.337-.86A17.181 17.181 0 0125.731 0"
            data-name="Path 361"
            transform="translate(-13.908)"
          ></path>
          <path
            fill="#00baf2"
            d="M16.538 79.276a1.406 1.406 0 01.589 1.665 31.092 31.092 0 00-1.041 5.893 6.374 6.374 0 005.777 6.9c.186.031.376.037.561.071.609.111 1.133.377 1.052 1.086s-.631.894-1.259.833c-4.015-.391-7.205-2.714-7.976-6.938-.549-3.011.469-5.819 1.1-8.675a1.1 1.1 0 011.2-.833"
            data-name="Path 362"
            transform="translate(-10.888 -61.283)"
          ></path>
          <path
            fill="#00baf2"
            d="M2.7 68.311a7.2 7.2 0 01.783-2.74c.3-.541.739-.915 1.379-.6s.628.871.34 1.444a5.366 5.366 0 00-.624 2.143c-.035.635-.236 1.274-1.01 1.239-.886-.04-.87-.769-.867-1.485"
            data-name="Path 363"
            transform="translate(-2.084 -50.135)"
          ></path>
          <path
            fill="#00baf2"
            d="M62.577 141.652c-.785-.016-1.517-.052-1.564-.9s.647-.99 1.337-1.005a6.365 6.365 0 002.074-.522c.567-.21 1.165-.285 1.463.382s-.155 1.062-.712 1.317a7.622 7.622 0 01-2.6.733"
            data-name="Path 364"
            transform="translate(-47.163 -107.506)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconAeps;