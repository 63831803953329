import React from "react";
import { useNavigate } from "react-router-dom";


const TicketList =()=>{


    const navigate = useNavigate()
    const handleCreateTicket =()=>{
        
        navigate('/CreateTicket')
    }
    
    
    return(
        <>
        <div className="userlisthead_container">
        <div className='subadmin_main_container'>
            <div className='subadmin_head flex space-between' style={{width:"97%"}}>
                <strong>Ticket</strong>

                
                         <div className='userlist_right flex'>
                                <button className='pdf'

                                >PDF</button>
                                <button className='xls btn-success'>CSV </button>
                            </div>
                 
            
            </div>
            <div className='userlist_container'>
                   <div className="conatiner_header">
                      <ul className="flex align-item-center" style={{marginRight:"10px"}}>
                        <li className="fs  btn_all">All</li>
                        <li  className="fs fw mg-left18">Open Tickets</li>
                        <li className="fs fw mg-left18 ">Closed Tickets</li>
                        <li className="fs fw mg-left18">Reopen Tickets</li>
                      </ul>
                 
                   </div>
                        <div className="userlist_filter_data">
                            <div className="flex align-item-center" >
                                <div className="flex align-item-center">
                                <div className="filter_row">
                                    <label >FilterBy</label>
                                </div>
                                <div className="filter_row">
                                    <select>
                                        <option>select</option>
                                        <option>Ticket Id</option>
                                    </select>
                                </div>
                                <div className="filter_row">
                                    <input
                                        type="text"
                                        name="detailData"
                                        placeholder="search here"
                                      //  value={detailData}
                             />
                                </div>
                                <div className="filter_row">
                                    <button className="search_btn"
                                       >Search</button>
                                </div>
                                </div>

                                <div className="filter_row">
                                    <label>FilterBy</label>
                                </div>
                                <div className="filter_row">
                                <select>
                                        <option>Category</option>
                                        <option>Ticket Id</option>
                                    </select>
                                </div>
                                <div className="filter_row">
                                <select>
                                        <option>status</option>
                                        <option>Ticket Id</option>
                                    </select>
                                </div>
                                <div className="flex" style={{ backgroundColor: "#F4F8F8"}}>
                                    <div style={{ backgroundColor: "#F4F8F8" }}>
                                        <input style={{ backgroundColor: "#F4F8F8" }}
                                            type="date"
                                            name=""
                                         //   onChange={(e) => setStartDate(e.target.value)}
                                          //  max={disableFutureDate()}
                                        />
                                    </div>
                                    <div style={{ backgroundColor: "#F4F8F8" }}>
                                        <label style={{ backgroundColor: "#F4F8F8", fontSize: "12px", fontFamily: "Noto sans", fontWeight: "bold" }}>To</label>
                                    </div>
                                    <div>
                                        <input style={{ backgroundColor: "#F4F8F8" }}
                                            type="date"
                                            name=""
                                         //   onChange={(e) => setEndDate(e.target.value)}
                                           // max={disableFutureDate()}
                                        />
                                    </div>
                                </div>
                                <div className="filter_row" style={{marginLeft:"3px"}}>
                                    <button className="userlist_btn1"
                                      //  onClick={() => handleApiData()}
                                        >GO</button>
                                </div >
                                <div className="filter_row">
                                    <button className="userlist_btn" onClick={handleCreateTicket}>Create Ticket</button>
                                </div>
                            </div>

                        </div>
                    </div>

                   




            </div>
            <div className="userlist_table">
                    <table id="customers">
                        <tr>
                            <th>S.No.</th>
                            <th>Ticket ID</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Ticket Date</th>
                            <th>Issue Category</th>
                            <th>Discription</th>
                            <th>Last Update</th>
                            <th>Status</th>
                            <th>Options</th>
                        </tr>
                        {/* 
                     <tbody>
                        {apiData
                            ? createServiceListItems()
                            : "No DATA  AVAILABLE"}
                     </tbody> */}
                        {
                            <tbody>
                                <tr className="border" style={{height:"32px"}}>
                                      <td>fff</td>
                                      <td>fff</td>
                                      <td>fff</td>
                                      <td>fff</td>
                                      <td>fff</td>
                                      <td>ff</td>
                                      <td>ffff</td>
                                      <td>ff</td>
                                      <td>ff</td>
                                      <td> <button  className="delete" style={{marginLeft:"5px"}}>+</button></td>
                                </tr>
                               
                            </tbody>
                        }

                    </table>

                </div>
</div>
        </>

    )
}
export default TicketList;