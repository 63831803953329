import { NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import { FiLogOut } from "react-icons/fi";

import { useEffect, useState,useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "../Sidebar/sidebar.scss";

import completelogo from "../../assets/images/completelogo.png";
import SidebarMenu from "./SidebarMenu";
import { useDispatch } from "react-redux";
import { logout } from "../../store/authSlice";
import { deleteUser } from "../../store/userSlice";
import { sidemenuContast } from "./constant";
import { rolePermission } from "../../utils/api";

const SideBar = ({ children, isOpen, setIsOpen,setPermission,siedBarLeft,setSiedBarLeft}) => {

  const [isMenuOpen, setIsMenuOpen] = useState("");
  const [isActive, setIsActive] = useState("");
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [permissionList, setpermissionList] = useState([]);

  
  const getRole = sessionStorage.getItem("token");
      const role = JSON.parse(getRole).authorities;
           

              const handlePermission = ()=>{
                rolePermission(role).then((res)=>{
                  if (res?.data?.success) {
                    const permission = res?.data?.data;
                      
                    let createPermissionArr = [];
                    permission &&
                      Object.keys(permission).map((CkeckPermissionkey, index) => {
                        permission[CkeckPermissionkey].map((item, i) => {
                          const perMissionString = item.navName;
                          createPermissionArr.push(perMissionString);
                        });
                      });
            
                   
                      setpermissionList([...createPermissionArr]);
                      setPermission([...createPermissionArr])
                   
                  }

                })
              }
              
    useEffect(()=>{
       // handlePermission(); 
 
    },[]);












  const handleLogout = () => {
    setIsActive("logout");
    sessionStorage.clear();
    dispatch(logout());
    dispatch(deleteUser());
    navigate("/");
  };

  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleActiveRoute = (item,) => {
    setIsActive(item);
   
    setIsMenuOpen("");
  };


  return (
    <>
      <div className="sidebar-main-container">
        <motion.div
          animate={{
            width: isOpen ? "245px" : "60px",
            transition: {
              duration: 0.2,
              // damping: 10,
            },
          }}
          className={`sidebar ${siedBarLeft} `}
        >
          <div className="top_section">
            <AnimatePresence>
              <img
                src={completelogo}
                alt=""
                onClick={toggle}
                style={{
                  width: isOpen ? "55%" : "150px",
                  alignItems: "center",
                }}
                className="pointer"
              />

              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo d-flex column mt-10"
                  style={{ marginLeft: "-25px" }}
                >
                  {/* <strong style={{marginLeft:"20%"}}>Pay2</strong>
                                <strong style={{marginLeft:"20%",marginTop:"10%"}}>Mobile</strong> */}
                </motion.h1>
              )}
            </AnimatePresence>

             <div className="bars pointer d-m-none">
              {isOpen && <FaBars onClick={toggle} />}
            </div> 
            <div className="bars pointer d-m-block">
              {isOpen && <FaBars onClick={() => setSiedBarLeft("")} />}
            </div>
          </div>
          <section className="routes">
            {sidemenuContast.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    setIsMenuOpen={setIsMenuOpen}
                    isMenuOpen={isMenuOpen}
                    permissionList ={permissionList}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className={`link ${!isOpen && "center"} ${
                    isActive === route.name && "active_tab"
                  }`}
                  // activeclassname="active"
                  onClick={(e) => handleActiveRoute(route.name, e)}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        // exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}

            <NavLink
              to={"/"}
              onClick={handleLogout}
              className={`linklogout ${!isOpen && "center"} ${
                isActive === "logout" && "active_tab"
              } `}
            >
              <div className="icon">
                <FiLogOut />
              </div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    Logout
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          </section>
        </motion.div>

        {children}
      </div>
    </>
  );
};

export default SideBar;
