import React, { memo } from "react";
import { ModalWrapper } from "../../Components/Modal/Modal";



const AddNewMerchant = memo(
    ({
        setPopUpShow
    }) => {

        const closePopUpHandler = () => {
            setPopUpShow(false)
        }


        return (
            <ModalWrapper>
                <div className="modal-dialog modal" style={{ width: '100%' }} role="document">
                    <div className="modal-content">
                        <div>
                            <div className="modal-header flex justify-between item-center">
                                <label className="modal-title" id="exampleModalLabel">
                                    Add New Merchant
                                </label>
                                <span aria-hidden="true">
                                    <i className="fa fa-times" onClick={closePopUpHandler} aria-hidden="true"
                                        style={{ color: "#00286F", height: "20px", width: "20px" }}></i>
                                </span>
                            </div>
                            <div className='add_merchant'>
                                <div className='add_merchant_container'>
                                    <div className='add_merchant_flex'>
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Plan Name <span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'>
                                                <input type='text'
                                                    placeholder='please enter your first name'
                                                ></input>
                                            </div>

                                        </div>
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Select User <span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'>
                                                <input type='text'
                                                    placeholder='please enter your first name'
                                                >

                                                </input>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='add_merchant_flex'>
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Pay In Commisson<span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'>
                                                <input type='text'
                                                    placeholder='please enter your first name'
                                                ></input>
                                            </div>

                                        </div>
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Pay Out Commisson <span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'>
                                                <input type='text'
                                                    placeholder='please enter your first name'
                                                >

                                                </input>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='add_merchant_flex'>
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <button>Add Commission</button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </ModalWrapper>


        );
    }
)


export default AddNewMerchant;

