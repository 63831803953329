import React from "react";
import logo from "../../assets/images/logo.png";


const RegisterRight = () => {
    return (
        <div className="right">
            <div className="img">{/* <img src={bg} alt="waves" /> */}</div>

            <div className="register_form_wrapper">
                <div className="register_form">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>

                    <div className="form">
                        <p className="fs-20 mt-20 fw-700">Create New Account</p>

                        <p className="fs-16 mt-14">
                            You can reset your password here.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterRight;
