import React from "react";
import LosBoxTop from "./LosBoxTop";
import carret from "../../assets/img/carret.svg";
import demo from "../../assets/img/demo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

function APIkeyConfiguration() {
 const [tab, setTab] = useState("tab-1")

  return (
    <div className="mainBoxWrapper">
    <LosBoxTop  step="4"/>
    <div className="fromBox">
        <div className="tabs flex">
          <div className={tab === "tab-1" && "active"} onClick={() =>setTab("tab-1")}>Website</div>
          <div className={tab === "tab-2" && "active"} onClick={() =>setTab("tab-2")}>Android App</div>
          <div className={tab === "tab-3" && "active"} onClick={() =>setTab("tab-3")}>iOS App</div>
        </div>
      {tab === "tab-1" &&<div className="mainfrom flex">
      <div class="from-group">
          <div class="from-field">
            <label for="">
            Select Website Platform<span class="red"></span>
            </label>
            <div class=" flex">
            <select name="" id="">
                <option value="">Select Website Platform</option>
                <option value="">Organization Type</option>
                <option value="">Organization Type</option>
              </select>
              <img className="icon" src={carret} alt="icon" />
            </div>
          </div>
          <div class="msg"></div>
        </div>
        <div class="from-group">
          <div class="from-field">
            <label for="">
            Live Key ID<span class="red"></span>
            </label>
            <div class=" flex">
              <input
                class=" "
                placeholder="Enter Live Key ID"
                type="text"
              />
            </div>
          </div>
          <div class="msg"></div>
        </div>
        <div class="from-group">
          <div class="from-field">
            <label for="">
            Live Key Secret<span class="red">*</span>
            </label>
            <div class=" flex">
              <input
                class=" "
                placeholder="XXXXXXXXXXXXXXX"
                type="text"
              />
            </div>
          </div>
          <div class="msg"></div>
        </div>
       
    
     
      </div>}
      <div className="submitButtom">
        <Link className="btn" to="/completed-add-organization">
        NEXT
        </Link>
      </div>
    </div>
  </div>
  );
}

export default APIkeyConfiguration;
