import React, { useEffect, useState } from "react";
import "../../Transactions/styles/transactions.scss";
import "../../Users/style.scss";
import "./style.scss";
import {
  getRoleAndPermissionList,
  rolePermission,
  updatePermissionList,
} from "../../../utils/api";
import SubAdminPermission from "./SubAdminPermisson";
import { FilterWrapper } from "../../../assets/styles/FilterStyle";
import { isEmpty } from "../../../utils/common";
import { AlertWrapper } from "../../../assets/styles/AlerWrapper";


const PermissionManagement = () => {
  const [rolelist, setRoleList] = useState([]);
  const [checkValue, setCheckValue] = useState([]);
  const [role, setRole] = useState("");
  const [permissionList, setpermissionList] = useState([]);
  const [drawerShow, setDrawerShow] = useState(false);  
  const[msg,setMsg] = useState("")
  const getAllRole = () => {
    getRoleAndPermissionList().then((resp) => {
      const data = resp?.data?.data;
      setRoleList(data);
    });
  };

  useEffect(() => {
    getAllRole();
  }, []);

  const updatePermission = () => {
    const param = {
      roleCode: role,
      activePermission: [...checkValue],
    };
    updatePermissionList(param).then((resp) => {

      if(!role == " " ){
        if (resp?.data?.success) {
          if(resp?.data?.message==="SUCCESS")
          setMsg("Permission Updated Successfully");
         }
      }

     
      
    });
  };
  

  const handleUpdatePermission = () => {
    updatePermission();
  };
  const handleRole = (e) => {
   
    setRole(e.target.value);
    rolePermission(e.target.value).then((res) => {
      if (res?.data?.success) {
        const permission = res?.data?.data;
          
        let createPermissionArr = [];
        permission &&
          Object.keys(permission).map((CkeckPermissionkey, index) => {
            permission[CkeckPermissionkey].map((item, i) => {
              const perMissionString = CkeckPermissionkey + "/" + item.navName;
              createPermissionArr.push(perMissionString);
              
            });
          });

       
          setpermissionList([...createPermissionArr]);
       
      }
    
    });
  };
  useEffect(()=>{
    if(msg !== ""){
      setTimeout(()=>{
    setMsg("");
      },5000)
     
    }

  },[msg])

  return (
    
    <>
     {  msg &&   <AlertWrapper className= "success globle">
					<span className="close" onClick={()=>setMsg("")}>
						×
					</span>
					{msg}
				</AlertWrapper>}
      <div className="transaction_main_container" style={{ padding: "30px" }}>
        <div className="transaction_head">
          <div className="transaction_heading" style={{ alignItems: "center" }}>
            <div className="transaction_left">
              <label>Manage Roles and Permissions</label>
            </div>
          </div>
        </div>
        <FilterWrapper>
          <div className=" flex filterBox">
            <div className="flex align-items">
              <lable
                className="filter-text"
                style={{ marginRight: "19px", fontWeight: "bold" }}
              >
                Select Role
              </lable>
              <div className="input-field">
                <select
                  onChange={handleRole}
                  className="input_select_field"
                  style={{
                    width: "272px",
                    border: "none",
                    height: "32px",
                    backgroundColor: "#F4F8F8",
                  }}
                >
                  <option selected disabled value="">
                    SELECT ROLE
                  </option>
                  <option value="ROLE_ADMIN">ADMIN</option>
                  <option value="ROLE_SUB_ADMIN">SUB ADMIN</option>
                  <option value="ROLE_RETAILER">RETAILER</option>
                </select>
              </div>
            </div>
          </div>
        </FilterWrapper>




        <div className="container">
          <h1 className="main_heading">Permission List</h1>
          {rolelist &&
            Object.keys(rolelist).map((key, index) => {
              return (
                <SubAdminPermission
                  heading={key}
                  index={index}
                  role={rolelist[key]}
                  checkValue={checkValue}
                  setCheckValue={setCheckValue}
                  permissionList={permissionList}
                  userRole={role}
                
                />
              );
            })}
        </div>
        <button className="userlist_btn mt-20" onClick={handleUpdatePermission}>
          Update
        </button>
      </div>
    </>
  );
};
export default PermissionManagement;
