import React, { useEffect, useRef, useState } from 'react'
import { json, useNavigate, } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/authSlice';
import { deleteUser } from '../../store/userSlice';
import headerBg from '../../assets/images/background_image.png'
import "../Navbar/Navbar.scss";
import barsstaggered from "../../assets/images/bars-staggered.png";
import membericon from "../../assets/images/member_icon.png";
import searcg from "../../assets/img/search.svg";
import wallet from "../../assets/images/wallet.png";
import { isEmpty } from '../../utils/common';
import { getLiveBalance, rolePermission } from '../../utils/api';
import mobileViewtoggle from "../../assets/images/mobileviewtoggle.svg"
import bell from "../../assets/img/bell.svg";
import userIcon from "../../assets/img/userslogo.svg";
import down from "../../assets/img/caret-down.svg";
import Swal from 'sweetalert2';
//import jwt from 'jsonwebtoken';

const Navbar = ({ setSiedBarLeft }) => {

    const [isMenuHidden, setIsMenuHidden] = useState(false)
    const [liveBalance, setLiveBalance] = useState("");


    const { user } = useSelector((state) => state.user)


    const navigate = useNavigate();
    const dispatch = useDispatch();



    const ref = useRef();
    useEffect(() => {

        setIsMenuHidden(false)
    }, [window.location.pathname]);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuHidden && ref.current && !ref.current.contains(e.target)) {
                setIsMenuHidden(false)
            }
        };
        document.addEventListener("click", checkIfClickedOutside);
        return () => {
            document.removeEventListener("click", checkIfClickedOutside);
        };

    })

    useEffect(() => {

        if (isEmpty(user)) {


        }


    }, [user]);



    useEffect(() => {
        getLiveBalance().then((resp) => {
            if (resp?.data?.success) {
                setLiveBalance(resp?.data?.data?.liveBalance);
            }
        })

    }, [])

    useEffect(() => {
        if (sessionStorage.getItem('token')) {

            const token = JSON.parse(sessionStorage.getItem('token'));


            const token_ = token?.id_token;
            // const token_  = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4ODAwNTc4ODY2IiwiYXV0aCI6IlJPTEVfQURNSU4iLCJ1c2VySWQiOiI4NzJjN2UwOC0xNjgyLTRjOGYtODk0Mi04ZWFkMTMxYjliNmUiLCJpc090cFZhbGlkYXRlZCI6ZmFsc2UsImV4cCI6MTY5NDg0NTExM30.HD_Eeg2G-KUQzUVryiE5J_YzGjn6IG2BojcvcBjopGBVWjPUE6eRA7yJtBqPHqsF_2G9ad0hCg_pV85_N3lFUg";

            var base64Url = token_.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            jsonPayload = JSON.parse(jsonPayload)
            let expirytime = jsonPayload.exp

            let expirationDate = new Date(expirytime * 1000);







            const Intervalid = setInterval(() => {
                try {
                    if (expirationDate) {





                        if (expirationDate <= Date.now()) {

                            Swal.fire({
                                icon: "error",
                                title: "Session Expired",
                                showConfirmButton: "#ED1C24",

                                confirmButtonText: 'Ok',
                                customClass: "swal-wide",

                            })

                            // alert('logout');
                            sessionStorage.clear()
                            dispatch(logout())
                            dispatch(deleteUser())
                            navigate('/')
                            clearInterval(Intervalid);
                        }
                    } else {
                        console.error('Token is not valid');
                    }
                } catch (error) {
                    console.error('An error occurred:', error.message);
                }
            }, 6000);
        }
    }, [sessionStorage.getItem('token')]);



    return (
        <>
     
        <div className="header-wrapper">
            <header className='flex justify-between align-items'>
                 <div className="left">
                <h2> Product Management</h2>
                 </div>
                 <div className="middle">
                    <div className="searchBox flex grow-1">
                       <input type="search w-100" name="" id="" placeholder='Search here...'  />
                       <div className="SearchButton">
                        <img src={searcg} alt="searcg-icon" />
                       </div>
                    </div>
                 </div>
                 <div className="right flex align-items gap-10" >
                    <div className="bell center">
                        <img src={bell} alt="" />
                        <div className='notification center'>
                            <span>2</span>
                        </div>
                    </div>
                    <div className='center'>
                    <div className="user">
                        { <img src={userIcon} alt="" /> }
                    </div>
                    <div className="userName">
                    {user?.firstName}
                    </div>
                    <div className='down'>
                    { <img src={down} alt="" /> }
                    </div>
                    </div>
                    

                 </div>
            </header>
        </div>
        </>
    )
}

export default Navbar