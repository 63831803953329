
import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import "./style.scss"
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import { deteleUserFromList, getUserList, searchUser } from "../../utils/api";
import { getDateFromDateObj, isAlphabet, isEmpty, isNumber, keyExists } from "../../utils/common";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp.jsx";
import LocalLoader from "../../Components/Loader/LocalLoader.js";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import userMainBalance from "../../assets/images/userMainBalance.svg"
import userAepsBalance from "../../assets/images/userAepsBalance.svg"
import modalpopup from "../../assets/images/modalPopubg.svg"
import DatePicker from "react-datepicker";
require('react-datepicker/dist/react-datepicker.css')




const UsersList = () => {

    const [apiData, setApiData] = useState([]);
    const [detailData, setDetailData] = useState("")
    const [name, setName] = useState("");
    const [userType,setUserType] = useState("")
    const [totalPages, setTotalPages] = useState(null);
    const [totalElements, setTotalElements] = useState("");
    const [currPage, setCurrPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pdfData, setPdfData] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [popUpData, setPopData] = useState(false)
    const [userBalanceData, setUserBalanceData] = useState()



    const link = useRef();
    // Date Format
    var currentDate = new Date();
    var formattedDate = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear()]
        .map(n => n < 10 ? `0${n}` : `${n}`).join('-');

    const navigate = useNavigate()

    let _fromDate = "";
    let _toDate = "";

    if (startDate !== "" && endDate !== "") {
        _fromDate = getDateFromDateObj(startDate, "YYYY-MM-DD")
        _toDate = getDateFromDateObj(endDate, "YYYY-MM-DD")
    }
    //API to fetch userlist

    const handleApiData = async (e, key) => {

        let error = {};

        if (name.length < 3) {
            error.name = "Name cannot be less than 3 letters";

        }
        const param = {
            fromDate: _fromDate,
            role: "PTM_RETAILER",
            toDate: _toDate,
            username: detailData,
            name: name,
            userType:userType,
        }

        const pagination = {
            currentPage: currPage,
            pageSize: pageSize
        }
        if (key === "CSV" || key === "PDF") {
            if ((key === 'CSV')) {
                setDownloadLoader("CSV")
            } else {
                setDownloadLoader("PDF")
            }
            pagination.pageSize = totalElements;
            pagination.currentPage = 0;
            
        }
        if (key === "search") {
            pagination.currentPage = 0;
            setCurrPage(0)
        } else {
            setLoading(true)
        }
        if (key === "refresh") {
            dataRefresh()
            param.username = "";
            param.fromDate = "";
            param.role = "PTM_RETAILER";
            param.toDate = "";
            param.name = "";
            param.userType="";
            param.pagination = {
                currentPage: 0,
            }
            setCurrPage(0);
        }

        getUserList(param, pagination).then((resp) => {
            setLoading(false)
            setDownloadLoader("");
            if (resp?.data.success) {
                if (key === "CSV") {
                    setPdfData(resp?.data?.data?.content);

                    setTimeout(() => {
                        const csvlink = link.current
                        csvlink.link.click();
                    }, 100)

                } else if (key === "PDF") {
                    userlistPdfData(resp?.data?.data?.content)
                } else {
                    setApiData(resp?.data?.data?.content);
                    setTotalPages(resp?.data?.data?.totalPages);
                    setTotalElements(resp.data.data.totalElements);
                }
            }
        });

    };
    useEffect(() => {
        handleApiData();
    }, [currPage,userType]);


    //API to Export PDF file

    const userlistPdfData = (data = []) => {

        const doc = new jsPDF();
        //Add image in PDF header
        const imgWidth = 25;
        const imgHeight = 9;
        const leftXPos = 14;
        const yPos = 2;
        doc.addImage(companylogo, 'PNG', leftXPos, yPos, imgWidth, imgHeight);

        //Add fileName in the center
        const text = 'User List';
        // Calculate the width of the text
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // Get the width of the PDF
        const pdfWidth = doc.internal.pageSize.width;
        const yPos1 = 10;
        // Calculate the X position for center alignment
        const centerXPos = (pdfWidth - textWidth) / 2;
        doc.text(text, centerXPos, yPos1);
        const generalFontSize = 12;
        doc.setFontSize(generalFontSize);
        doc.setMargins(0,0,0,0);
        // Calculate the width of the date text
        const dateWidth = doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // Calculate the X position for right alignment
        const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge           
        doc.text(formattedDate, rightXPos, yPos1);

        // Create a table from API data
        const tableData = [];
        data.forEach((item, index) => {
            const rowData = [index + 1, item.firstName + " " + item.lastName, item.userName, item.email, item.address1, 'Approved', item.userType]; // Customize as needed
            tableData.push(rowData);
            console.log(" smx", tableData)
        });


        doc.autoTable({
            head: [['S.No', 'Name', 'Mobile No.', 'Email', 'Address', 'Status', 'UserType']], // Customize table header
            body: tableData,
        });
        
        doc.save('userlist.pdf');
    };

    //delete User

    const deletUserData = async (userId) => {

        let user = JSON.parse(sessionStorage.getItem("token"));

        deteleUserFromList(userId).then((resp) => {

            if (resp?.data?.success) {
                Swal.fire({
                    icon: "success",
                    title: "User deleted successfully",
                    timer: 2200,
                    showConfirmButton: "#00BAF2",
                    customClass: "swal-wide",
                });
                handleApiData();
            } else {
                let msg = "";
                if (resp?.data?.errorCodeList.length > 0) {
                    msg = resp?.data?.errorCodeList[0];
                } else {
                    msg = resp?.data?.msg;
                }
                Swal.fire({
                    icon: "error",
                    text: msg,
                    confirmButtonColor: "#ED1C24",
                    customClass: "swal-wide",
                });

            }

        })

    };

    const viewBalance = async (userId) => {
        searchUser(userId).then((resp) => {

            if (resp?.data?.success) {
                setUserBalanceData({ success: resp?.data?.data })
            } else {
                // alert(resp?.data?.msg)
                if (resp?.data?.errorCodeList.length > 0) {
                    setUserBalanceData({ fail: resp?.data?.errorCodeList[0] });
                } else {
                    setUserBalanceData({ fail: resp?.data?.msg });
                }

            }
        })



    }
    console.log("mnm", userBalanceData)

    const deleteUser = async (userId) => {
        Swal.fire({
            title: 'Are you sure! you want to delete this user?',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#00BAF2',
            confirmButtonText: 'Yes, I am sure!',
            cancelButtonText: "No, cancel it!",
            closeOnConfirm: false,
            closeOnCancel: false
        }).then((result) => {
            if (result.isConfirmed) {
                deletUserData(userId)
            }
        });
    }



    const handleDetailData = (e, key = "") => {
        const value = e.target.value;
        if (key === "number") {
            if (!isNumber(value) && !isEmpty(value)) return;
            setDetailData(e.target.value);
        }
        if (key === "text") {
            if (!isAlphabet(value) && !isEmpty(value)) return;
            setName(e.target.value);
        }



    }


    const editUser = (item) => {
        if (item.screenCode === "AEPS_ONBOARDING") {
            Swal.fire({
                icon: "success",
                title: "This user is already onboard",
                timer: 2200,
                showConfirmButton: "#00BAF2'",
                customClass: "swal-wide",
                preConfirm: async () => {
                    navigate("/editUser", { state: item });

                }

            });
        } else if (item.screenCode === "USER_REGISTRATION") {
            Swal.fire({
                icon: "success",
                title: "This user's registration is done,Now you can complete kyc",
                showConfirmButton: "#00BAF2'",
                showCancelButton: true,
                confirmButtonText: 'Yes, I am sure!',
                cancelButtonText: "No, cancel it!",
                customClass: "swal-wide",
                preConfirm: async () => {
                    const userData = {
                        email: item.email,
                        userName: item.userName,
                        uuid: item.uuid,
                    }
                    navigate("/bankdetails", { state: userData });

                }
            })
        } else if (item.screenCode === "USER_KYC") {
            Swal.fire({
                icon: "success",
                title: "This user's KYC is done.Now you can select package",
                showConfirmButton: "#00BAF2'",
                showCancelButton: true,
                confirmButtonText: 'Yes, I am sure!',
                cancelButtonText: "No, cancel it!",
                customClass: "swal-wide",
                preConfirm: async () => {
                    const userData = {
                        email: item.email,
                        userName: item.userName,
                        uuid: item.uuid,
                    }
                    navigate("/select-package", { state: userData });

                }
            })
        } else if (item.screenCode === "PACKAGE_ASSIGNED") {
            Swal.fire({
                icon: "success",
                title: "This user's KYC and  Package Assigned done.Now you can do otp verification to onboard the user",
                showConfirmButton: "#00BAF2'",
                showCancelButton: true,
                confirmButtonText: 'Yes, I am sure!',
                cancelButtonText: "No, cancel it!",
                customClass: "swal-wide",
                preConfirm: async () => {
                    const userData = {
                        email: item.email,
                        userName: item.userName,
                        uuid: item.uuid,
                    }
                    navigate("/Verification", { state: userData });

                }
            })
        }
    }
    const dataRefresh = () => {
        setStartDate("")
        setEndDate("")
        setDetailData("")
        setName("")
        setUserType("")
    }
    return (
        <div className="userlisthead_container">
            <div>
                <div className='transaction_main_container'>
                    <div className='userlist_head'>
                        <div className='userlist_heading'>
                            <div className='userlist_left'>
                                <label>Users List</label>
                            </div>
                            <div className='userlist_right'>
                                {downloadLoader !== "PDF" ? <button className='pdf' onClick={(e) => handleApiData(e, "PDF")}
                                >PDF</button> :
                                    <button className='pdf'>
                                        <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                    </button>
                                }
                                {downloadLoader !== "CSV" ? <button className='xls' onClick={(e) => handleApiData(e, "CSV")}
                                >CSV</button> :
                                    <button className='xls'>
                                        <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                    </button>
                                }
                                <CSVLink
                                    ref={link}
                                    data={pdfData}
                                    filename="Userlist.csv"
                                >
                                </CSVLink>
                            </div>
                        </div>

                    </div>

                    <FilterWrapper style={{ height: "auto" }}>
                        <div className='filterBox'>
                            <div>
                                <div className="flex align-items gap-20">

                                    <div>
                                        <input
                                            type="text"
                                            name="detailData"
                                            placeholder="Enter mobile"
                                            value={detailData}
                                            onChange={(e) => handleDetailData(e, "number")}
                                            maxLength={10}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Enter username"
                                            value={name}
                                            onChange={(e) => handleDetailData(e, "text")}

                                        />
                                    </div>
                                    <div className="input-field">
                                        <select className="input_select_field"
                                        value={userType}
                                        onChange={(e) => setUserType(e.target.value)}>
                                            <option value={""}>User Type</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Retailer">Retailer</option>
                                            <option value="Distributor">Distributor</option>

                                        </select>
                                    </div>
                                    <div className="input-field">
                                        <div className="flex">
                                            {/* <div style={{ position: "absolute", fontSize: "20px", top: "8px", left: "10px", zIndex: "1", color: "#00286F" }}>
                                                <i class="fa-regular fa-calendar"
                                                ></i>
                                            </div> */}
                                            <DatePicker
                                                showIcon
                                                className="date-picker2 date_type"
                                                placeholderText="From Date"
                                                selected={startDate}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown={true}
                                                showYearDropdown
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={date => setStartDate(date)}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-field">
                                        <div className="flex" >
                                            <DatePicker
                                                showIcon
                                                className="date-picker2 date_type"
                                                placeholderText="To Date"
                                                selected={endDate}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown={true}
                                                showYearDropdown
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={date => setEndDate(date)}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex align-items gap-20 pdt-15">

                                    <button className="userlist_btn"
                                        onClick={(e) => handleApiData(e, "search")}
                                    >Search</button>
                                    <button className="userlist_btn"
                                        onClick={() => navigate('/addUser')}>Add New User
                                    </button>
                                    <button className='refresh-btn' onClick={(e) => handleApiData(e, "refresh")}>
                                        <i className="fa fa-refresh"></i>
                                    </button>


                                </div>
                            </div>
                        </div>
                    </FilterWrapper>
                </div>
                <div className="transaction_table">

                    {loading && <LocalLoader className="tableLoader" />}
                    <table id="customers">
                        <tr>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>User Type</th>
                            <th>Created Date</th>
                            <th>View Balance</th>
                            <th>Options</th>
                        </tr>


                        {apiData && apiData?.map((item, index) => {
                            const dateTimeString = item.createdDate;
                            let [date, time] = dateTimeString.split("T");
                            let newTime = time.slice(0, 8);
                            
                            return(
                            <tr className="border" key={item.transactionId}>
                                <td>{(currPage * pageSize) + index + 1}</td>

                                <td >{item.firstName}&nbsp;&nbsp;{item.lastName}</td>
                                <td>{item.email}</td>
                                <td>{item.userName}</td>
                                <td>{item.address1}</td>
                                <td>{item.status} </td>
                                <td>{item.userType}</td>
                                <td>{date+" "+newTime}</td>
                                <td
                                    className="user_action_btn"
                                    >
                                    <button
                                        onClick={() => viewBalance(item.userName)}>

                                        <i class="fa-regular fa-eye fs-16 update"
                                        ></i>
                                    </button></td>
                                <td className="user_action_btn">
                                    <button
                                        className=""
                                        style={{ marginRight: "5px" }}
                                        onClick={() => editUser(item)}
                                    >
                                        <i class="fa-solid fa-pen-to-square update" ></i>
                                    </button>
                                    <button
                                    onClick={() => deleteUser(item.userName)}>
                                        <i class="fa-solid fa-trash delete" ></i>
                                    </button>

                                    {/* Approved */}
                                </td>
                            </tr>
                            );
})}
                        {
                            !loading && apiData && (apiData.length < 1) &&
                            <>
                                <tr>
                                    <td colSpan={16} style={{ textAlign: "center", color: "red", height: "120px", fontSize: "20px" }}>
                                        No Data Found
                                    </td>

                                </tr>
                            </>

                        }
                    </table>

                </div>
                {totalPages > 1 &&
                    <Pagination
                        items={apiData}
                        itemsPerPage={pageSize}
                        setitemsPerPage={setPageSize}
                        currentPage={currPage}
                        setcurrentPage={setCurrPage}
                        totalPages={totalPages}
                    />}

            </div>
            {userBalanceData &&
                <ModalPopUp >
                    <div className="modal-popup"
                        style={{
                            backgroundImage: `url(${modalpopup})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPositionX: "right",
                            backgroundPositionY: "bottom"
                        }}>

                        <div className="flex justify-center mr-20 mt-16"
                            style={{ color: "#8B9CA8" }}
                            onClick={() => setUserBalanceData("")}>
                            <i class="fa-solid fa-xmark"
                                style={{
                                    display: "inline-block",
                                    maxWidth: "40px",
                                    marginLeft: " auto",
                                    fontSize: "25px"
                                }}></i>
                        </div>
                        {!isEmpty(userBalanceData?.success?.mainBalance || !isEmpty(userBalanceData?.success?.aepsBalance)) ? <>
                            <div className="flex justify-center gap-20 fs25 mt-16"
                                style={{ color: "#002970" }}>
                                <h4>{userBalanceData?.success?.firstName}</h4>
                                <h4>{userBalanceData?.success?.lastName}</h4>
                            </div>
                            <div className="flex justify-center gap40 mt-30">
                                <div className="flex gap-15" style={{ paddingRight: "45px" }}>
                                    <div>
                                        <img src={userMainBalance}></img>
                                    </div>
                                    <div className="flex column">
                                        <label>Main Balance</label>
                                        <label>{userBalanceData?.success?.mainBalance}</label>
                                    </div>

                                </div>
                                {!isEmpty(userBalanceData?.success?.aepsBalance) ? <div className="flex gap-15"
                                    style={{ borderLeft: "1px dotted #8B9CA8", paddingLeft: "45px" }}>
                                    <div className="mt-10">
                                        <img src={userAepsBalance}></img>
                                    </div>
                                    <div className="flex column">
                                        <label>AEPS Balance</label>
                                        <label>{userBalanceData?.success?.aepsBalance}</label>
                                    </div>

                                </div> : ""}

                            </div>
                        </> : <div className="flex justify-center"
                            style={{ marginTop: "45px" }}>
                            <h4>User doesn't have Wallet</h4>
                            {/* <h4>{userBalanceData?.fail}</h4> */}
                        </div>}
                    </div>

                </ModalPopUp>
            }

        </div>

    );
};

export default UsersList;