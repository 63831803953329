import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";
import "./style/style.scss"
import AddNewMerchant from './AddNewMerchant';



function MerchantCommissionList() {
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [popUpShow,setPopUpShow] = useState(false)

    const disableFutureDate = () => {
        let today, date1;
        today = new Date();
        date1 = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');
       
        return date1
    }
    // const addNewMerchant = () => {
    //     Swal.fire({
    //         width:"100%",
    //         title: "Are you sure?",
    //         html:`
    //         <div className='edit_operator_list_main_container'>
    //         <div className='edit_operator_container'>
    //             <div className='edit_operator'>
    //                 <div className='edit_operator_left_container'>
    //                     <div className='edit_operator_flex'>
    //                         <div className='flex_edit_operaor'>
    //                             <div className='flex_edit_operator_row'>
    //                                 <label>Operator Name <span>*</span></label>
    //                             </div>
    //                             <div className='flex_edit_operator_row'>
    //                                 <input type='text'
    //                                     placeholder='please enter your first name'
    //                                 ></input>
    //                             </div>

    //                         </div>
    //                         <div className='flex_edit_operaor'>
    //                             <div className='flex_edit_operator_row'>
    //                                 <label>Operator Code <span>*</span></label>
    //                             </div>
    //                             <div className='flex_edit_operator_row'>
    //                                 <input type='text'
    //                                     placeholder='please enter your first name'
    //                                 >

    //                                 </input>
    //                             </div>

    //                         </div>
                            
    //                     </div>
    //                     <div className='edit_operator_flex'>
    //                         <div className='flex_edit_operaor'>
    //                             <div className='flex_edit_operator_row'>
    //                                 <label>Operator Name <span>*</span></label>
    //                             </div>
    //                             <div className='flex_edit_operator_row'>
    //                                 <input type='text'
    //                                     placeholder='please enter your first name'
    //                                 ></input>
    //                             </div>

    //                         </div>
    //                         <div className='flex_edit_operaor'>
    //                             <div className='flex_edit_operator_row'>
    //                                 <label>Operator Code <span>*</span></label>
    //                             </div>
    //                             <div className='flex_edit_operator_row'>
    //                                 <input type='text'
    //                                     placeholder='please enter your first name'
    //                                 >

    //                                 </input>
    //                             </div>

    //                         </div>
                            
    //                     </div>
    //                     <div className='edit_operator_flex'>
    //                         <div className='flex_edit_operaor'>
    //                             <div className='flex_edit_operator_row'>
    //                                 <button>Update Operator</button>
    //                             </div>
    //                         </div>

    //                     </div>

    //                 </div>

    //             </div>
    //         </div>


    //     </div> `,
    //         customClass:{
    //             title:"swal-title",
    //             container: 'my-swal-container',
    //         },
    //         showCloseButton: true,
           
       
    //     })
    // }
  return (
    <div className="userlisthead_container">
            <div>
                <div className='userlist_main_container'>
                    <div className='userlist_head'>
                        <div className='userlist_heading'>
                            <div className='userlist_left'>
                                <label>Merchant Commission List</label>
                            </div>
                            <div className='userlist_right'>
                                <button className='pdf'
                                >PDF</button>
                                <button className='xls btn-success'>CSV </button>
                            </div>
                        </div>

                    </div>
                    <div className='userlist_container'>
                        <table className="userlist_filter_data">
                            <tr >
                                <td className="filter_row">
                                    <label >FilterBy</label>
                                </td>
                                <td className="filter_row">
                                    <select style={{ marginLeft: "20px" }}>
                                        <option>select</option>
                                        <option>Phone No.</option>
                                    </select>
                                </td>
                                <td className="filter_row">
                                    <input style={{ marginLeft: "2px" }}
                                        type="text"
                                        name="detailData"
                                        placeholder="search here"
                                        // value={detailData}
                                        // onChange={(e) => setDetailData(e.target.value)}
                                         />
                                </td>
                                <td className="filter_row">
                                    <button className="search_btn"
                                       >Search</button>
                                </td>

                                <td className="filter_row">
                                    <label style={{ marginLeft: "25px" }}>FilterBy</label>
                                </td>
                                <div style={{ backgroundColor: "#F4F8F8", marginLeft: "10px" }}>
                                    <td style={{ backgroundColor: "#F4F8F8" }}>
                                        <input style={{ backgroundColor: "#F4F8F8" }}
                                            type="date"
                                            name=""
                                            onChange={(e) => setStartDate(e.target.value)}
                                            max={disableFutureDate()}
                                        />
                                    </td>
                                    <td style={{ backgroundColor: "#F4F8F8" }}>
                                        <label style={{ backgroundColor: "#F4F8F8", fontSize: "12px", fontFamily: "Noto sans", fontWeight: "bold" }}>To</label>
                                    </td>
                                    <td>
                                        <input style={{ backgroundColor: "#F4F8F8" }}
                                            type="date"
                                            name=""
                                            onChange={(e) => setEndDate(e.target.value)}
                                            max={disableFutureDate()}
                                        />
                                    </td>
                                </div>
                                <td className="filter_row">
                                    <button className="userlist_btn1"
                                        
                                        style={{ marginLeft: "15px" }}>GO</button>
                                </td >
                                <td className="filter_row">
                                    <button className="userlist_btn" style={{ marginLeft: "5%",width:"140px" }} 
                                    onClick={()=>setPopUpShow(true)}>Add New Commission</button>
                    { popUpShow ? <AddNewMerchant setPopUpShow= {setPopUpShow}/>:""}

                                </td>
                            </tr>

                        </table>
                    </div>

                </div>
                <div className="userlist_table">
                    <table id="customers">
                        <tr>
                            <th>S.No.</th>
                            <th>Merchant Name</th>
                            <th>Plan Name</th>
                            <th>Pay In Commission</th>
                            <th>Pay Out commission</th>
                            <th>Options</th>
                        </tr>
                      
                        {
                            <tbody>
                                
                            </tbody>
                        }

                    </table>

                </div>

                {/* <Pagination
                    items={apiData}
                    itemsPerPage={pageSize}
                    setitemsPerPage={setPageSize}
                    currentPage={currPage}
                    setcurrentPage={setCurrPage}
                    totalPages={totalPages}
                /> */}
            </div>
        </div>
  )
}

export default MerchantCommissionList
