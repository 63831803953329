import React, { useState } from "react";
import { addPackageServices, rolePermission, updateConfigurationServices } from "../../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "../../../utils/common";
import Swal from "sweetalert2";
import { AlertWrapper } from "../../../assets/styles/AlerWrapper";

const Service = () => {
  const [showDrawer, setshowDrawer] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const state = location.state;
  const [showHide, setShowHide] = useState(true);
  
  const packagename = state?.packageName;
 console.log("......",state?.confirguration[0]);
  console.log(".......",state.packageCode);
  const[configuration,setConfiguration] = useState(state?.confirguration[0])
  console.log("xzsxnskcnjdnsjadmzxnsmsjksdjksdjksdjkjksd",configuration);
const navigate = useNavigate();

  const handleRange = (i) => {
    setshowDrawer(!showDrawer);

    setIsActive((current) => !current);
  };

  // const handleRange = (key) => {
  //   if(showDrawer===key){
  //     setshowDrawer("");
  //   }else{
  //     setshowDrawer(key);
  //   }

  //   setIsActive((current) => !current);
  // };

  const [serviceForm, setServiceForm] = useState({
    serviceName: "",
    serviceModel: "",
    priceConfiguration: [
      {
        minAmount: "",
        maxAmount: "",
        feeType: "",
        route: "NEFT",
        fee: "",
        isRangeCheckRequired: true,
        hierarchyDistributions: [
          {
            splitFee: "",
            userType: "",
          },
        ],
      },
    ],
  });
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState("");

  const ServiceformConfig = (e) => {
    setServiceForm({ ...serviceForm, [e.target.name]: e.target.value });

    console.log("service", e.target.value);
  };

  const handleChange = (e, i, j) => {
    const clonedData = { ...serviceForm };

    if (j !== undefined) {
      clonedData["priceConfiguration"][i]["hierarchyDistributions"][j][
        e.target.name
      ] = e.target.value;
    } else {
      clonedData["priceConfiguration"][i][e.target.name] = e.target.value;
    }

    setServiceForm(clonedData);
  };
  const handleUpdateChange = (e, i, j) => {
    const clonedData = {...configuration};

    if (j !== undefined) {
      clonedData["priceConfiguration"][i]["hierarchyDistributions"][j][
        e.target.name
      ] = e.target.value;
    } else {
      clonedData["priceConfiguration"][i][e.target.name] = e.target.value;
    }

    setConfiguration(clonedData);
  };

  const handleCreateServices = () => {
    let error = {};

    if (serviceForm.serviceName === "") {
      error.serviceName = "Please Select Service Name";
    }
    if (serviceForm.serviceModel === "") {
      error.serviceModel = "Please Select Service Model";
    }

    {
      serviceForm.priceConfiguration.map((data, index) => {
        if (data.minAmount === "") {
          error.minAmount = "Please Select Minimum Amount";
        }
        if (data?.maxAmount === "") {
          error.maxAmount = "Please Select Maximum Amount";
        }
        if (data?.minAmount > data?.maxAmount) {
          error.minAmount = "min amount can not be less than max amount";
        }
        if (data?.feeType === "") {
          error.feeType = "Please Select Commission Type";
        }

        if (data?.fee === "") {
          error.fee = "Please Enter Net Comission";
        }
        {
          data?.hierarchyDistributions.map((items, j) => {
            if (items?.splitFee === "") {
              error.splitFee = "Please Enter Role Wise Comission";
            }

            if (items.userType === "") {
              error.userType = "please Enter Role";
            }
          });
        }
      });
    }

    if (isEmpty(error)) {
      console.log(serviceForm);

      let a = "";
      for (let i = 0; i < serviceForm.priceConfiguration.length; i++) {
        let sum = 0;
        for (
          let j = 0;
          j < serviceForm.priceConfiguration[i].hierarchyDistributions.length;
          j++
        ) {
          sum += Number(
            serviceForm.priceConfiguration[i].hierarchyDistributions[j].splitFee
          );
        }
        if (
          serviceForm.priceConfiguration[i].feeType == "FLAT" &&
          Number(serviceForm.priceConfiguration[i].fee) < sum
        ) {
          a = 2;
          Swal.fire({
            timer: 2000,
            icon: "warning",
            title: `Net commission can not be less  in Range ${i + 1}`,
          });
          return false;
        }
      }

      if (serviceForm?.priceConfiguration?.length > 1) {
        for (let i = 0; i < serviceForm?.priceConfiguration?.length; i++) {
          if (
            Number(serviceForm?.priceConfiguration[i + 1]?.minAmount) <=
            Number(serviceForm?.priceConfiguration[i]?.maxAmount)
          ) {
            a = 1;
            Swal.fire({
              timer: 2000,
              icon: "warning",
              title: `enter valid range ${i + 1}`,
            });
            return false;
          }
        }
      }

      if (a !== "") {
        return;
      }
      addPackageServices(serviceForm, packagename).then((res) => {
        console.log("serdfdfgvd", res);

        if (res?.data?.success) {
          setServiceForm({
            serviceName: "",
            serviceModel: "",
            priceConfiguration: [
              {
                minAmount: "",
                maxAmount: "",
                feeType: "",
                route: "NEFT",
                fee: "",
                isRangeCheckRequired: true,
                hierarchyDistributions: [
                  {
                    splitFee: "",
                    userType: "",
                  },
                ],
              },
            ],
          });
          setMsg("Package Created Succesfully");
        }
      });
    }
    console.log("sdsjdjasj", error);
    setErrors(error);

    console.log("serdfdfgvd", serviceForm);
  };

  function handleRole(columnId) {
    const obj = {
      splitFee: "",
      userType: "",
    };
    // let newColums = {...serviceForm.priceConfiguration}; // Create a copy of the state using spread operator

    let newColums = serviceForm.priceConfiguration;
    newColums[columnId].hierarchyDistributions.push(obj); // Add the new item
    setServiceForm({ ...serviceForm }); // Set the state

    console.log("ddddddd", serviceForm, columnId);
  }

  function handleRoleMinus(columnId) {
    let newColums = serviceForm.priceConfiguration;
    newColums[columnId].hierarchyDistributions.pop(); // Add the new item
    setServiceForm({ ...serviceForm }); // Set the state

    console.log("ddddddd", serviceForm);
  }

  function handleAddRange() {
    const obj = {
      minAmount: "",
      maxAmount: "",
      feeType: "",
      route: "NEFT",
      fee: "",
      isRangeCheckRequired: true,
      hierarchyDistributions: [
        {
          splitFee: "",
          userType: "",
        },
      ],
    };
    let newColums = serviceForm;
    newColums.priceConfiguration.push(obj); // Add the new item
    setServiceForm({ ...serviceForm });
    console.log("ddddddd", serviceForm);
  }
  function handleMinusRange() {
    let newColums = serviceForm;
    newColums.priceConfiguration.pop(); // Add the new item
    setServiceForm({ ...serviceForm });
    console.log("ddddddd", serviceForm);
  }
  const handleBack = () => {
    navigate("/CreatePackage");
  };

   const handleUpdateConfiguration = (counts,role)=>{
 const  param ={
  packageCode: state.packageCode,
  serviceName: state?.confirguration[0]?.serviceName,
  serviceModel: state?.confirguration[0]?.serviceModel,
  minAmount: counts.minAmount,
  maxAmount: counts.maxAmount,
  route: "NEFT",
  hierarchyDistributions: [
    {
      splitFee: role.splitFee,
      userType: role.userType
    }
  ]
    }

    updateConfigurationServices(param).then((resp)=>{
      console.log("hjhcjhsjdchsjdhcjsk....", resp);

    })

   }
   const handleClick =()=>{
    navigate('/PackageManagement')
   }




  return (
    <>
      {msg && (
        <AlertWrapper className="success globle">
          <span className="close" onClick={() => setMsg("")}>
            ×
          </span>
          {msg}
        </AlertWrapper>
      )}

      {serviceForm && isEmpty(state?.confirguration) && (
        <div className="createpkg_container padding_30">
          <div onClick={handleBack} className="flex align-items gap10 cursor">
            <i class="fa-solid fa-arrow-left-long"></i>
            <p>Back</p>
          </div>
          <h2>Create Package</h2>
          <div className="container_progress1">
            <div className="round round-adduser">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-1" />
              <div className="lable">Package Details</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-2 " />
              <div className="lable">Service Configuration</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="bar bar-2" style={{ visibility: "hidden" }} />
              <div className="lable fs-20 bold">View Package</div>
            </div>{" "}
          </div>
          <div className="container mt-20">
            <div className="mt-20">
              <h4>Service And Price Confguration</h4>
              <div className="flex gap-20">
                <div>
                  <lable className="filter_text fs">Service Name</lable>

                  <div className="mt-50">
                    <select
                      name="serviceName"
                      onChange={(e) => ServiceformConfig(e)}
                      className="filter_select "
                    >
                      <option selected disabled>
                        SELECT SERVICES
                      </option>
                      <option value="PAY_OUT">PAY OUT</option>
                      <option value="AEPS">AEPS</option>
                      <option value="BBPS">BBPS</option>
                      <option value="SEND_MONEY">Send Money</option>
                      <option value="PREPAID_MOBILE_RECHARGE">
                        Mobile Recharge
                      </option>
                      <option value="ADD_MONEY">Add Money</option>
                    </select>
                  </div>
                  {errors.serviceName && (
                    <p className="error fs-12 mt-10">{errors.serviceName}</p>
                  )}
                </div>

                <div>
                  <lable className="filter_text fs">Service Model</lable>

                  <div>
                    <select
                      name="serviceModel"
                      onChange={(e) => ServiceformConfig(e)}
                      className="filter_select "
                    >
                      <option disabled selected value="">
                        SELECT MODEL
                      </option>
                      <option value="CHARGEABLE">CHARGABLE</option>
                      <option value="NON_CHARGEABLE">NON CHARGABLE</option>
                    </select>
                  </div>
                  {errors.serviceModel && (
                    <p className="error fs-12 mt-10">{errors.serviceModel}</p>
                  )}
                </div>
              </div>

              {serviceForm &&
                serviceForm?.priceConfiguration?.map((counts, index) => {
                  return (
                    <div className="container  mt-20" key={index}>
                      <div className="flex gap-20">
                        <div>
                          <lable className="filter_text fs">
                            Range:{index + 1}
                          </lable>
                          <div className="flex mt-5 gap10">
                            <div>
                              <input
                                type="number"
                                name="minAmount"
                                value={state?.configuration?.minAmount}
                                onChange={(e) => handleChange(e, index)}
                                className="filter_select"
                                placeholder="Min Amount"
                                min={1}
                                required
                              ></input>{" "}
                              {errors.minAmount && (
                                <p className="error fs-12 mt-10">
                                  {errors.minAmount}
                                </p>
                              )}
                              {/* {errors && errors.map((error,index)=>{
                            
                            return(
                              <>
                               
                              </>
                          

                            )
                          
                         
                          
                          }) } */}
                            </div>

                            <div>
                              <input
                                type="number"
                                name="maxAmount"
                                value={counts.maxAmount}
                                onChange={(e) => handleChange(e, index)}
                                className="filter_select"
                                placeholder="Max Amount"
                                required
                              ></input>
                              {errors.minAmount && (
                                <p className="error fs-12 mt-10">
                                  {errors.minAmount}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <lable className="filter_text fs">
                            Commission Type:
                          </lable>
                          <div className="mt-5">
                            <select
                              onChange={(e) => handleChange(e, index)}
                              name="feeType"
                              className="filter_select"
                            >
                              <option disabled selected>
                                SELECT TYPE
                              </option>
                              <option value="FLAT">FLAT</option>
                              <option value="PERCANTAGE">PERCANTAGE</option>
                            </select>
                          </div>
                          {errors.feeType && (
                            <p className="error fs-12 mt-10">
                              {errors.feeType}
                            </p>
                          )}
                        </div>
                        <div>
                          <lable className="filter_text fs">
                            Net Commission:
                          </lable>
                          <div className="mt-5">
                            <input
                              type="text"
                              name="fee"
                              value={counts.fee}
                              onChange={(e) => handleChange(e, index)}
                              className="filter_select"
                              placeholder="Commission"
                            ></input>
                          </div>
                          {errors.fee && (
                            <p className="error fs-12 mt-10">{errors.fee}</p>
                          )}
                        </div>
                        <div
                          className={isActive ? "transform" : "transition"}
                          onClick={handleRange}
                        >
                          <i class="fa-solid fa-angle-down pointer"></i>
                        </div>
                      </div>

                      {showDrawer ? (
                        <div
                          className="mt-20 flex align-items"
                          style={{ transition: "all 15s ease-in " }}
                        >
                          <h5>Hiracrcy</h5>
                          <div className="container range">
                            {serviceForm.priceConfiguration[
                              index
                            ].hierarchyDistributions.map((role, Subindex) => {
                              return (
                                <div
                                  className=" flex gap-20 mt-10 "
                                  key={Subindex}
                                >
                                  <div>
                                    <lable className="filter_text fs">
                                      Role
                                    </lable>
                                    <div>
                                      <input
                                        name="userType"
                                        value={role.userType}
                                        onChange={(e) =>
                                          handleChange(e, index, Subindex)
                                        }
                                        className="filter_select"
                                        type="text"
                                        placeholder="Role"
                                      ></input>
                                    </div>
                                    {errors.userType && (
                                      <p className="error fs-12 mt-10">
                                        {errors.userType}
                                      </p>
                                    )}
                                  </div>
                                  <div>
                                    <lable className="filter_text fs">
                                      Commission:
                                    </lable>
                                    <div>
                                      <input
                                        name="splitFee"
                                        value={role.splitFee}
                                        onChange={(e) =>
                                          handleChange(e, index, Subindex)
                                        }
                                        className="filter_select"
                                        type="text"
                                        placeholder="Commision"
                                      ></input>
                                    </div>
                                    {errors.splitFee && (
                                      <p className="error fs-12 mt-10">
                                        {errors.splitFee}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div>
                            <button
                              onClick={() => handleRole(index)}
                              className="role_btn mt-20"
                            >
                              +
                            </button>

                            {serviceForm.priceConfiguration[index]
                              .hierarchyDistributions.length > 1 ? (
                              <div>
                                <button
                                  onClick={() => handleRoleMinus(index)}
                                  className=" role_btn mt-20"
                                >
                                  -
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}

              <div className="flex gap-20">
                <button onClick={handleAddRange} className="userlist_btn mt-20">
                  Range+
                </button>

                {serviceForm.priceConfiguration.length > 1 ? (
                  <button
                    onClick={handleMinusRange}
                    className="userlist_btn mt-20"
                  >
                    Range-
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleCreateServices}
              className="userlist_btn mt-20"
              style={{ width: "200px" }}
            >
              Create Configuration
            </button>
          </div>
        </div>
      )}

      {state?.confirguration.length > 0 && (
        <div className="createpkg_container padding_30">
          <div onClick={handleBack} className="flex align-items gap10 cursor">
            <i class="fa-solid fa-arrow-left-long"></i>
            <p>Back</p>
          </div>
          <h2>Update Package</h2>
          <div className="container_progress1">
            <div className="round round-adduser">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-1" />
              <div className="lable">Package Details</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox" className="active"></label>
              </div>
              <div className="bar bar-2 " />
              <div className="lable">Service Configuration</div>
            </div>
            <div className="round">
              <div>
                <input type="checkbox" checked id="checkbox" />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="bar bar-2" style={{ visibility: "hidden" }} />
              <div className="lable fs-20 bold">View Package</div>
            </div>{" "}
          </div>
          <div className="container mt-20">
            <div className="mt-20">
              <h4>Service And Price Confguration</h4>
              <div className="flex gap-20">
                <div>
                  <lable className="filter_text fs">Service Name</lable>

                  <div className="mt-5">
                    <input
                      type="text"
                      value={state?.confirguration[0]?.serviceName}
                    ></input>
                  </div>
                  {errors.serviceName && (
                    <p className="error fs-12 mt-10">{errors.serviceName}</p>
                  )}
                </div>

                <div>
                  <lable className="filter_text fs">Service Model</lable>

                  <div>
                    <input
                      type="text"
                      value={state?.confirguration[0]?.serviceModel}
                    ></input>
                  </div>
                  {errors.serviceModel && (
                    <p className="error fs-12 mt-10">{errors.serviceModel}</p>
                  )}
                </div>
              </div>

              {state?.confirguration.length > 0 &&
                state?.confirguration[0]?.priceConfiguration?.map(
                  (counts, index) => {
                    const role ={}
                    return (
                      <>
                     <div className="container  mt-20" key={index}>
                        <div className="flex gap-20">
                          <div>
                            <lable className="filter_text fs">
                              Range:{index + 1}
                            </lable>
                            <div className="flex mt-5 gap10">
                              <div>
                                <input
                                  type="number"
                                  name="minAmount"
                                  value={counts.minAmount}
                                  onChange={(e) => handleUpdateChange(e, index)}
                                  className="filter_select"
                                  placeholder="Min Amount"
                                  min={1}
                                  required
                                ></input>{" "}
                                {errors.minAmount && (
                                  <p className="error fs-12 mt-10">
                                    {errors.minAmount}
                                  </p>
                                )}
                                {/* {errors && errors.map((error,index)=>{
                            
                            return(
                              <>
                               
                              </>
                          

                            )
                          
                         
                          
                          }) } */}
                              </div>

                              <div>
                                <input
                                  type="number"
                                  name="maxAmount"
                                  value={counts.maxAmount}
                                  onChange={(e) => handleUpdateChange(e, index)}
                                  className="filter_select"
                                  placeholder="Max Amount"
                                  required
                                ></input>
                                {errors.minAmount && (
                                  <p className="error fs-12 mt-10">
                                    {errors.minAmount}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div>
                            <lable className="filter_text fs">
                              Commission Type:
                            </lable>
                            <div className="mt-5">
                              <select
                                value={counts.feeType}
                                onChange={(e) => handleUpdateChange(e, index)}
                                name="feeType"
                                className="filter_select"
                              >
                                <option disabled selected>
                                  SELECT TYPE
                                </option>
                                <option value="FLAT">FLAT</option>
                                <option value="PERCANTAGE">PERCANTAGE</option>
                              </select>
                            </div>
                            {errors.feeType && (
                              <p className="error fs-12 mt-10">
                                {errors.feeType}
                              </p>
                            )}
                          </div>
                          <div>
                            <lable className="filter_text fs">
                              Net Commission:
                            </lable>
                            <div className="mt-5">
                              <input
                                type="text"
                                name="fee"
                                value={counts.fee}
                                onChange={(e) => handleUpdateChange(e, index)}
                                className="filter_select"
                                placeholder="Commission"
                              ></input>
                            </div>
                            {errors.fee && (
                              <p className="error fs-12 mt-10">{errors.fee}</p>
                            )}
                          </div>
                          <div
                            className={isActive ? "transform" : "transition"}
                            onClick={handleRange}
                          >
                            <i class="fa-solid fa-angle-down pointer"></i>
                          </div>
                        </div>

                        {showDrawer ? (
                          <div
                            className="mt-20 flex align-items"
                            style={{ transition: "all 15s ease-in " }}
                          >
                            <h5>Hiracrcy</h5>
                            <div className="container range">
                              {
                              
                              counts?.hierarchyDistributions?.map((role, Subindex) => {
                                role = role;
                                return (
                                  <div
                                    className=" flex gap-20 mt-10 "
                                    key={Subindex}
                                  >
                                    <div>
                                      <lable className="filter_text fs">
                                        Role
                                      </lable>
                                      <div>
                                        <input
                                          name="userType"
                                          value={role.userType}
                                          onChange={(e) =>
                                            handleUpdateChange(e, index, Subindex)
                                          }
                                          className="filter_select"
                                          type="text"
                                          placeholder="Role"
                                        ></input>
                                      </div>
                                      {errors.userType && (
                                        <p className="error fs-12 mt-10">
                                          {errors.userType}
                                        </p>
                                      )}
                                    </div>
                                    <div>
                                      <lable className="filter_text fs">
                                        Commission:
                                      </lable>
                                      <div>
                                        <input
                                          name="splitFee"
                                          value={role.splitFee}
                                          onChange={(e) =>
                                            handleUpdateChange(e, index, Subindex)
                                          }
                                          className="filter_select"
                                          type="text"
                                          placeholder="Commision"
                                        ></input>
                                      </div>
                                      {errors.splitFee && (
                                        <p className="error fs-12 mt-10">
                                          {errors.splitFee}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                );
                              
                              
                              })}
                            </div>
                            <div>
                              <button
                                onClick={() => handleRole(index)}
                                className="role_btn mt-20"
                              >
                                +
                              </button>

                              {
                               counts?.hierarchyDistributions?.length > 1 ? (
                                <div>
                                  <button
                                    onClick={() => handleRoleMinus(index)}
                                    className=" role_btn mt-20"
                                  >
                                    -
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      
                      <div style={{ textAlign: "center" }}>
            <button
              onClick={()=>handleUpdateConfiguration(counts,role)}
              className="userlist_btn mt-20"
              style={{ width: "200px" }}
            >
              Update Configuration
            </button>
          </div>

                      </>
                      
                    );
                  }
                )}

              <div className="flex gap-20">
                <button onClick={handleAddRange} className="userlist_btn mt-20">
                  Range+
                </button>

                {serviceForm.priceConfiguration.length > 1 ? (
                  <button
                    onClick={handleMinusRange}
                    className="userlist_btn mt-20"
                  >
                    Range-
                  </button>
                ) : (
                  ""
                )}
              </div>
         
         <button onClick={handleClick} className="userlist_btn mt-20">
          Skip
         </button>

            </div>
          </div>
          
        </div>
      )}
    </>
  );
};

export default Service;
