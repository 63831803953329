import React from 'react'
import "../AdminProfile/style/admin.scss"
import { useState, useEffect } from 'react';
import Input from '../../Components/Input/Input';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Profile = () => {

   
    const navigate = useNavigate();

      const {user} = useSelector((state)=> state.user)
      console.log("xxsm,s",user)
 
    return (

        <div className='admin_profile_container'>
            <div className='admin_profile_header'>
                <h2>Admin Profile</h2>
            </div>
            <div className='admin_profile_main'>
            <div className="admin_profile_row">
        <div className="admin_profile_column">
            <label className="label_head">
                Personal Details
            </label>
        </div>

    </div>
    <div className="admin_profile_row">

        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    UserName
                </label>
                <span className="required"> *</span>
            </div>

            <Input
                type="text"
                placeholder="enter you username"
                name="userName"
                value={user?.userName}

            />
        </div>

    </div>
    <div className="admin_profile_row">

        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    Firstname
                </label>
                <span className="required"> *</span>
            </div>
            <div>
                <Input className="inputtype"
                    type="text"
                    placeholder="enter you firstName"
                    name="firstName"
                    value={user?.firstName}
                />

            </div>

        </div>
        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    Lastname
                </label>
                <span className="required"> *</span>
            </div>
            <Input className="inputtype"
                type="text"
                placeholder="enter you lastName"
                name="lastName"
                value={user?.lastName}

            />

        </div>
    </div>
    <div className="admin_profile_row">

        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    DOB
                </label>
                <span className="required"> *</span>
            </div>
            <div>
                <Input
                    type="text"
                    placeholder="enter you dob"
                    name="dob"
                    value={"03/03/1989"}
                 />

            </div>



        </div>

    </div>

    {/* Contact Infomation */}


    <div className="admin_profile_row">
        <div className="admin_profile_column">
            <label className="label_head">
                Contact Details
            </label>
        </div>

    </div>

    <div className="admin_profile_row">

        <div className="admin_profile_column">
            <div className="labelname">
                <label >
                    Contact Number
                </label>
                <span className="required"> *</span>
            </div>
            <div>
                <Input
                    type="text"
                    placeholder="enter you mobile number"
                    name="phoneNumber"
                    value={user?.userName}

                />

            </div>
        </div>
        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    Email Address
                </label>
                <span className="required"> *</span>
            </div>
            <Input
                type="text"
                placeholder="enter you email"
                name="email"
                value={user?.email}

            />

        </div>
    </div>


    {/* Address Details */}

    <div className="admin_profile_row">
        <div className="admin_profile_column">
            <label className="label_head">
                Address Details
            </label>
        </div>

    </div>

    <div className="admin_profile_row">

        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    Address1
                </label>
                <span className="required"> *</span>
            </div>
            <div>
                <Input
                    type="text"
                    placeholder="enter you address"
                    name="address1"
                    value={user?.address1}

                />

            </div>
      </div>
        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    Address2
                </label>

            </div>
            <Input
                type="text"
                placeholder="enter you address2"
                name="address2"
                value={user?.address2}

            />
        </div>
    </div>
    <div className="admin_profile_row">

        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    Landmark
                </label>
            </div>
            <div>
                <Input
                    type="text"
                    placeholder="enter you landmark"
                    name="landmark"
                    value={user?.landmark}

                />
            </div>
        </div>
        <div className="admin_profile_column">
            <div className="labelname">
                <label>
                    Picode
                </label>
                <span className="required"> *</span>
            </div>
            <Input
                type="text"
                placeholder="enter you pincode"
                name="pincode"
                value={user?.pincode}

            />

        </div>
    </div>
    <div className="admin_profile_row">
        <div className="admin_profile_column">
        <button
            className="btn"
            style={{
                backgroundColor: "#00BAF2",
                height: "40px",
                    width: "330px",
                    border:"none",
                    marginBottom:"2%",
            }}
            onClick={()=>{
                navigate("/usersList")
            }}
        >
            Close
        </button>
        </div>
        <div className="admin_profile_column">
        <button
            className="btn"
            style={{
                backgroundColor: "#00BAF2",
                height: "40px",
                width: "330px",
                border:"none",
                marginBottom:"2%",
                

            }}
            onClick={()=>navigate("/editprofile",{state:user})}
        >
            Edit
        </button>
        </div>
    </div>           
            </div>
        </div>

    )
}
export default Profile