import React from 'react';
import { useEffect } from 'react';
import animationData from '../../assets/img/Animation-done.json';
import Lottie from "react-lottie";
import { Link } from 'react-router-dom';

function Completed() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
      };

  return (
    <div className='ThanksWrapper '> 
 
      <div className="center">
      <div className="animation">
      <div className="container_lottie">
            <Lottie options={defaultOptions} height={190} width={190}></Lottie>
          </div>
        </div>
        </div>

        <h1 className='text-center'>Welcome, Flow Finance Solutions!</h1>
        <p className='text-center'>Now that you've successfully add your organization.</p>
       <Link to="/" className="btn mx-auto" style={{maxWidth:"300px"}}>BACK TO DASHBOARD</Link>
 
    
      
    </div>
  );
}

export default Completed;
