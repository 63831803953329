import React from "react";
import LosBoxTop from "./LosBoxTop";

import { Link } from "react-router-dom";

function DocumentsUpload() {
  return (
    <div className="mainBoxWrapper">
      <LosBoxTop step="3" />
      <div className="fromBox">
        <div className="mainfrom flex">
        <div className="from-group">
          <div className="from-field">
            <label htmlFor="">
            GST Certificate <span className="red">*</span>
            </label>
            <div className="input-withButton flex">
              <input
                type="file"
                id="upload-3"
                name="salary"
              
                style={{ display: "none" }}
              />
              <input className="fs-16" type="text"  />
              <label htmlFor="upload-3" className="button-round center">
              UOLOAD
              </label>
            </div>
          </div>
          <div className="msg">
            
          </div>
        </div>
        <div className="from-group">
          <div className="from-field">
            <label htmlFor="">
            PAN <span className="red">*</span>
            </label>
            <div className="input-withButton flex">
              <input
                type="file"
                id="upload-2"
                name="salary"
              
                style={{ display: "none" }}
              />
              <input className="fs-16" type="text"  />
              <label htmlFor="upload-2" className="button-round center">
              UOLOAD
              </label>
            </div>
          </div>
          <div className="msg">
            
          </div>
        </div>
        <div className="from-group">
          <div className="from-field">
            <label htmlFor="">
            Organization Certificate *<span className="red">*</span>
            </label>
            <div className="input-withButton flex">
              <input
                type="file"
                id="upload-1"
                name="salary"
              
                style={{ display: "none" }}
              />
              <input className="fs-16" type="text"  />
              <label htmlFor="upload-1" className="button-round center">
              UOLOAD
              </label>
            </div>
          </div>
          <div className="msg">
            
          </div>
        </div>
        
       
        </div>
        <div className="submitButtom">
          <Link className="btn" to="/api-key-configuration">
          NEXT
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DocumentsUpload;
