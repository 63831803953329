import React from 'react'

export default function NewSubadmin() {
    return (
        <div className='subadmin_main_container'>
            <div className='subadmin_head'>
                <strong>New Subadmin</strong>
            </div>
            <div className='subadmin_container'>
                <div className='subadmin_col_container'>
                    <div className='subadmin_left_container'>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <strong>Member Information</strong>
                                </div>
                            </div>
                        </div>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                  <label>First Name <span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                  <input  type='text' placeholder='please enter your first name'></input>
                                </div>
                            </div>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                  <label>Last Name <span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                  <input  type='text' placeholder='please enter your last name'></input>
                                </div>
                            </div>
                        </div>
                        <div className='flex_subadmin' style={{marginTop:"8%"}}>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                  <button>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='subadmin_right_container'>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <strong>Contact Information</strong>
                                </div>
                            </div>
                        </div>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                  <label>Email <span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                  <input  type='email' placeholder='please enter your email'></input>
                                </div>
                            </div>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                  <label>Contact No. <span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                  <input  type='number' placeholder='please enter your contact number'></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
