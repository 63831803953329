import styled from "styled-components";

export const ModalWrapper = styled.div `
    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color:rgb(225 225 225 / 14%);
    border-radius: 10px;
    backdrop-filter: brightness(0.75);
    
    .modal-dialog {
        position: relative;
        top: 80px;
        left: 70px;
        max-width: 700px;
        margin: 50px auto;
        transform: translateY(0);
        transition: transform .3s ease-out,-webkit-transform .3s ease-out;
        overflow: hidden;
        background: #F9F9F9;
        border-radius: 10px;
    }
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-clip: padding-box;
       // border: 1px solid var(--ip-border-color);
        outline: 0;
    }
    .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 25px;
        
        .modal-title{
            
            font-size: 25px;
            color:#002970;
            font-weight: 700;
            display: flex;
            align-items: center;
            .icon{
                width: 34px;
                height: 20px;
                border-radius: 50%;
                color:#fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                font-weight: 400;
                background-color: #FF6600;
            }
        }
        
    }

    .modal_backgroud{
        background: url(../../../assets/images/subadmin_bg.png) no-repeat right bottom #FFFFFF;
    }


`