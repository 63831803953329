import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { bankDetailsKyc } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import { isAlphabet, isEmpty, isIdPattern, isNumber } from "../../utils/common";

export default function BankDetails() {
  const navigate = useNavigate();
  const location = useLocation();

  const userData = location.state;
  const InitialValues = {
    aadhaarNo: "",
    panNo: "",
    firstName: "",
    lastName: "",
    userName: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    outletId: "",
    userName: userData?.userName,
  };
  const INIT_ERRORS = {
    aadhaarno: "",
    panno: "",
    firstname: "",
    lastname: "",
    userName: "",
    bankname: "",
    accountnumber: "",
    ifsccode: "",
    outletId: "",
  };

  const [bankData, setBankData] = useState(InitialValues);
  const [errors, setErrors] = useState(INIT_ERRORS);
  const [loading, setLoading] = useState(false);

  const userBankDetail = async (e) => {
    let regifsc = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    let regpan = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}$/);
  
    if (bankData.aadhaarNo === "") {
      setErrors((prev) => ({
        ...prev,
        aadhaarno: "Aadhar number can't be null",
      }));
    } else if (bankData.aadhaarNo.length < 12) {
      setErrors((prev) => ({
        ...prev,
        aadhaarno: "Aadhar Number can't be less than 12 digits",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        aadhaarno: "",
      }));

    }
    if (bankData.panNo === "") 
    {
      setErrors((prev) => ({
        ...prev,
        panno: "Please Enter Your Pan Card Number",
      }));
    } else if (bankData.panNo.length < 10) {
      setErrors((prev) => ({
        ...prev,
        panno: "Pan number can't be less than 10 digits",
      }));
    } else if (regpan.test(bankData.panNo) != true) {
      setErrors((prev) => ({
        ...prev,
        panno: "Please Enter valid Pan Number",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        panno: "",
      }));

    }
    if (bankData.firstName === "") {
      setErrors((prev) => ({
        ...prev,
        firstname: "Please enter your first name",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        firstname: "",
      }));

    }
    if (bankData.lastName === "") {
      setErrors((prev) => ({
        ...prev,
        lastname: "Please enter your last name",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        lastname: "",
      }));

    }
    if (bankData.userName === "") {
      setErrors((prev) => ({
        ...prev,
        userName: "Please enter your Mobile Number",
      }));
    } else if (bankData.userName.length < 10) {
      setErrors((prev) => ({
        ...prev,
        userName: "Mobile number can't be less than 10 digits",
      }));
    }else if (/\s/.test(bankData.userName)) {
      setErrors((prev) => ({
        ...prev,
        userName:"Please enter valid mobile number",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        userName: "",
      }));

    }
    if (bankData.bankName === "") {
      setErrors((prev) => ({
        ...prev,
        bankname: "Please enter your Bank Name",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        bankname: "",
      }));
    }
    if (bankData.accountNumber === "") {
      setErrors((prev) => ({
        ...prev,
        accountnumber: "Please enter your Account Number",
      }));
    } else if (bankData.accountNumber.length < 11) {
      setErrors((prev) => ({
        ...prev,
        accountnumber: "Bank account number can't be less than 11 digits",
      }));
    }else if (/\s/.test(bankData.accountNumber)) {
      setErrors((prev) => ({
        ...prev,
        accountnumber: "Please enter valid account number",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        accountnumber: "",
      }));
    }

    if (bankData.ifscCode === "") {
      setErrors((prev) => ({
        ...prev,
        ifsccode: "Please Enter IFSC Code",
      }));
    } else if (regifsc.test(bankData.ifscCode) != true) {
      setErrors((prev) => ({
        ...prev,
        ifsccode: "enter the valid format of IFSC Code",
      }));
    } 

    else if(isEmpty(errors.aadhaarno) && isEmpty(errors.firstname) && isEmpty(errors.lastname) && isEmpty(errors.accountnumber) && isEmpty(errors.bankname) && isEmpty(errors.userName) && isEmpty(errors.ifsccode))
    {
      setLoading(true);
      bankDetailsKyc(bankData).then((resp) => {
        setLoading(false);
        if (resp.data.success === true) {
          Swal.fire({
            icon: "success",
            title: "User Created Successfully",
            timer: 2200,
            showConfirmButton: "#3bee4a",
          });
          navigate("/select-package", { state: userData });
        } else {
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.msg;
          }
          Swal.fire({
            icon: "error",
            text: msg,
            confirmButtonColor: "#ED1C24",
          });
        }
      });
    
    }
 else {
  setErrors((prev) => ({
    ...prev,
    ifsccode: "",
  }));
    }
    
};
 
  const handleInput = (e, key = "") => {
    let name = e.target.name;
    let value = e.target.value;
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    } else if (key === "textNum") {
      if (!isIdPattern(value) && !isEmpty(value)) {
        return;
      }
    }
    if (name === "panNo" || name === "ifscCode") {
      let upperCase = value.toUpperCase();
      setBankData({ ...bankData, [name]: upperCase });
    } else {
      setBankData({ ...bankData, [name]: value });
    }

    //setBankData({ ...bankData, [name]: value });
  };



  return (
    <div className="main_container">
      <div className="container1">
        <label>Create New Retailer</label>
      </div>
      <div className="container_form">
        <div className="container_progress1">
          <div className="round round-adduser">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Retailer Detail</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" className="active"></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable">Bank Detail</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-3" />
            <div className="lable">ID Verification</div>
          </div>{" "}
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-4" />
            <div className="lable lable-last">Otp Verification</div>
          </div>
        </div>
        {/* <div className="role">
          <div className="retailer">
            <label className="label_head">UIDAI Details</label>
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>
                Aadhar No.<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="enter  your aadhar no."
                maxLength={12}
                name="aadhaarNo"
                value={bankData.aadhaarNo}
                onChange={(e) => handleInput(e, "number")}
              ></input>
              </div>
            {errors.aadhaarno && (
                <p className="error fs-12">{errors.aadhaarno}</p>
              )}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>
                Pan No.<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="enter your pan number"
                maxLength={10}
                name="panNo"
                value={bankData.panNo}
                onChange={(e) => handleInput(e, "textNum")}
              ></input>
            </div>
            {errors.panno && (
                <p className="error fs-12">{errors.panno}</p>
              )}
          </div>
        </div> */}

        {/* Bank Details */}

        <div className="role">
          <div className="retailer">
            <label className="label_head">Bank Details</label>
          </div>
        </div>

        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>
                First Name.<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter your first name"
                name="firstName"
                value={bankData.firstName}
                onChange={(e) => handleInput(e, "text")}
              ></input>
            </div>
            {errors.firstname && (
                <p className="error fs-12">{errors.firstname}</p>
              )}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>
                Last Name. <span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter last name"
                name="lastName"
                value={bankData.lastName}
                onChange={(e) => handleInput(e, "text")}
              ></input>
            </div>
            {errors.lastname && (
                <p className="error fs-12">{errors.lastname}</p>
              )}
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>
                Mobile No<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="mobile"
                placeholder="Please enter registered mobile no."
                name="userName"
                maxLength={10}
                value={bankData.userName}
                onChange={(e) => handleInput(e, "number")}
              ></input>
            </div>
            {errors.userName && (
                <p className="error fs-12">{errors.userName}</p>
              )}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>
                Bank Name<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter Bank name."
                name="bankName"
                value={bankData.bankName}
                onChange={(e) => handleInput(e, "text")}
              ></input>
            </div>
            {errors.bankname && (
                <p className="error fs-12">{errors.bankname}</p>
              )}
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>
                Account No.<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter bank account number"
                name="accountNumber"
                value={bankData.accountNumber}
                onChange={(e) => handleInput(e, "number")}
                maxLength={16}
              ></input>
            </div>
            {errors.accountnumber && (
                <p className="error fs-12">{errors.accountnumber}</p>
              )}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>
                IFSC<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input
                type="text"
                placeholder="Please enter IFSC code"
                maxLength={11}
                name="ifscCode"
                value={bankData.ifscCode}
                onChange={(e) => handleInput(e, "textNum")}
              ></input>
            </div>
            {errors.ifsccode && (
                <p className="error fs-12">{errors.ifsccode}</p>
              )}
          </div>
        </div>

        {/* Extra Account Info */}
        {/* 
          <div className="role">
            <div className="retailer">
              <label className="label_head">Extra Account Info</label>
            </div>
          </div>
      
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>
                Acc Number-1<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input type="number"></input>
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>
                IFSC-1<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input type="text"></input>
            </div>
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>
                Acc Number-2<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input type="number"></input>
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>
                IFSC-2<span>*</span>
              </label>
            </div>
            <div className="labelname">
              <input type="text"></input>
            </div>
          </div>
        </div> */}

        {/* buttons */}

        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <button
                className="submit_btn"
                onClick={() => {
                  navigate(userBankDetail());
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <GloabalLoader />}
    </div>
  );
}
