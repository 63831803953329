import React from 'react'
import Input from "../../Components/Input/Input";
import { FilterWrapper } from '../../assets/styles/FilterStyle';


const EditUserForm = ({ handleInput, userData, errors, handleFormData}) => 
{
   return (
        <div className="main_container"
        style={{padding:"60px 40px 40px"}}>
            <FilterWrapper>
            <div >
                <label style={{
                    color: "rgb(240 241 243)",
                    fontSize: "25px",
                    fontWeight: "bold", fontFamily: "'Cabin', sans-serif"
                }}>Edit Details</label>
            </div>
            </FilterWrapper>
            <div className="container_form">
                <div className="role">
                    <div className="retailer">
                        <label className='label_head'>
                            Personal Details
                        </label>
                    </div>
                </div>
                <div className="role">
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                UserName
                            </label>
                            <span className="required">*</span>
                        </div>
                        <Input
                            type="text"
                            placeholder="enter you username"
                            name="userName"
                            value={userData.userName}
                        />
                    </div>

                </div>
                <div className="role">
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Firstname
                            </label>
                            <span className="required">*</span>
                        </div>
                        <div>
                            <Input className="inputtype"
                                type="text"
                                placeholder="enter you firstName"
                                name="firstName"
                                value={userData.firstName}
                            />
                        </div>
                    </div>
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Lastname
                            </label>
                            <span className="required">*</span>
                        </div>
                        <Input className="inputtype"
                            type="text"
                            placeholder="enter you lastName"
                            name="lastName"
                            value={userData.lastName}
                        />
                    </div>
                </div>
                <div className="role">
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                DOB
                            </label>
                            <span className="required">*</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                name="dob"
                                value={userData.dob}
                            />
                        </div>

                    </div>
                </div>
            
                <div className="role">
                    <div className="retailer">
                        <label className='label_head'>
                            Contact Details
                        </label>
                    </div>

                </div>

                <div className="role">

                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Contact Number
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you mobile number"
                                name="phoneNumber"
                                value={userData.userName}

                            />

                        </div>
                    </div>
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Email Address
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input
                            type="text"
                            placeholder="enter you email"
                            name="email"
                            value={userData.email}
                            onChange={handleInput}
                        />
                        {errors.email && (
                            <p className="error fs-12 mt-10">
                                {errors.email}
                            </p>
                        )}
                    </div>
                </div>
                {/* Address Details */}
                <div className="role">
                    <div className="retailer">
                        <label className='label_head'>
                            Address Details
                        </label>
                    </div>
                </div>
                <div className="role">
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Address1
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you address"
                                name="address1"
                                value={userData.address1}
                                onChange={handleInput}
                            />
                            {errors.address1 && (
                                <p className="error fs-12 mt-10">
                                    {errors.address1}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Address2
                            </label>

                        </div>
                        <Input
                            type="text"
                            placeholder="enter you address2"
                            name="address2"
                            value={userData.address2}
                            onChange={handleInput}
                        />
                    </div>
                </div>
                <div className="role">

                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Landmark
                            </label>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you landmark"
                                name="landmark"
                                value={userData.landmark}
                                onChange={handleInput}
                            />
                        </div>

                    </div>
                    <div className="retailer">
                        <div className="labelname">
                            <label>
                                Picode
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input
                            type="text"
                            placeholder="enter you pincode"
                            name="pincode"
                            value={userData.pincode}
                            onChange={handleInput}
                            maxLength={6}
                        />
                        {errors.pincode && (
                            <p className="error fs-12 mt-10">
                                {errors.pincode}
                            </p>
                        )}
                    </div>
                </div>
                <div className="role">
                    <div className="retailer">
                        <button
                            className="btn_submit"
                            style={{
                                color: "white",
                                backgroundColor: "#00286F",
                                height: "40px",
                                width: "250px",
                            }}
                            onClick={() => {
                                handleFormData()

                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditUserForm