import React from "react";
import LosBoxTop from "./LosBoxTop";
import carret from "../../assets/img/carret.svg";
import demo from "../../assets/img/demo.png";
import { Link } from "react-router-dom";

function Organization() {
  return (
    <div className="mainBoxWrapper">
      <LosBoxTop />
      <div className="fromBox">
        <div className="mainfrom flex">
          <div class="from-group">
            <div class="from-field">
              <label for="">
              Organization Name<span class="red">*</span>
              </label>
              <div class=" flex">
                <input
                  class=" "
                  placeholder="Enter Organization Name"
                  type="text"
                />
              </div>
            </div>
            <div class="msg"></div>
          </div>
          <div class="from-group">
            <div class="from-field">
              <label for="">
              Organization Type <span class="red">*</span>
              </label>
              <div class=" flex">
              <select name="" id="">
                  <option value="">Organization Type</option>
                  <option value="">Organization Type</option>
                  <option value="">Organization Type</option>
                </select>
                <img className="icon" src={carret} alt="icon" />
              </div>
            </div>
            <div class="msg"></div>
          </div>
          <div class="from-group">
            <div class="from-field">
              <label for="">
              Email ID<span class="red">*</span>
              </label>
              <div class=" flex">
                <input
                  placeholder="Enter e-mail"
                  type="text"
                />
              </div>
            </div>
            <div class="msg"></div>
          </div>
          <div class="from-group">
            <div class="from-field">
              <label for="">
              Phone No.<span class="red">*</span>
              </label>
              <div class=" flex">
                <input
                  class=" "
                  maxlength="10"
                  placeholder="Enter Mobile No."
                  type="text"
                />
              </div>
            </div>
            <div class="msg"></div>
          </div>
          <div class="from-group">
            <div class="from-field">
              <label for="">
              Address <span class="red">*</span>
              </label>
              <div class=" flex">
                <input
                
                  placeholder="Enter Address"
                  type="text"
                />
              </div>
            </div>
            <div class="msg"></div>
          </div>
          <div class="from-group">
            <div class="from-field">
              <label for="">
              GST No.<span class="red">*</span>
              </label>
              <div class=" flex">
                <input
                
                  placeholder="Enter  GST No.."
                  type="text"
                />
              </div>
            </div>
            <div class="msg"></div>
          </div>

          <div className="from-group">
          <div className="from-field">
            <label htmlFor="">
            Upload Logo<span className="red">*</span>
            </label>
            <div className="input-withButton flex">
              <input
                type="file"
                id="Salary"
                name="salary"
              
                style={{ display: "none" }}
              />
              <input className="fs-16" type="text"  />
              <label htmlFor="Salary" className="button-round center">
              UOLOAD
              </label>
            </div>
          </div>
          <div className="msg">
            
          </div>
        </div>
          <div className="from-group  demo">
            <img src={demo} alt="upload-logo" />
          <div className="msg">
            
          </div>
        </div>
       
        </div>
        <div className="submitButtom">
          <Link className="btn" to="/manage-team">
          NEXT
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Organization;
