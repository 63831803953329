import { FaUser, FaLock, FaMoneyBill } from "react-icons/fa";
import { BsGrid3X3Gap } from "react-icons/bs";
import { GoSettings } from "react-icons/go";
import { VscGraph } from "react-icons/vsc";
import { AiOutlineBell, AiOutlineQuestionCircle } from "react-icons/ai";
import { FaListOl } from "react-icons/fa";
export const sidemenuContast = [
  {
    path: "/",
    name: "Dashboard",
    icon: <BsGrid3X3Gap />,
    Permissions: "OVERVIEW",
  },
  {
    path: "/add-organization",
    name: "Add Organization",
    icon: <BsGrid3X3Gap />,
    Permissions: "OVERVIEW",
  },
//   {
//     path: "/users",
//     name: "Users",
//     icon: <FaListOl />,
//     exact: true,
//     id: "Users",
//     Permissions: "USERS",
//     subRoutes: [
//       {
//         path: "/usersList",
//         name: "Manage Users ",
//         icon: <FaUser />,
//         Permissions: "MANAGE_USERS",
//       },

//       // {
//       //     // path: "/addnewmember",
//       //     path:"/addUser",
//       //     name: "Add New Memeber ",
//       //     icon: <FaUser />,
//       //     Permissions:"ADD_MEMBER"
//       // },

//       // {
//       //     path: "/freememberidplan",
//       //     name: "Free Member Id Plan",
//       //     icon: <FaUser />,
//       //     Permissions:"FREE_MEMBER_PLAN"
//       // },
//       // {
//       //     path: "/merchantcommissionlist",
//       //     name: "Merchant Commission List",
//       //     icon: <FaUser />,
//       //     Permissions:"MERCHANT_COMMISSION"
//       // },

//       // {
//       //     path: "/permisionManagement",
//       //     name: "Role and Permission Management",
//       //     icon: <FaUser />,
//       //     Permissions:"MANAGE_USERS",
//       //     role:"ROLE_ADMIN"
//       // },

//       // {
//       //     path: "/ApiPermisionManagement",
//       //     name: "Api Permission Management",
//       //     icon: <FaUser />,
//       //     Permissions:"MANAGE_USERS",
//       //     role:"ROLE_ADMIN"

//       // },
//     ],
//   },
  {
    path: "/borrower",
    name: "Borrower",
    icon: <FaListOl />,
    exact: true,
    id: "Borrower",
    //Permissions:"USERS",
    subRoutes: [
      {
        path: "/borrowerList",
        name: "Manage Borrower ",
        icon: <FaUser />,
        Permissions: "MANAGE_USERS",
      },
    ],
  },
  // {
  //     path: "/PackageMnagementList",
  //     name: "Package Management",
  //     icon: <FaListOl />,
  //     subRoutes:[
  //         {
  //             path: "/PackageManagement",
  //             name:"Package Management List",
  //             icon: <FaUser />,
  //             Permissions:"MANAGE_USERS",
  //             role:"ROLE_ADMIN"
  //         },
  //         {
  //             path: "/CreatePackage",
  //             name:"Manage Packages",
  //             icon: <FaUser />,
  //             Permissions:"MANAGE_USERS",
  //             role:"ROLE_ADMIN"
  //         },

  //     ]

  // },
  // {
  //     path: "/mapping",
  //     name: "User Mapping",
  //     icon: <VscGraph />,
  //     exact: true,

  // },

  // {
  //     path: "/operators",
  //     name: "Operators List ",
  //     icon: <AiOutlineQuestionCircle />,
  // },
  // {
  //     path: "/transactions",
  //     name: "Transactions",
  //     icon: <AiOutlineQuestionCircle />,
  // },
  // {
  //     path: "/serviceList",
  //     name: "Services List",
  //     icon: <AiOutlineQuestionCircle />,
  // },

  // {
  //     path: "/wallet",
  //     name: "Wallet",
  //     icon: <GoSettings />,
  //     exact: true,
  //     id: "Wallet",
  //     Permissions:"WALLET",
  //     subRoutes: [
  //         {
  //             path: "/walletTransactions",
  //             name: "Wallet Summary ",
  //             icon: <FaUser />,
  //             Permissions:"WALLET_SUMMARY"
  //         },
  //         {
  //             path: "/DebitCreditList",
  //             name: "Manage Debit Credit",
  //             icon: <FaLock />,
  //             Permissions:"WALLET_SUMMARY_ACTION"
  //         },
  //         {
  //             path: "/addcredit",
  //             name: "Add Credit/Deduct Balance",
  //             icon: <FaLock />,
  //             Permissions:"CREDIT/DEDUCT"
  //         },
  //     ],
  // },
  {
    path: "/settings",
    name: "Settings",
    icon: <GoSettings />,
    exact: true,
    id: "Settings",
    subRoutes: [
      {
        path: "/changePassword",
        name: "Change Password ",
        icon: <FaUser />,
        Permissions: "CHANGE_PASSWORD",
      },

      // {
      //     path: "/holdbalance",
      //     name: "Hold Balance",
      //     icon: <FaLock />,
      //     Permissions:"HOLD_BALANCE"
      // },
      // {
      //     path: "/rechargecommission",
      //     name: "Recharge Commission",
      //     icon: <FaMoneyBill />,
      //     Permissions:"RECHARGE_COMMISSION"
      // },
      // {
      //     path: "/bbpsrechargecommision",
      //     name: "BBPS Recharge Commission",
      //     icon: <FaMoneyBill />,
      //     Permissions:"BBPS_RECHARGE_COMMISSION"
      // },
      // {
      //     path: "/billpaycommision",
      //     name: "Bill Pay Commission",
      //     icon: <FaMoneyBill />,
      //     Permissions:"BILL_PAY_COMMISSION"
      // },
      // {
      //     path: "/aepscommission",
      //     name: "AEPS Commission",
      //     icon: <FaMoneyBill />,
      //     Permissions:"AEPS_COMMISSION"
      // },
      // {
      //     path: "/adharpaycomission",
      //     name: "Adhar Pay Commission",
      //     icon: <FaMoneyBill />,
      //     Permissions:"ADHAR_PAY_COMMISSION"
      // },
      // {
      //     path: "/adharpaysetting",
      //     name: "Adhar Pay Setting",
      //     icon: <FaMoneyBill />,
      //     Permissions:"ADHAR_PAY_SETTING"
      // },
      // {
      //     path: "/aepscommission",
      //     name: "AEPS Commission",
      //     icon: <FaMoneyBill />,
      //     Permissions:""
      // },
      // {
      //     path: "/expresspayoutsurcharge",
      //     name: "Express Payout Surcharge",
      //     icon: <FaMoneyBill />,
      //     Permissions:"EXPRESS_PAYOUT_SURCHARGE"
      // },
      // {
      //     path: "/expresspayoutcomission",
      //     name: "Express Payout Commissione",
      //     icon: <FaMoneyBill />,
      //     Permissions:"EXPRESS_PAYOUT_COMMISSION"
      // },
      // {
      //     path: "/payoutsurcharge",
      //     name: "Payout Surcharge",
      //     icon: <FaMoneyBill />,
      //     Permissions:"PAYOUT_SURCHARGE"
      // },
      // {
      //     path: "/uttpansurcharge",
      //     name: "UTT Pan Surcharge",
      //     icon: <FaMoneyBill />,
      //     Permissions:"UTT_SURCHARGE"
      // },
      // {
      //     path: "/uttpancouponsurcharge",
      //     name: "UTT Pan coupon Surcharge",
      //     icon: <FaMoneyBill />,
      //     Permissions:"UTT_COUPON_SURCHARGE"
      // },
      // {
      //     path: "/upisurcharge",
      //     name: "UPI Surcharge",
      //     icon: <FaMoneyBill />,
      //     Permissions:"UPI_SURCHARGE"
      // },
      // {
      //     path: "/upicomission",
      //     name: "UPI Comission",
      //     icon: <FaMoneyBill />,
      //     Permissions:"UPI_COMMISSION"
      // },
      // {
      //     path: "/slider",
      //     name: "Slider",
      //     icon: <FaMoneyBill />,
      //     Permissions:"SLIDER"
      // },
      // {
      //     path: "/news",
      //     name: "News",
      //     icon: <FaMoneyBill />,
      //     Permissions:"NEWS"
      // },

      // {
      //     path: "/rechargeplan",
      //     name: "Recharge Plan",
      //     icon: <FaMoneyBill />,
      //     Permissions:"RECHARGE_PLAN"

      // },
      // {
      //     path: "/ministatementcomission",
      //     name: "Mini Statement Comission",
      //     icon: <FaMoneyBill />,
      //     Permissions:"MINI_STATEMENT_COMMISSION"
      // },
      // {
      //     path: "/memberidplan",
      //     name: "Member Id Plan",
      //     icon: <FaMoneyBill />,
      //     Permissions:"MEMBER_ID_PLAN"
      // },
      // {
      //     path: "/movetomember",
      //     name: "Move To Member",
      //     icon: <FaMoneyBill />,
      //     Permissions:"MOVE_TO_MEMBER"
      // },
      // {
      //     path: "/statemaster",
      //     name: "State Master",
      //     icon: <FaMoneyBill />,
      //     Permissions:"STATE_MASTER"
      // },
      // {
      //     path: "/citymaster",
      //     name: "City Master",
      //     icon: <FaMoneyBill />,
      //     Permissions:"CITY_MASTER"
      // },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    icon: <GoSettings />,
    exact: true,
    id: "Reports",
    subRoutes: [
      // {
      //     path: "/exportreports",
      //     name: "Export Reports",
      //     icon: <FaUser />,
      //     Permissions:"EXP_REPORTS"
      // },

      {
        path: "/exportreports",
        name: "Export Reports",
        icon: <FaUser />,
        Permissions: "EXP_REPORTS",
      },

      // {
      //     path: "/aepshistory",
      //     name: "AEPS History",
      //     icon: <FaLock />,
      //     Permissions:"AEPS_HISTORY"
      // },

      // {
      //     path: "/adharpayhistory",
      //     name: "Adhar Pay History",
      //     icon: <FaMoneyBill />,
      //     Permissions:"ADHAR_HISTORY"
      // },
      // {
      //     path: "/recharge",
      //     name: "Recharge",
      //     icon: <FaMoneyBill />,
      //     Permissions:"RECHRGE"
      // },
      // {
      //     path: "/bbps",
      //     name: "BBPS",
      //     icon: <FaMoneyBill />,
      //     Permissions:"BBPS"
      // },
      // {
      //     path: "/bills",
      //     name: "Bills",
      //     icon: <FaMoneyBill />,
      //     permission:"BILLS"
      // },
      // {
      //     path: "/adminbalance",
      //     name: "Admin + Balance",
      //     icon: <FaMoneyBill />,
      //     permissions:"ADMIN_BAL"
      // },
      // {
      //     path: "/expresspayout",
      //     name: "Express Payout",
      //     icon: <FaMoneyBill />,
      //     permissions:"EXPRESS_PAYOUT"
      // },
      // {
      //     path: "/payout",
      //     name: "Payout",
      //     icon: <FaMoneyBill />,
      //     permissions:"PAYOUT"
      // },
      // {
      //     path: "/utiregistrationpanhistory",
      //     name: "UTI Registration Pan History",
      //     icon: <FaMoneyBill />,
      //     permissions:"UTI_REGISTRATION_HISTORY"
      // },
      // {
      //     path: "/utipancouponhistory",
      //     name: "UTI Pan Coupon History",
      //     icon: <FaMoneyBill />,
      //     permissions:"MEMBER_REPORT"
      // },
      // {
      //     path: "/virtualaccountreport",
      //     name: "Virtual Account Report",
      //     icon: <FaMoneyBill />,
      //     permissions:"VIRTUAL_REPORT"
      // },
      // {
      //     path: "/upicollectionreport",
      //     name: "UPI Collection Report",
      //     icon: <FaMoneyBill />,
      //     permissions:"UPI_COLLECTION_REPORT"
      // },
      // {
      //     path: "/memberidreport",
      //     name: "Member Id Report",
      //     icon: <FaMoneyBill />,
      //     permissions:"MEMBER_REPORT"
      // },
      // {
      //     path: "/tdsreport",
      //     name: "TDS Report",
      //     icon: <FaMoneyBill />,
      //     permissions:"TDS_REPORT"

      // },
    ],
  },

  {
    path: "/helpandsupport",
    name: "Help & Support",
    icon: <AiOutlineQuestionCircle />,
  },

  // {
  //     path: "/logout",
  //     name: "Logout",
  //     icon: <FiLogOut />,
  // },
];