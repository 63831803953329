import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../Components/Input/Input";
import Swal from "sweetalert2";
import axios from "axios";


const ChangePassword = () => {

    const initialValues = {
        currentPassword: "", newPassword: "",
        confirmPassword: "", otp: "",
    };

    const INIT_ERRORS = {
        currentpassword: "", newpassword: "",
        confirmpassword: "", otp: "",
    };

    const [changePasswordData, setChangePasswordData] = useState(initialValues);
    const [errors, setErrors] = useState(INIT_ERRORS);
    const [passwordType ,setpasswordType] = useState("password")

    const navigate = useNavigate()

    const passwordChange = async (e) => {

        if (changePasswordData.currentPassword === "") {
            setErrors((prev) => ({
                ...prev,
                currentpassword: "please enter current password",
            }));
        }
        if (changePasswordData.newPassword === "") {
            setErrors((prev) => ({
                ...prev,
                newpassword: "please enter your new password",
            }));
        }
        if (changePasswordData.currentPassword === "") {
            setErrors((prev) => ({
                ...prev,
                currentpassword: "please enter your confirm password",
            }));
        }
        if (changePasswordData.otp === "") {
            setErrors((prev) => ({
                ...prev,
                otp: "please enter your otp",
            }));
        }

        else {
            let body = changePasswordData;

            let user = JSON.parse(sessionStorage.getItem("token"));

            let response = await axios({
                method: "POST",
                url: `user/account/change-password`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + user.id_token,
                },
                data: body,
            });


            if (response.data.success === true) {
                Swal.fire({
                    icon: "success",
                    title: "Password changed Successfully",
                    timer: 1200,
                    showConfirmButton: false,
                });
                navigate('/')
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.data.msg,
                    confirmButtonColor: '#ED1C24',
                })
            }
        }
    };

    let name, value;
    const handleInput = (e) => {
        name = e.target.name;
        value = e.target.value;

        setChangePasswordData({ ...changePasswordData, [name]: value });
    };
    const checkEquality = () => {
        if (changePasswordData.newPassword !== changePasswordData.confirmPassword) {
            setErrors("new password and confirm password should be same.")
        }
    }

    const handleTogglePassword =()=>{
        if(passwordType==="password"){
            setpasswordType("text")
            return
        }
        setpasswordType("password")

    }
    return (

        <div className="main_container">

            <div className="container_form d-flex justify-center">

                <div className="change_password gap-20">

                    <div className="role "
                        style={{width:"100% !important",marginTop: "0px !important" ,justifyContent:"center",alignItems:"center" }}>
                        <div className="retailer ">
                            <button
                            style={{width:"402px"
                            ,height:"40px",
                            marginTop:"-12px",
                            border:"none",
                            borderRadius:"5px 5px 0px 0px",
                            backgroundColor:"#00286F",
                            color:"white"}} className="justify-center fw-bold fs-20">
                                Change Password
                            </button>
                        </div>
                    </div>
                    <div className="role">
                        <div className="retailer">
                            <div className="labelname">
                                <label>
                                    Current Password
                                </label>
                                <span className="required"> *</span>
                            </div>
                            <div className="">
                                <Input
                                    className="input-width"
                                    type="text"
                                    placeholder="enter you current password"
                                    name="currentPassword"
                                    value={changePasswordData.currentPassword}
                                    onChange={handleInput}
                                />
                                 

                                {errors.currentpassword && (
                                    <p className="error fs-12 mt-10">
                                        {errors.currentpassword}
                                    </p>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="role">
                        <div className="retailer">
                            <div className="labelname">
                                <label>
                                    New Password
                                </label>
                                <span className="required"> *</span>
                            </div>
                            <div style={{position:"relative"}}>
                            <Input
                             className="inputtype"
                                type={passwordType}
                                placeholder="enter you new password"
                                name="newPassword"
                                value={changePasswordData.newPassword}
                                onChange={handleInput}
                                
                            />
                            
                            <button className="eye_icon"  onClick={handleTogglePassword}>
                            { passwordType==="password" ?<i class="fa fa-eye-slash" aria-hidden="true"></i>:<i class="fa fa-eye" aria-hidden="true"></i>
                           }
                            </button>
                           
                            {errors.newpassword && (
                                <p className="error fs-12 mt-10">
                                    {errors.newpassword}
                                </p>
                            )}
                            </div>
                         
                        </div>
                    </div>
                    <div className="role">
                        <div className="retailer">
                            <div className="labelname">
                                <label>
                                    Confirm Password
                                </label>
                                <span className="required"> *</span>
                            </div>

                            <Input

                                type="text"
                                placeholder="enter you confirm password"
                                name="confirmPassword"
                                value={changePasswordData.confirmPassword}
                                onChange={handleInput}
                            />
                            {errors.confirmpassword && (
                                <p className="error fs-12 mt-10">
                                    {errors.confirmpassword}
                                </p>
                            )}

                        </div>
                    </div>

                    <div className="role">
                        <div className="retailer flex">
                            <button
                                className="btn_submit"
                                style={{
                                    color: "white",
                                    backgroundColor: "#00BAF2",
                                    height: "40px",
                                    width: "230px",
                                    border: "none",
                                    marginBottom:"20px"

                                }}
                                onClick={() => {
                                    checkEquality()
                                    passwordChange()
                                }}
                            >
                                Change Password
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};
export default ChangePassword