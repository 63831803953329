import React, { useState } from 'react';

import "./style.scss";
import GloabalLoader from '../../Components/Loader/GloabalLoader';
import { userPackageMapping } from '../../utils/api';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

function SelectPackage() {

    const [pkgCod, setPkgCod] = useState("DEFAULT");
    const location = useLocation();
    const navigate = useNavigate();
  
    const responsedata = location.state;
    const SubmitHandaler = () => {
        userPackageMapping(responsedata?.uuid,pkgCod).then((resp) =>{
           
            if(resp?.data?.success){
                Swal.fire({
                    icon: "success",
                    text: resp?.data?.msg,
                    confirmButtonColor: "#34ed1c",
                  });
                  navigate("/Verification", { state: responsedata });

            }else{
                let msg = "";
           if (resp?.data?.errorCodeList.length > 0) {
             msg = resp?.data?.errorCodeList[0];
           } else {
             msg = resp?.data?.msg;
           }
           Swal.fire({
             icon: "error",
             text: msg,
             confirmButtonColor: "#ED1C24",
             customClass: "swal-wide",
           });
            }
           
        })
    }

    const packageHandler =(packageCode)=>{
        if(packageCode === pkgCod){
            setPkgCod("");
        }else{
            setPkgCod(packageCode)
        }
    }
  return (
    <div className="main_container">
      <div className="container1">
        <label>Create New Retailer</label>
      </div>
      <div className="data_details">
        <div className="container_progress1">
          <div className="round round-adduser">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Retailer Detail</div>
          </div>
          <div className="round round-addBank">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable ">Bank Detail</div>
          </div>
          <div className="round round-addBank">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" style={{backgroundColor:"#0000ff"}} className="active" ></label>
            </div>
            <div className="bar bar-3" />
            <div className="lable ">Select package</div>
           
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" ></label>
            </div>
            <div className="bar bar-4" />
            <div className="lable lable-last">ID Verification</div>
          </div>
        </div>
        <div className="packege-wrapper">
            <h2>Select Package</h2>
            <div className="container package-box-wrapper active">
            <div className="package_box gap-10">
                <div className="box" onClick={() => packageHandler("DEFAULT")}>
                <div className='left' >
                       <div className='checkbox'>
                        <input type="checkbox" name=""  checked={pkgCod==="DEFAULT"?true:false} />
                       </div>
                       <div>
                       <h4>LIGHT</h4>
                        <p className='small'>The basics of starting a business</p>
                       </div>
                    </div>
                </div>
                <div className="box" onClick={() => packageHandler("STARTER")}>
                <div className='left'>
                       <div className='checkbox' >
                        <input type="checkbox" name="" id="STARTER" checked={pkgCod==="STARTER"?true:false} />
                       </div>
                       <div>
                       <h4>STARTER</h4>
                        <p className='small'>It's time to scale sales</p>
                       </div>
                    </div>
                </div>
                <div className="box" onClick={() => packageHandler("PREMIMUM")}>
                <div className='left' >
                       <div className='checkbox'>
                        <input type="checkbox" name=""  checked={pkgCod==="PREMIMUM"?true:false} />
                       </div>
                       <div>
                       <h4>PREMIUM</h4>
                        <p className='small'>Everything you need for business</p>
                       </div>
                    </div>
                </div>
            </div>
        
              {/* <div className="package_box">
                    <div className='left'>
                       <div className='checkbox'>
                        <input type="checkbox" name=""  checked={pkgCod==="LIGHT"?true:false} />
                       </div>
                       <div>
                       <h4>LIGHT</h4>
                        <p className='small'>The basics of starting a business</p>
                       </div>
                    </div>
                    <div className='right'>
                        <h4>₹500/ <span className='small'>mo</span></h4>
                        <label  className={pkgCod==="LIGHT"?"button active":"button"} onClick={() => packageHandler("LIGHT")} >Choose Light</label>
                        <div className='dropdown-icon'>
                        <i class="fa-solid fa-caret-down"></i>
                        </div>
                    </div>
                    
                </div> */}
                {/* <div className="package-box-dropdown">
    <div className="payment">
        <h5>Payments</h5>
    </div>            
    <div className="Recharge">
        <h5>Recharge & Bill</h5>
        </div>            
    <div className="Financial">
        <h5>Financial Services</h5>
        </div>            
                </div> */}
             

           </div>
          
            {/* <div className="container package-box-wrapper active">
        
              <div className="package_box">
                    <div className='left'>
                       <div className='checkbox'>
                        <input type="checkbox" name="" id="STARTER" checked={pkgCod==="STARTER"?true:false} />
                       </div>
                       <div>
                       <h4>STARTER</h4>
                        <p className='small'>It's time to scale sales</p>
                       </div>
                    </div>
                    <div className='right'>
                        <h4>₹1800/ <span className='small'>mo</span></h4>
                        <label  className={pkgCod==="STARTER"?"button active":"button"}onClick={() => packageHandler("STARTER")} >Choose Starter</label>
                        <div className='dropdown-icon'>
                        <i class="fa-solid fa-caret-down"></i>
                        </div>
                    </div>
                    
                </div>
               
             

           </div>
            <div className="container package-box-wrapper active">
        
              <div className="package_box">
                    <div className='left'>
                       <div className='checkbox'>
                        <input type="checkbox" name=""  checked={pkgCod==="PREMIUM"?true:false} />
                       </div>
                       <div>
                       <h4>PREMIUM</h4>
                        <p className='small'>Everything you need for business</p>
                       </div>
                    </div>
                    <div className='right'>
                        <h4>₹2200/ <span className='small'>mo</span></h4>
                     <label  className={pkgCod==="PREMIUM"?"button active":"button"} onClick={() => packageHandler("PREMIUM")}>  Choose Premium</label>
                        <div className='dropdown-icon'>
                        <i class="fa-solid fa-caret-down"></i>
                        </div>
                    </div>
                    
                </div>
                
             

           </div> */}

           <div>
            <button className='submit' onClick={SubmitHandaler}>Submit</button>
           </div>

          
        </div>
        

      </div>
      {false && <GloabalLoader />}
    </div>
  );
}

export default SelectPackage;






