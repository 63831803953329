
import React, { useState } from "react";
import Swal from "sweetalert2";
import Input from "../../Components/Input/Input";
import { useNavigate } from "react-router-dom";
import { addUser, searchUser } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import { getDateFromDateObj, isAlphabet, isEmpty, isNumber } from "../../utils/common";
import moment from 'moment';
import DatePicker from "react-datepicker";
require('react-datepicker/dist/react-datepicker.css')

const AddUserForm = () => {
  const initialValues = {
    role: "Retailer",
    authorities: ["ROLE_RETAILER"],
    tenantId: 0,
    qrCodeId: "",
    address1: "",
    address2: "",
    dob: "",
    phoneNumber: "",
    email: "",
    firstName: "",
    landmark: "",
    langKey: "system",
    lastName: "",
    password: "",
    pincode: "",
    userName: "",
    screenCode: null,
  };

  const INIT_ERRORS = {
    role: "",
    address1: "",
    address2: "",
    dob: "",
    phoneNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    pincode: "",
    userName: "",
  };

  const [userData, setUserData] = useState({ ...initialValues });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null)
  const navigate = useNavigate();


//DatePicker
  var maxBirthdayDate = new Date();
  maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleFormData = async (e) => {
    userData.dob = getDateFromDateObj(date, "DD/MM/YYYY")
    // console.log("namxn",userData.dob)
      let error = {};

    if(userData.userName === "") {
      error.userName = "Please enter your username";
    } else if (userData.userName.length < 10) {
      error.userName = "Username can't be less than 10 digits";
    }else if (/\s/.test(userData.userName)) {
      error.userName = "Please remove white space"
    }

    if(userData.firstName === "") {
      error.firstName = "Please enter your first name";
    }else if (/\s/.test(userData.firstName)) {
      error.firstName = "Please remove white space"
    }

    if (userData.lastName === "") {
      error.lastName = "Please enter your last name";
    }else if (/\s/.test(userData.lastName)) {
      error.lastName = "Please remove white space"
    }

    if (userData.dob === "Invalid date") { 
      error.dob = "Please enter your date of birth";
    //  } else if(userData.dob === "Invalid date"){
    //   error.dob = "Please enter valid date";
     }else{
       const date1 = new Date();
       const date2 = new Date(date);
        const timeDifferenceMs = date1 - date2;
         const daysDifference = timeDifferenceMs / (365.25 * 24 * 60 * 60 * 1000);
        if(daysDifference < 18){  
          error.dob = "User's age can't be less than 18.";      
        }else if(daysDifference > 60){
          error.dob = "User's age can't be greater than 60."; 
        }
    }
    if (userData.password === "") {
      error.password="Please enter your password";
    }else if (/\s/.test(userData.password)) {
      error.password = "Please remove white space"
    }else if(userData.password.length < 8){
      error.password="Password should not be less than 8";
    }
    if (userData.phoneNumber === "") {
      error.phoneNumber = "Please enter your phone number";
    }else if (userData.phoneNumber.length < 10) {
      error.phoneNumber = "Phone number can't be less than 10 digits";
    }else if (/\s/.test(userData.phoneNumber)) {
      error.phoneNumber = "Please remove white space"
    }
    if (userData.email === "") {
      error.email = "Please enter your email";
    } else if (!isValidEmail(userData.email)) {
      error.email = "Please enter valid email";
    }else if (/\s/.test(userData.email)) {
      error.email = "Please remove white space"
    }

    if (userData.address1 === "") {
      error.address1="Please enter your houseno/building name.";
    }  else if (userData.address1.length < 5) {
      error.address1="Houseno/Buildingname can't be less than 5 letters";
    }

    if (userData.pincode === "") {
      error.pincode = "Please enter your pincode";
    }else if (userData.pincode.length < 6) {
      error.pincode = "Pincode can't be less than 6 digits";
    }else if (/\s/.test(userData.pincode)) {
      error.pincode = "Please remove white space"
    }

    setErrors(error);
     
    if(isEmpty(error))
     {
       setLoading(true);
        addUser(userData).then((resp) => { 
         setLoading(false);
 
         if (resp.data.success === true) {
           Swal.fire({
             icon: "success",
             title: "User basic details is done",
             timer: 2200,
             showConfirmButton: "#00BAF2",
             customClass: "swal-wide",
           });
           navigate("/bankdetails", { state: resp?.data?.data });
         } else {
           let msg = "";
           if (resp?.data?.errorCodeList.length > 0) {
             msg = resp?.data?.errorCodeList[0];
           } else {
             msg = resp?.data?.msg;
           }
           Swal.fire({
             icon: "error",
             text: msg,
             confirmButtonColor: "#ED1C24",
             customClass: "swal-wide",
           });
         }
       });
     }    
     
  };
  let name, value;
  const handleInput = (e, key = "") => {

    name = e.target.name;
    value = e.target.value;
    setErrors({});

    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    }
    setUserData({ ...userData, [name]: value });
  }
  // const handleDateChange = (e) => {

  //   setDate(e.target.value);
  //   setUserData({
  //     ...userData,
  //     date: getDateFromDateObj(date, "DD/MM/YYYY"),
  //   })
  // }
  // const disableFutureDate = () => {
  //   const currentDate = new Date();
  //   currentDate.setFullYear(currentDate.getFullYear() - 18);
  //   const minDate = currentDate.toISOString().slice(0, 10);
  //   return minDate;
  // };

  const checkStatus = () => {
    if (isEmpty(userData.userName)) {
      setErrors((prev) => ({
        ...prev,
        userName: "Please enter your username",
      }));
      return
    }
    else if ((userData?.userName).length < 10) {
      setErrors((prev) => ({
        ...prev,
        userName: "Username can't be less than 10 digit",
      }));

      return
    } else {
      searchUser(userData.userName).then((resp) => {
        if (resp?.data?.success) {

          if (resp?.data?.data?.screenCode === "AEPS_ONBOARDING") {

            Swal.fire({
              icon: "success",
              title: "This user is already onboard",
              timer: 2200,
              showConfirmButton: "#00BAF2",
              customClass: "swal-wide",
            });
          } else if (resp?.data?.data?.screenCode === "USER_REGISTRATION") {
            console.log("USER_REGISTRATION")
            Swal.fire({
              icon: "success",
              title: "This user is already Registered you can complete kyc",
              showConfirmButton: "#00BAF2",
              showCancelButton: true,
              confirmButtonText: 'Yes, I am sure!',
              cancelButtonText: "No, cancel it!",
              customClass: "swal-wide",
            }).then((result) => {
              if(result.isConfirmed){
              const userData = {
                email: resp?.data?.data?.email,
                userName: resp?.data?.data?.userName,
                uuid: resp?.data?.data?.uuid
              }
              navigate("/bankdetails", { state: userData });
            }else{
              navigate("/userslist")
            }
            });
          } else if (resp?.data?.data?.screenCode === "USER_KYC") {
            Swal.fire({
              icon: "success",
              title: "This user kyc is already done you can select package",
              showConfirmButton: "#00BAF2",
              showCancelButton: true,
              confirmButtonText: 'Yes, I am sure!',
              cancelButtonText: "No, cancel it!",
              customClass: "swal-wide",
            }).then((result) => {
              if(result.isConfirmed){
              const userData = {
                email: resp?.data?.data?.email,
                userName: resp?.data?.data?.userName,
                uuid: resp?.data?.data?.uuid
              }
              navigate("/select-package", { state: userData });
            }else{
              navigate("/userslist")
            }
              
            })
          } else if (resp?.data?.data?.screenCode === "PACKAGE_ASSIGNED") {
            Swal.fire({
              icon: "success",
              title: "This user kyc and Select Package are already done you can otp verification for user onboarding",
              showConfirmButton: "#00BAF2",
              showCancelButton: true,
              confirmButtonText: 'Yes, I am sure!',
              cancelButtonText: "No, cancel it!",
              customClass: "swal-wide",
            }).then((result) => {
              if(result.isConfirmed){
              const userData = {
                email: resp?.data?.data?.email,
                userName: resp?.data?.data?.userName,
                uuid: resp?.data?.data?.uuid
              }
              navigate("/Verification", { state: userData });
            }else{
              navigate("/userslist")
            }
            })
          }


        } else {
          Swal.fire({
            icon: "success",
            title: "User not found you can register this user",
            timer: 2200,
            showConfirmButton: "#00BAF2",
            customClass: "swal-wide",
          });
        }
      })
    }

  }

 

  return (
    <div className="main_container">
      <div className="container1">
        <label>Create New Retailer</label>
      </div>
      <div className="container_form">
        <div className="container_progress1">
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" className="active"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Retailer Detail</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable">Bank Detail</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-3" />
            <div className="lable">ID Verification</div>
          </div>{" "}
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-4" />
            <div className="lable lable-last">Otp Verification</div>
          </div>
        </div>

        <div className="role">
          <div className="retailer">
            <label className="label_head">Personal Details</label>
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>Username</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              maxLength={10}
              placeholder="Enter your registered mobile number"
              name="userName"
              value={userData?.userName}
              onChange={(e) => handleInput(e, "number")}
            />
            {errors.userName && (
              <p className="error fs-12 mt-10">{errors.userName}</p>
            )}
          </div>
          <div className="retailer">
            <button className="check-status" onClick={checkStatus}>Check Status</button>
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>First Name</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                className="inputtype"
                type="text"
                placeholder="Enter your first name"
                name="firstName"
                value={userData.firstName}
                onChange={(e) => handleInput(e, "text")}
              />
              {errors.firstName && (
                <p className="error fs-12 mt-10">{errors.firstName}</p>
              )}
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Last Name</label>
              <span className="required"> *</span>
            </div>
            <Input
              className="inputtype"
              type="text"
              placeholder="Enter your last name"
              name="lastName"
              value={userData.lastName}
              onChange={(e) => handleInput(e, "text")}
            />
            {errors.lastName && (
              <p className="error fs-12 mt-10">{errors.lastName}</p>
            )}
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>DOB</label>
              <span className="required"> *</span>
            </div>

           
            <DatePicker
              showIcon
              className="flex justify-center colr"
              placeholderText="Enter your DOB"
              selected={date}
              valueDefault={null}
              value={date}
              maxDate={maxBirthdayDate}
              yearDropdownItemNumber={100}
              scrollableYearDropdown={true}
              // yearDropdownMin={new Date()}
              showYearDropdown
              onKeyDown={(e) => e.preventDefault()}
              onChange={(date) => {
                setDate(userData.dob = date)

              }}
            />
            
            {errors.dob && <p className="error fs-12 mt-10">{errors.dob}</p>}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Password</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="Enter your password"
              name="password"
              value={userData.password}
              onChange={(e) => handleInput(e, "password")}
              autoComplete="new-password"
              maxLength={30}
            />

            {errors.password && (
              <p className="error fs-12 mt-10">{errors.password}</p>
            )}
          </div>
        </div>

        {/* Contact Infomation */}
        <div className="role">
          <div className="retailer">
            <label className="label_head">Contact Details</label>
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>Contact Number</label>
              <span className="required"> *</span>
            </div>

            <Input
              type="text"
              maxLength={10}
              placeholder="Enter mobile number"
              name="phoneNumber"
              value={userData.phoneNumber}
              onChange={(e) => handleInput(e, "number")}
            />
            {errors.phoneNumber && (
              <p className="error fs-12 mt-10">{errors.phoneNumber}</p>
            )}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Email Address</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="Enter email address"
              name="email"
              value={userData.email}
              onChange={(e) => handleInput(e, "email")}
            />
            {errors.email && (
              <p className="error fs-12 mt-10">{errors.email}</p>
            )}
          </div>
        </div>
        {/* Address Details */}
        <div className="role">
          <div className="retailer">
            <label className="label_head">Address Details</label>
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>Building/House No. </label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="Enter building/house no."
              name="address1"
              value={userData.address1}
              onChange={(e) => handleInput(e, "address")}
            />
            {errors.address1 && (
              <p className="error fs-12 mt-10">{errors.address1}</p>
            )}
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Road Name/Area/Colony</label>
              <span className="required"> </span>
            </div>
            <Input
              type="text"
              placeholder="Enter road name/area/colony"
              name="address2"
              value={userData.address2}
              onChange={(e) => handleInput(e, "address")}
            />
            {errors.address1 && (
              <p className="error fs-12 mt-10">{errors.address2}</p>
            )}
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <div className="labelname">
              <label>Landmark</label>
            </div>
            <div>
              <Input
                type="text"
                placeholder="Enter landmark"
                name="landmark"
                value={userData.landmark}
                onChange={(e) => handleInput(e, "address")}
              />
            </div>
          </div>
          <div className="retailer">
            <div className="labelname">
              <label>Pincode</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              placeholder="Enter pincode"
              name="pincode"
              maxLength={6}
              value={userData.pincode}
              onChange={(e) => handleInput(e, "number")}
            />
            {errors.pincode && (
              <p className="error fs-12 mt-10">{errors.pincode}</p>
            )}
          </div>
        </div>
        <div className="role">
          <div className="retailer">
            <button
              className="btn_submit"
              style={{
                color: "white",
                backgroundColor: "#00BAF2",
                height: "40px",
                width: "330px",
                border: "none",
              }}
              onClick={() => {
                handleFormData();

              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <>{loading && <GloabalLoader />}</>
    </div>
  );
};
export default AddUserForm;
