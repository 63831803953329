import React from 'react';

function LosBoxTop({step ="1"}) {
  return (
    <>

<h1>Complete your Organization Setup</h1>
        <p className='text-center'>Your account is active.</p>
        <div className="progressStape mx-auto center">
        <div className="step center">
            <div class="customCheckbox">
                <input class="styled-checkbox" id="" type="checkbox" checked={step > 1} />
                <label for=""></label>
            </div>
            <p>Add Organization</p>

            </div>
        <div className="step center">
            <div class="customCheckbox">
                <input class="styled-checkbox" id="" type="checkbox" checked={step > 2} />
                <label for=""></label>
            </div>
            <p>Manage Team</p>

            </div>
        <div className="step center">
            <div class="customCheckbox">
                <input class="styled-checkbox" id="" type="checkbox" checked={step > 3} />
                <label for=""></label>
            </div>
            <p>Documents Upload</p>

            </div>
        <div className="step center">
            <div class="customCheckbox">
                <input class="styled-checkbox" id="" type="checkbox" checked={step > 4} />
                <label for=""></label>
            </div>
            <p>API key Configuration</p>

            </div>
        </div>
      
      
    </>
  );
}

export default LosBoxTop;
