import React, { useEffect, useRef, useState } from "react";
import "./styles/addCredit.scss";
import "../TicketManagement/style.css";
import "../NewMember/RoleManagmentAndPermission/style.scss";
import {
  createDebitCredit,
  debitCreditList,
  getDebitCreditApprove,
  searchUser,
} from "../../utils/api";
import Pagination from "../../Components/Pagination/Pagination";
import Swal from "sweetalert2";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import { AlertWrapper } from "../../assets/styles/AlerWrapper";
import DatePicker from "react-datepicker";
require('react-datepicker/dist/react-datepicker.css')

const DebitCreditList = () => {
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const initialValues = {
    userName: "",
    amount: "",
    remark: "",
    test: "",
  };
  const [debitCredit, setDebitCredit] = useState({ ...initialValues });
  const [listDebitCredit, setlistDebitCredit] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [errors, setErrors] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectStatus, setSelectStatus] = useState(null);
  const [transactionMode, setTransactionMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalElements, setTotalElement] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const [txnType, setTxnType] = useState(null);
  const [fromDate, setStartDate] = useState("");
  const [toDate, setEndDate] = useState("");
  const [downloadLoader, setDownloadLoader] = useState('');

  const navigate = useNavigate();
  const link = useRef();
  const location = useLocation();
  const userData = location.state;
  const [msg, setMsg] = useState(userData)


  const handleDebitCreditList = (e, key = "") => {
    setLoading(true);

    const param = {
      serviceType: txnType,
      fromDate: fromDate,
      toDate: toDate,
      //  txnId: "string",
      status: selectStatus,
      pagination:
      {
        pageNo: currPage,
        pageSize: pageSize
      }


    }

    if (key == "CSV" || key == "PDF") {
      if ((key === "CSV")) {
        setDownloadLoader("CSV")
      } else {
        setDownloadLoader("PDF")
      }
      param.pagination.pageSize = totalElements;
    }
    if (key === "search") {
      param.pagination.pageNo = 0;
    } else {
      setLoading(true)
    }
    if (key === "refresh") {
      dataRefresh()
      param.fromDate = "";
      param.serviceName = "";
      param.toDate = "";
    }

    debitCreditList(param).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      if (resp?.data?.success) {
        if (key == "CSV") {
          setPdfData(resp?.data?.data?.content);

          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          debitCreditPdfData(resp?.data?.data?.content);
        } else {
          setlistDebitCredit(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElement(resp?.data?.data?.totalElements);
        }
      } else {
        setlistDebitCredit([]);
      }
    });
  };

  const debitCreditPdfData = (data = []) => {
    const doc = new jsPDF();
    //Add image in PDF header
    const imgWidth = 25; // Adjust the width of the logo
    const imgHeight = 9; // Adjust the height of the logo
    const leftXPos = 14; // Adjust the X position
    const yPos = 2; // Adjust the Y position
    doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

    //Add fileName in the center
    const text = "Transaction List";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);

    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    // Calculate the X position for right alignment
    const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
    doc.text(formattedDate, rightXPos, yPos1);

    // Create a table from API data
    const tableData = [];
    //
    data.forEach((item, index) => {
      const rowData = [
        index + 1,
        item.userId,
        item.transactionType,
        item.reason,
        item.amount,
        item.status,
      ]; // Customize as needed
      tableData.push(rowData);
    });

    doc.autoTable({
      head: [["S.No", "Merchant", "Type", "Reason", "Amount", "Status"]], // Customize table header
      body: tableData,
    });

    doc.save("DebitCreditList.pdf");
  };

  useEffect(() => {
    handleDebitCreditList();
  }, [selectStatus, currPage]);

  const handleApprove = (id, status) => {

    let msg = ""
    if (status == "APPROVED") {
      msg = "Are You Sure you Want Approve"
    }
    else {
      msg = "<lable>Reason For Reject:</lable><input type ='text' placeholder ='Reject Reason'/>"
    }

    Swal.fire({
      icon: "success",
      title: msg,
      //   timer: 2200,
      //  showConfirmButton: "#3bee4a",
      showCancelButton: true,
      customClass: "swal-wide",
      confirmButtonColor: "#00BAF2",
      preConfirm: async () => {
        return getDebitCreditApprove(id, status).then((resp) => {
          if (resp?.data?.success && status == "APPROVED") {
            handleDebitCreditList(null);
            Swal.fire({
              timer: 2000,
              icon: "success",
              title: "Approved Successfully",
            });
          }
          else if (resp?.data?.success && status == "REJECTED") {
            handleDebitCreditList(null);
            Swal.fire({
              timer: 2000,
              icon: "success",
              title: "Reject Successfully",
            });

          }
          else {
            let errormsg = "";
            if(resp?.data?.errorCodeList?.length > 0 ){
              errormsg = resp?.data?.errorCodeList[0];
              
            }
            // else{
            //   msg = resp?.data?.msg;
            // }
            Swal.fire({
              timer: 2000,
              icon: "success",
              title: errormsg,
              color: "#ED1C24",
            });
          }
        });
      },
    })




  };

  const handleStatus = (e) => {
    setSelectStatus(e.target.value);
    // handleDebitCreditList(e.target.value)
  };
  const handleTxnType = (e) => {
    setTxnType(e.target.value)

  }
  const disableFutureDate = () => {
    let today, newdate;
    today = new Date();
    newdate = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("-");

    return newdate;
  };
  useEffect(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, 5000)

    }

  }, [msg])


  const dataRefresh = () => {
    setStartDate("")
    setEndDate("")

  }

  return (
    <>
      <div>
        {msg && <AlertWrapper className="success globle">
          <span className="close" onClick={() => setMsg("")}>
            ×
          </span>
          {msg}
        </AlertWrapper>}
        <div className="" style={{ padding: "30px" }}>
          <div className="transaction_head">
            <div className="transaction_heading">
              <div className="transaction_left">
                <label>Debit/Credit List</label>
              </div>
              <div className="transaction_right">
               

                {downloadLoader !== "PDF" ? <button className='pdf' onClick={(e) => handleDebitCreditList(e, "PDF")}
                >PDF</button> :
                  <button className='pdf'>
                    <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                  </button>
                }

                {downloadLoader !== "CSV" ?
                  <button className='xls' onClick={(e) => handleDebitCreditList(e, "CSV")}
                  >CSV</button> :
                  <button className='xls'>
                    <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                  </button>
                }
                <CSVLink
                  ref={link}
                  data={pdfData}
                  filename="DebitCreditList.csv"
                >

                </CSVLink>
              </div>
            </div>
          </div>
          <FilterWrapper style={{height:"auto"}}>
            <div className="filterBox">
              <div>
              <div className="flex align-items gap-20">
                <div>
                  <div className="input-field">
                    <select value={selectStatus}
                      onChange={handleStatus}
                      className="input_select_field">
                      <option value={null} disabled selected>Select Status</option>
                      <option value="PENDING">PENDING</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="REJECTED">REJECTED</option>

                    </select>
                  </div>
                </div>
                
                <div>
                  <div className="input-field">
                    <select
                      value={txnType}
                      defaultValue="DEBIT"
                      onChange={handleTxnType}
                      className="input_select_field">
                      <option value="" onChange={(e) => setTxnType(e.target.value)}>Select Type</option>
                      <option selected value="DEBIT">
                        DEBIT
                      </option>
                      <option value="CREDIT">CREDIT</option>
                    </select>
                  </div>
                </div>
               
                <div>
                  <div className="input-field">
                    <select value={transactionMode}                   
                      className="input_select_field"
                      onChange={(e) => setTransactionMode(e.target.value)}>
                     
                    <option value="null">Select Mode</option>
                    <option value="P2P">P2P</option>
                    <option value="BT">BT</option>
                    <option value="UPI">UPI</option>
                    <option value="UPI COLLECT">UPI COLLECT</option>
                    <option value="VIRTUAL ACCOUNT">VIRTUAL ACCOUNT</option>
                    <option value="UPI PAYMENT">UPI PAYMENT</option>
                    <option value="PREPID CARD">PREPID CARD</option>
                    <option value="BBPS">BBPS</option>

                    </select>
                  </div>
                </div>

                <div className="input-field">
                  <div className="flex">

                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="From Date"
                      selected={fromDate}
                      selectsStart
                      startDate={fromDate}
                      endDate={toDate}
                      onChange={date => setStartDate(date)}
                      maxDate={new Date()}
                    />

                  </div>

                </div>
                <div className="input-field">
                  <div className="flex" >
                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="To Date"
                      selected={toDate}
                      selectsEnd
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      onChange={date => setEndDate(date)}
                      maxDate={new Date()}

                    />
                  </div>
                </div>
              </div>
              <div className="flex align-items gap-20 pdt-15">
                

                <button
                  className="userlist_btn"
                  onClick={(e) => handleDebitCreditList(e, "search")}
                >
                  Search
                </button>


                <div>
                  <Link to="/addcredit"><button
                    className="userlist_btn"

                  >
                    Add Debit/Credit
                  </button>
                  </Link>
                </div>
                
                <button className='refresh-btn'
                  onClick={(e) => handleDebitCreditList(e, "refresh")}
                >
                  <i className="fa fa-refresh"></i>
                </button>
              </div>
              </div>
            </div>

          </FilterWrapper>

          <div className="transaction_table">
            <table id="customers">
              <tr>
                <th>S.No.</th>
                <th>Merchant</th>
                <th>Transaction Id</th>
                <th>Transaction Mode</th>
                <th>Created Date</th>
                <th>Modify Date</th>
                <th>Type</th>
                <th>Reason</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              {loading && (
                <tr>
                  <td colSpan={12}>
                    <LocalLoader />
                  </td>
                </tr>
              )}

              
                
                  {!loading &&
                    listDebitCredit &&
                    listDebitCredit.map((item, index) => {
                      const [date,time] =  item.createdDate.split("T");
                      let newTime = time.slice(0, 8);
                     
                      const [modifyDate,modifyTime]  =  item.lastModifiedDate.split("T")
                      let newModifyTime = modifyTime.slice(0,8);

                      return (
                        <tr className="border" >
                          <td>{currPage * pageSize + index + 1}</td>
                          <td>{item.userId}</td>
                          <td>{item.txnUuid}</td>
                          <td>Net Banking</td>
                          <td>{date+" "+newTime}</td>
                          <td>{modifyDate+" " +newModifyTime}</td>
                          <td>{item.transactionType}</td>
                          <td>{item.reason}</td>
                          <td>{item.amount}</td>
                          <td
                            className={
                              item.status !== "APPROVED" && item.status !== "SUCCESS" ? "required" : "success"
                            }
                          >
                            {item.status}
                          </td>

                          
                            <td>
                              {item.status === "PENDING" && (
                                <div className="flex">
                              <button
                                onClick={() =>
                                  handleApprove(item.txnUuid, "APPROVED")
                                }
                                className="btn_action"
                              >
                                Approve
                              </button>
                              <button
                                onClick={() => handleApprove(item.txnUuid, "REJECTED")}
                                className="btn_action"
                                style={{ marginLeft: "2px" }}
                              >
                                Reject
                              </button>
                              </div>
                              )}
                            </td>
                          
                        </tr>
                      );
                    })}
               
              
            </table>
          </div>
        </div>
        <div className="gggggggggg" style={loading ? { opacity: "0" } : { opacity: "1" }}>
          <Pagination
            items={listDebitCredit}
            itemsPerPage={pageSize}
            setitemsPerPage={setPageSize}
            currentPage={currPage}
            setcurrentPage={setCurrPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </>
  );
};
export default DebitCreditList;
