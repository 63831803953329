import React from 'react'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function EditHelpSupport() {
    const INIT_ERRORS = {
        topic: "",
        question: "",
        answer: "",
        usertype: "",
    };
    const location = useLocation();
    const helpSupportData = location.state
    const [updateFaq, setUpdateFaq] = useState({ ...helpSupportData });
    const [error, setError] = useState(INIT_ERRORS);
    const navigate = useNavigate()
    let usser=updateFaq.UserType

    const handleInput = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setUpdateFaq({ ...updateFaq, [name]: value });
    }

    const updateFaqData = () => {
        if (updateFaq.Topic === "") {
            setError((prev) => ({
                ...prev,
                topic: "Topic can't be null",
            }));
        }
        if (updateFaq.Question === "") {
            setError((prev) => ({
                ...prev,
                question: "Question canot be null",
            }));
        }
        if (updateFaq.Answer === "") {
            setError((prev) => ({
                ...prev,
                answer: "Answer can't be null",
            }));
        }
        if (updateFaq.UserType === "") {
            setError((prev) => ({
                ...prev,
                usertype: "Usertype can't be null",
            }));
        }
        else {
            Swal.fire({
                icon: "success",
                title: "FAQ updated succesfully",
                timer: 1200,
                showConfirmButton: false,
                customClass: 'swal-wide',
            });
            navigate('/helpandsupport')
        }
    }
    return (
        <div className='edit_operator_list_main_container'>
            <div className='edit_operator_heading'>
                <label>Edit Help & Support</label>
            </div>
            <div className='edit_operator_container'>
                <div className='edit_operator'>
                    <div className='edit_operator_left_container'>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <text>FAQ</text>
                                </div>
                            </div>

                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Topic<span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    
                                    <select
                                        name="Topic"
                                        onChange={handleInput}>
                                        <option defaultValue={updateFaq.Topic}>{updateFaq.Topic}</option>
                                        <option value="Transaction">Transaction</option>
                                        <option value="Wallet">Wallet</option>
                                        <option value="AEPS">AEPS</option>
                                    </select>
                                    {error.topic && (
                                        <p className="error fs-12 mt-10">
                                            {error.topic}
                                        </p>
                                    )}
                                </div>

                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Question<span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='text'
                                        placeholder='please enter service code'
                                        name='Question'
                                        value={updateFaq.Question}
                                        onChange={handleInput}
                                    >
                                    </input>
                                    {error.question && (
                                        <p className="error fs-12 mt-10">
                                            {error.question}
                                        </p>
                                    )}
                                </div>

                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Answer<span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='type'
                                        placeholder='select create date'
                                        name='Answer'
                                        value={updateFaq.Answer}
                                        onChange={handleInput}
                                    ></input>
                                    {error.answer && (
                                        <p className="error fs-12 mt-10">
                                            {error.answer}
                                        </p>
                                    )}
                                </div>

                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>User Type<span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <select
                                        name="userType"
                                        onChange={handleInput}
                                       >
                                        <option defaultValue={usser}>{usser}</option>
                                        <option value="Retaile">Retailer</option>
                                        <option value="Agent">Agent</option>
                                    </select>
                                    {error.usertype && (
                                        <p className="error fs-12 mt-10">
                                            {error.usertype}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <button
                                        onClick={updateFaqData}>Edit FAQ</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditHelpSupport
