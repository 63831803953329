import React from 'react'
import "../Transactions/styles/transactions.scss"
import TransactionTable from './TransactionTable'

const Transactions = () => {
  return (
    <div className="transactions_container">
        <TransactionTable/>
    </div>
  )
}

export default Transactions