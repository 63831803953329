import React,{useState} from 'react'
import "../../pages/Help&Support/Style/helpsupport.scss"
import AddNewTopic from './AddNewTopic'
import Pagination from "../../Components/Pagination/Pagination";
import AddNewQuestion from './AddNewQuestion';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";


const helpsupport =[
    {Topic:"Payment related",Question:"How to resolve payment issue",Answer:"How to check transaction history",UserType:"Individual Merchant User"},
    {Topic:"Transation",Question:"How to add money in wallet",Answer:"Go to add money page",UserType:"Individual Merchant User"},
    {Topic:"Transation",Question:"how to do AEPS transaction",Answer:"Check transaction history",UserType:"Individual Merchant User"},
    {Topic:"Payment related",Question:"How to resolve payment issue",Answer:"Go to add money page",UserType:"Individual Merchant User"},
    {Topic:"Transatione",Question:"How to add money in wallet",Answer:"Go to add money page",UserType:"Individual Merchant User"},
    {Topic:"Payment related",Question:"How to check transaction history",Answer:"ABC",UserType:"Individual Merchant User"},
    {Topic:"Transation",Question:"How to add money in wallet",Answer:"Go to add money page",UserType:"Individual Merchant User"},
    {Topic:"Payment related",Question:"How to check transaction history",Answer:"Go to add money page",UserType:"Individual Merchant User"},
]
function HelpandSupport() {
    const [popUpShow,setPopUpShow] = useState(false)
    const [popUpShows,setPopUpShows] = useState(false)
    const [csvData, setCsvData] = useState([]);
    const navigate=useNavigate()


    const updateStatusmodal = (status) => {
        Swal.fire({
            title: "Are you sure? you want to update status",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#00BAF2',
            confirmButtonText: 'Yes, I am sure!',
            cancelButtonText: "No, cancel it!",
            closeOnConfirm: false,
            closeOnCancel: false
        }).then((result) => {
            if (result.isConfirmed) {
                // updateStatus(status)
            }
          });
    }

    const helpSupportData=(e,key)=>{
        var currentDate = new Date();
        var formattedDate = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        if (key === "PDF") {
            const doc = new jsPDF();
            //Add image in PDF header
            const imgWidth = 25; 
            const imgHeight = 9; 
            const leftXPos = 14; 
            const yPos = 2; 
            doc.addImage(companylogo, 'PNG', leftXPos, yPos, imgWidth, imgHeight);

            //Add fileName in the center
            const text = 'FAQ';
            // Calculate the width of the text
            const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            // Get the width of the PDF
            const pdfWidth = doc.internal.pageSize.width;
            const yPos1 = 10;
            // Calculate the X position for center alignment
            const centerXPos = (pdfWidth - textWidth) / 2;
            doc.text(text, centerXPos, yPos1);


            // Calculate the width of the date text
            const dateWidth = doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize() / doc.internal.scaleFactor;

            // Calculate the X position for right alignment
            const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge           
            doc.text(formattedDate, rightXPos, yPos1);

            // Create a table from API data
            const tableData = [];
            helpsupport.forEach((item, index) => {
                const rowData = [index + 1, item.Topic, item.Question, item.Answer, item.UserType]; // Customize as needed
                tableData.push(rowData);
            });

            doc.autoTable({
                head: [['S.No', 'Topic', 'Question', 'Answer', 'UserType']], // Customize table header
                body: tableData,
            });

            doc.save('FAQ.pdf');
        }
        if (key === "CSV") {
            
            const csvData = helpsupport.map((item, index) => [index + 1, item.Topic, item.Question, item.Answer, item.UserType]);

            setCsvData(csvData);

            const csvContent = [
                ['FileName : User_List.CSV', `Date : ${formattedDate}`],
                ['S.No', 'Topic', 'Question', 'Answer', 'UserType'],
                ...csvData,
            ]
                .map(row => row.join(','))
                .join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'FAQ.csv'; // Set custom file name here
            link.click();

            URL.revokeObjectURL(url);
        }
    }
  return (
    <div className="userlisthead_container">
    <div>
        <div className='userlist_main_container'>
            <div className='userlist_head'>
                <div className='userlist_heading'>
                    <div className='userlist_left'>
                        <label>Help & Support</label>
                    </div>
                    <div className='userlist_right'>
                        <button className='pdf' 
                        onClick={(e) => helpSupportData(e, "PDF")}

                        >PDF</button>
                        <button className='xls btn-success' 
                        onClick={(e) => helpSupportData(e, "CSV")}
                        
                        >CSV</button>
                    </div>
                </div>

            </div>
            <div className='userlist_container'>
                <table className="userlist_filter_data">
                    <tr >
                        <td className="help_filter_row">
                            <label >FilterBy</label>
                        </td>
                        <td className="help_filter_row">
                            <select style={{ marginLeft: "20px" }}>
                                <option>select</option>
                                <option>Topic</option>
                            </select>
                        </td>
                       
                        <td className="help_filter_row">
                            <button className="search_btn"
                                >Search</button>
                        </td>

                        <td className="help_filter_row">
                            <label style={{ marginLeft: "25px" }}>FilterBy</label>
                        </td>
                        <td className="help_filter_row">
                            <select style={{ marginLeft: "20px" }}>
                                <option> Select User Type</option>
                                <option>Admin</option>
                                <option>Retailer</option>
                                <option>Distributor</option>
                            </select>
                        </td>
                        <td className="help_filter_row">
                            <button className="search_btn"
                                >Search</button>
                        </td>
                        <td className="help_filter_row">
                            <button className="userlist_btn"
                                style={{ marginLeft: "15px" }} 
                                onClick={()=>setPopUpShow(true)}>Add New Topic</button>
                                { popUpShow ? <AddNewTopic setPopUpShow= {setPopUpShow}/>:""}
                        </td >
                        <td className="help_filter_row">
                            <button className="userlist_btn" style={{ marginLeft: "5%" }}
                             onClick={()=>setPopUpShows(true)}
                            >Add Question</button>
                            { popUpShows ? <AddNewQuestion setPopUpShows= {setPopUpShows}/>:""}
                        </td>
                    </tr>

                </table>
            </div>

        </div>
        <div className="userlist_table">
            <table id="customers">
                <tr>
                    <th>S.No.</th>
                    <th>Topic</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>User Type</th>
                    <th>Actions</th>
                </tr>
                {helpsupport && helpsupport.map((val, ind) => {
                            return (
                                <tr className="table_border" key={Math.random() * 1000000}
                                style={{padding:"10px"}}>
                                    <td>{ind + 1}</td>
                                    <td>{val.Topic}</td>
                                    <td>{val.Question}</td>
                                    <td>{val.Answer}</td>
                                    <td>{val.UserType}</td>
                                    <td className="user_action_btn">
                                            <button
                                                className=""
                                                style={{ marginRight: "5px" }}
                                                onClick={()=>navigate("/edithelpandsupport",{state:val})}
                                            >
                                                <i class="fa-solid fa-pen-to-square update" 
                                               
                                                ></i>
                                            </button>
                                            <button onClick={updateStatusmodal}>
                                            <i class="fa-regular fa-lightbulb" 
                                           ></i>
                                                 
                                                 
                                            </button>

                                            {/* Approved */}
                                        </td>
                                </tr>
                            )

                        })}

                        {
                            helpsupport && (helpsupport.length < 1) &&
                            <>
                                <tr>
                                    <td colSpan={14} style={{textAlign:"center",color:"red",height:"120px",fontSize:"20px"}}>No Data Found</td> 
                                </tr>
                            </>
                        }

            </table>

        </div>

        <Pagination
            // items={apiData}
            // itemsPerPage={pageSize}
            // setitemsPerPage={setPageSize}
            // currentPage={currPage}
            // setcurrentPage={setCurrPage}
            // totalPages={totalPages}
        />
    </div>
</div>
  )
}

export default HelpandSupport
