import React, { useState } from "react";
import LoginRight from "./LoginRight";
import Reset from "./Reset";
import img from "../../assets/img/login-img.png";
import rupp from "../../assets/img/rupee.png";
import "./_styles/login.scss";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const Login = ({ inRegister, setInRegister }) => {
  const [forgotPass, setForgotPass] = useState(false);
  const [generateOtp, setGenerateOtp] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  return (
    <div className="main-div">
        <Header back={false} />
        <div className="mx-20">
        <div className="loginWrapper flex">
           
            <div className="loginLeft ">
                 <h4 className="heading">
                 India's Quickest Multi <br />
                 <span className="strong">Purpose Loan</span>
                 </h4>
                 <div className="Img">
                    <img src={img} alt="img" />
                 </div>
            </div>
            <div className="loginRight">
                <div className="ruee"> <img src={rupp} alt="" /></div>
            {resetPassword ? (
          <Reset />
        ) : (
          <LoginRight
            forgotPass={forgotPass}
            setForgotPass={setForgotPass}
            generateOtp={generateOtp}
            setGenerateOtp={setGenerateOtp}
            resetPassword={resetPassword}
            setResetPassword={setResetPassword}
            inRegister={inRegister}
            setInRegister={setInRegister}
          />
        )}

            </div>
        </div>
        </div>
     
      <Footer />
   
    </div>
  );
};

export default Login;
