import React, { useState } from "react";

const ApiPermissionList = ()=>{
    const [isActive, setIsActive] = useState(false);
    const [drawerShow, setDrawerShow] = useState(false); 

    const handlePermission = (e) => {
        setIsActive((current) => !current);
        setDrawerShow(!drawerShow);
      };

    return(
        <>

      <div
      //  key={index}
        className={isActive ? "bg sub_container" : "sub_container"}
        onClick={handlePermission}
      >

        <h2 className="sub_text">OverView</h2>
        <div className={isActive ? "transform" : ""} onClick={handlePermission}>
          <i class="fa-solid fa-angle-down pointer"></i>
        </div>
  </div>

<div>{drawerShow ? (
        <div className="checkbox_container flex">
          <div className="flex">
                  <div className="flex  align-item-center gap">
                     <input
                        name="checkValue"
                        type="checkbox"
                        className="checkbox_input"
                      ></input>
                    <div>
                      <label className="fs">
                       hello
                      </label>
                    </div>
                  </div>
              
          </div>
        </div>
      ) : (
        ""
      )}
      </div>       
 
      <div
      //  key={index}
        className={isActive ? "bg sub_container" : "sub_container"}
        onClick={handlePermission}
      >

        <h2 className="sub_text">OverView</h2>
        <div className={isActive ? "transform" : ""} onClick={handlePermission}>
          <i class="fa-solid fa-angle-down pointer"></i>
        </div>
  </div>

<div>{drawerShow ? (
        <div className="checkbox_container flex">
          <div className="flex">
                  <div className="flex  align-item-center gap">
                     <input
                        name="checkValue"
                        type="checkbox"
                        className="checkbox_input"
                      ></input>
                    <div>
                      <label className="fs">
                       hello
                      </label>
                    </div>
                  </div>
              
          </div>
        </div>
      ) : (
        ""
      )}
      </div>  

    
        </>
    )
};
export default  ApiPermissionList;