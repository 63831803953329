import React from 'react'
import { useState, useEffect } from 'react';
import Input from '../../Components/Input/Input';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditProfile = () => {

    const [apiUserData, setApiUserData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const editUserData = location.state;

    const getUserData = async () => {
        let user = JSON.parse(sessionStorage.getItem("token"));
        let response = await axios({
            method: "GET",
            url: `user/account`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user.id_token,
            },
        });

        setApiUserData(response.data.data);
    };

   

    useEffect(() => {
        getUserData();
    }, []);

    const updatetUserData = async () => {
        let body = apiUserData;

        let user = JSON.parse(sessionStorage.getItem("token"));

        let response = await axios({
            method: "PUT",
            url: `user/account`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user.id_token,
            },
            data: body,
        });

      
        if (response.data.success === true) {
            Swal.fire({
                icon: "success",
                title: "User updated Successfully",
                timer: 1200,
                showConfirmButton: false,
            });
            navigate("/usersList");
        } else {
            Swal.fire({
                icon: "error",
                text: response.data.msg,
                confirmButtonColor: "#ED1C24",
            });
        }
    };



    useEffect(() => {
        updatetUserData();
    }, []);

   
    const handleInput = (e,key="") => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        setApiUserData({ ...editUserData, [name]: value });
    };
    console.log("apiuserData",apiUserData)
    return (
        <div className="admin_profile_container">
            <div className="admin_profile_header">
                <h2>Admin Profile</h2>
            </div>

            <div className="admin_profile_main">
                <div className="admin_profile_row">
                    <div className="admin_profile_column">
                        <label className="label_head">
                            Personal Details
                        </label>
                    </div>

                </div>
                <div className="admin_profile_row">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                UserName
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you username"
                                name="userName"
                                value={editUserData.userName}

                            />
                        </div>

                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                DOB
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you dob"
                                name="dob"
                                value={"03/03/1989"}

                            />

                        </div>
                    </div>
                </div>
                <div className="admin_profile_row">
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Firstname
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input className="inputtype"
                                type="text"
                                placeholder="enter you firstName"
                                name="firstName"
                                value={editUserData.firstName}
                            />
                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Lastname
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input className="inputtype"
                            type="text"
                            placeholder="enter you lastName"
                            name="lastName"
                            value={editUserData.lastName}                          
                            />

                    </div>
                </div>

                {/* Contact Infomation */}


                <div className="admin_profile_row">
                    <div className="admin_profile_column">
                        <label className="label_head">
                            Contact Details
                        </label>
                    </div>

                </div>

                <div className="admin_profile_row">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Contact Number
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you mobile number"
                                name="phoneNumber"
                                value={editUserData.userName}
                            />

                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Email Address
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input
                            type="text"
                            placeholder="enter you email"
                            name="email"
                            value={editUserData.email}
                        />

                    </div>
                </div>


                {/* Address Details */}

                <div className="admin_profile_row">
                    <div className="admin_profile_column">
                        <label className="label_head">
                            Address Details
                        </label>
                    </div>

                </div>

                <div className="admin_profile_row">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Address1
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you address"
                                name="address1"
                                value={editUserData.address1}
                                onChange={(e) => handleInput(e, "text")}
                            />

                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Address2
                            </label>

                        </div>
                        <Input
                            type="text"
                            placeholder="enter you address2"
                            name="address2"
                            value={editUserData.address2}
                            onChange={handleInput}
                        />
                    </div>
                </div>
                <div className="admin_profile_row">

                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Landmark
                            </label>
                        </div>
                        <div>
                            <Input
                                type="text"
                                placeholder="enter you landmark"
                                name="landmark"
                                value={editUserData.landmark}
                                onChange={handleInput}
                            />
                        </div>
                    </div>
                    <div className="admin_profile_column">
                        <div className="labelname">
                            <label>
                                Picode
                            </label>
                            <span className="required"> *</span>
                        </div>
                        <Input
                            type="text"
                            placeholder="enter you pincode"
                            name="pincode"
                            value={editUserData.pincode}
                            onChange={handleInput}
                        />

                    </div>
                </div>
                <div className="admin_profile_row">
                    <div className="admin_profile_column">
                        <button onClick={() => {updatetUserData()}}
                            className="btn"
                            style={{
                                backgroundColor: "#00BAF2",
                                height: "40px",
                                width: "330px",
                                border: "none"
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditProfile