import React, { useEffect } from "react";
import "./LosSetup.scss";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import rupp from "../../assets/img/rupee.png";
import { Navigate } from "react-router-dom";

function LosSetup({ Component }) {
  useEffect(() => {
    let user = sessionStorage.getItem("token");

    if (!user) {
      <Navigate to={"/"} />;
    }
  });

  return (
    <div className="losSetupWrapper">
      {/* <Header /> */}
      <div className="losSetupMain">
        <div className="losMainBox">
          {/* <div className="ruee">
            {" "}
            <img src={rupp} alt="repp" />
          </div> */}

          <Component />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default LosSetup;
