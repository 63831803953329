
import React, { useEffect, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";

import { useRef } from "react";
import "./loaderstyle.css";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { getTransactionList } from "../../utils/api";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import DatePicker from "react-datepicker";
import { getDateFromDateObj } from "../../utils/common";
require('react-datepicker/dist/react-datepicker.css')



const INIT_ERRORS = {
  fromdate: "",
};
const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fromDate, setStartDate] = useState("");
  const [toDate, setEndDate] = useState("");
  const [service, setService] = useState("")
  const [msgError, setMsgError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [selectOperator, setSelectOperator] = useState("PREPAID_RECHARGE")
  const [selectStatus, setSelectStatus] = useState("")
  
  const [selectType, setSelectType] = useState("Select")
  const [selectMode, setSelectMode] = useState("Select")
  const [transactionId, settransactionId] = useState("")
  const [userName,setUserName] = useState("")
  const [refTransactionId, setREfTransactionId] = useState("")
  const link = useRef();
  const [downloadLoader, setDownloadLoader] = useState('');



  // Date Format
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const transactionData = (e, key = "") => {

    let _fromdate = getDateFromDateObj(fromDate, "YYYY-MM-DD")
    let _todate = getDateFromDateObj(toDate, "YYYY-MM-DD")

    if ((getDateFromDateObj(fromDate, "YYYY-MM-DD") === "Invalid date") || (getDateFromDateObj(toDate, "YYYY-MM-DD") === "Invalid date")) {
      _fromdate = "";
      _todate = "";
    }

    setLoading(true);
    let param = {
      fromDate: _fromdate,
      toDate: _todate,
      pagination: {
        pageNo: currPage,
        pageSize: pageSize,
      },
      serviceType: selectOperator,
      transactionId: transactionId,
      status: selectStatus,
      refTransactionId:refTransactionId,
      userId:useState
    };

    if (key === "CSV" || key === "PDF") {
      if ((key === "CSV")) {
        setDownloadLoader("CSV")
      } else {
        setDownloadLoader("PDF")
      }
      param.pagination.pageSize = totalElements;
      param.pagination.pageNo = 0;
      setCurrPage(0)
      // param.serviceType = ""
    }
    if (key === "search" || key==="refreshData") {
      param.pagination.pageNo = 0;
      setCurrPage(0)
    } else {
      setLoading(true)
    }
    if (key === "refresh") {
      dataRefresh()
      param.fromDate = "";
      param.serviceType = "";
      param.transactionId = "";
      param.toDate = "";
      param.userName="";
      param.refTransactionId="";
    }
    getTransactionList(param).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      if (resp?.data?.success) {

        if (key == "CSV") {
          setPdfData(resp?.data?.data?.content);
          setTimeout(() => {
            const csvlink = link.current
            csvlink.link.click();
          }, 100)
        }
        else if (key === "PDF") {
          transactionPdfData(resp?.data?.data?.content);
        }
        else {
          setTransactions(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElements(resp?.data?.data?.totalElements);
        }
      } else {
        setTransactions([]);

        setTotalPages(null);

        if (resp?.data?.errorCodeList.length < 1) {
          //  alert(response?.data?.msg)
          setMsgError(resp?.data?.msg);
        } else {
          setMsgError(resp?.data?.errorCodeList[0]);
        }
      }
    });

  };


  useEffect(() => {
    transactionData();
  }, [selectOperator, currPage]);

  useEffect(() => {
    transactionData("refreshData");
  }, [selectStatus]);


  const transactionPdfData = (data = []) => {
    const doc = new jsPDF();
    
    const imgWidth = 25;
    const imgHeight = 9; // Adjust the height of the logo
    const leftXPos = 14; // Adjust the X position
    const yPos = 2; // Adjust the Y position
    //   doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

    //Add fileName in the center
    const text = "Transaction List";
    // Calculate the width of the text
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    // Get the width of the PDF
    const pdfWidth = doc.internal.pageSize.width;
    const yPos1 = 10;
    // Calculate the X position for center alignment
    const centerXPos = (pdfWidth - textWidth) / 2;
    doc.text(text, centerXPos, yPos1);

    // Calculate the width of the date text
    const dateWidth =
      (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    // Calculate the X position for right alignment
    const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
    doc.text(formattedDate, rightXPos, yPos1);

    // Create a table from API data
    const tableData = [];

    data.forEach((item, index) => {
      const rowData = [index + 1,
      item.serviceType === "PREPAID_RECHARGE" ? item.rechargedNumber : item.serviceType === "AEPS" ?
        item.serviceType : item.serviceType === "SEND_MONEY" ? item.accountHolderName : item.rechargedNumber,
      item.serviceType === "PREPAID_RECHARGE" ? item.operatorCode : item.serviceType === "DTH" ? item.operatorCode :
        item.serviceType === "AEPS" ? item.orderId : item.accountIfscCode,
      item.serviceType === "PREPAID_RECHARGE" ? item.transactionId : item.serviceType === "DTH" ? item.transactionId : item.serviceType === "AEPS" ? item.transactionId
        : item === "SEND_MONEY" ? item.transactionId : item.accountIfscCode,
      item.amount,
      item.status

      ]; // Customize as needed
      tableData.push(rowData);
    });
    doc.autoTable({
      head: [["S.No",
        selectOperator === "PREPAID_RECHARGE"
          ? "Recharge Number"
          : selectOperator === "AEPS"
            ? "Service Type"
            : "Account Holder Name",

        selectOperator === "PREPAID_RECHARGE"
          ? "Operator Code"
          : selectOperator === "DTH"
            ? "Operator Code"
            : selectOperator === "AEPS"
              ? "Order Id"
              : "Account IFSC Code",
        selectOperator === "PREPAID_RECHARGE" ? "Transaction Id" : selectOperator === "DTH" ? "Transaction Id" : selectOperator === "AEPS" ? "Merchant Transaction Id"
          : selectOperator === "SEND_MONEY" ? "Transaction Id" : "Account IFSC Code",
        "Amount",
        "Status"]], // Customize table header
      body: tableData,
    });

    doc.save("TransactionList.pdf");
  };


  const handleOpertaorChange = (e) => {
    setEndDate('');
    setEndDate('');
    setSelectOperator(e.target.value);
    // transactionData(e.target.value);
  }
  const dataRefresh = () => {
    setSelectOperator("PREPAID_RECHARGE");
    settransactionId("");
    setREfTransactionId("");
    setSelectStatus("")
    setSelectType("Select")
    setSelectMode("")
    setService("")
    setStartDate("")
    setEndDate("")
    setUserName("")
  }
console.log("refenceId",refTransactionId)
  return (
    <div >
      <div className="transaction_main_container" style={{ marginTop: "-10px" }}>
        <div className="transaction_head">
          <div className="transaction_heading">
            <div className="transaction_left">
              <label>Transactions</label>
            </div>
            <div className="transaction_right">

              {downloadLoader !== "PDF" ? <button className='pdf' onClick={(e) => transactionData(e, "PDF")}
              >PDF</button> :
                <button className='pdf'>
                  <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                </button>
              }

              {downloadLoader !== "CSV" ? <button className='xls' onClick={(e) => transactionData(e, "CSV")}
              >CSV</button> :
                <button className='xls'>
                  <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                </button>
              }
              <CSVLink
                ref={link}
                data={pdfData}
                filename="TransactionList.csv"
              >

              </CSVLink>
            </div>
          </div>
        </div>

        <FilterWrapper style={{ height: "auto" }}>
          <div className="filterBox">
            <div>
              <div className="flex align-items gap-20">
                <div className="input-field" >
                  <select className="input_select_field" value={selectOperator}
                    onChange={handleOpertaorChange} >
                    <option value="PREPAID_RECHARGE">Prepaid Recharge</option>
                    {/* <option value="DTH">DTH</option> */}
                    <option value="AEPS">AEPS</option>
                    <option value="SEND_MONEY">Send-Money</option>
                  </select>
                </div>

                <div className="input-field" >
                  <select className="input_select_field" value={selectStatus}
                    onChange={(e) => setSelectStatus(e.target.value)}>
                    <option value={""}>Select Status</option>
                    <option value="SUCCESS">Success</option>
                    <option value="FAIL">Fail</option>
                    <option value="INITIATED">Initiated</option>
                  </select>
                </div>

                <div className="">
                  <input
                    type="text"
                    name="service"
                    placeholder="Mobile Number"
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                  ></input>
                </div>


                <div className="input-field">
                  <div className="flex">

                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="From Date"
                      selected={fromDate}
                      selectsStart
                      valueDefault={null}
                      startDate={fromDate}
                      endDate={toDate}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      showYearDropdown
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={date => setStartDate(date)}
                      maxDate={new Date()}
                    />

                  </div>

                </div>
                <div className="input-field">
                  <div className="flex" >
                    <DatePicker
                      showIcon
                      className="date-picker2 date_type"
                      placeholderText="To Date"
                      selected={toDate}
                      selectsEnd
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown={true}
                      
                      showYearDropdown
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={date => setEndDate(date)}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
              <div className="flex align-items gap-20 pdt-15">
                {/* <div className="input-field" >
                  <select className="input_select_field" value={selectType}
                    onChange={(e) => setSelectType(e.target.value)}>
                    <option value="Select">Type</option>
                    <option value="Debit">Debit</option>
                    <option value="Credit">Credit</option>
                  </select>
                </div> */}
                {/* <div className="input-field" >
                  <select className="input_select_field"
                    value={selectMode}
                    onChange={(e) => setSelectMode(e.target.value)}>
                    <option value="Select">Select Mode</option>
                    <option value="P2P">P2P</option>
                    <option value="BT">BT</option>
                    <option value="UPI">UPI</option>
                    <option value="UPI COLLECT">UPI COLLECT</option>
                    <option value="VIRTUAL ACCOUNT">VIRTUAL ACCOUNT</option>
                    <option value="UPI PAYMENT">UPI PAYMENT</option>
                    <option value="PREPID CARD">PREPID CARD</option>
                    <option value="BBPS">BBPS</option>
                  </select>
                </div> */}

                <div className="" >
                  <input
                    type="text"
                    name="refTransactionId"
                    placeholder="Ref Transaction Id"
                    value={refTransactionId}
                    onChange={(e) => setREfTransactionId(e.target.value)}
                  ></input>
                </div>
                <div className="" >
                  <input
                    type="text"
                    name="transactionId"
                    placeholder="Transaction Id"
                    value={transactionId}
                    onChange={(e) => settransactionId(e.target.value)}
                  ></input>
                </div>

                <button
                  className="userlist_btn"
                  onClick={(e) => transactionData(e, "search")}
                  style={{ width: "100px" }}
                >
                  Search
                </button>


                <button className='refresh-btn'
                  style={{ width: "60px" }}
                  onClick={(e) => transactionData(e, "refresh")}>
                  <i className="fa fa-refresh"></i>
                </button>
              </div>
            </div>
          </div>
        </FilterWrapper>

      </div >
      <div className="transaction_table">
      {loading && <LocalLoader className="tableLoader" />}
        <table id="customers" >
          <tr>
            <th>
              {selectOperator === "PREPAID_RECHARGE"
                ? "Recharge Number"
                : selectOperator === "AEPS"
                  ? "Account Number"
                  : "Account Holder Name"}
            </th>
            {selectOperator ===  "SEND_MONEY" && <th>Account Number </th> }
            <th>
              {selectOperator === "PREPAID_RECHARGE"
                ? "Operator Code"
                : selectOperator === "DTH"
                  ? "Operator Code"
                  : selectOperator === "AEPS"
                    ? "Order Id"
                    : "Account IFSC Code"}
            </th>
            <th>
              {selectOperator === "PREPAID_RECHARGE" ? "Transaction Id" : selectOperator === "DTH" ? "Transaction Id" : selectOperator === "AEPS" ? "Merchant Transaction Id"
                : selectOperator === "SEND_MONEY" ? "Transaction Id" : "Account IFSC Code"
              }
            </th>
            
            {/* <th>Transaction Id </th> */}
            <th>Date and Time</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Service Type</th>
            {/* <th>Mode</th> */}



          </tr>
         

          {transactions &&
            transactions?.map((item) => {
              const dateTimeString = item.transactionDate;
              let [date, time] = dateTimeString.split("T");
              let newTime = time.slice(0, 8);
              return (
                <tr className="table_border" key={Math.random() * 1000000}>
                  <td>
                    {selectOperator === "PREPAID_RECHARGE"
                      ? item.rechargedNumber
                      : selectOperator === "DTH"
                        ? item.rechargedNumber
                        : selectOperator === "AEPS"
                          ? item.accountNumber
                          : item.accountHolderName}
                  </td>
                {selectOperator==="SEND_MONEY" && <td>{item.accountNumber}</td>}
                  <td>
                    {selectOperator === "PREPAID_RECHARGE"
                      ? item.operatorCode
                      : selectOperator === "DTH"
                        ? item.operatorCode

                        : item.accountIfscCode}
                  </td>
                  <td>{item.transactionId || "Null"}</td>
                  <td>{date+" "+newTime}</td>
                  <td> ₹ {item.amount}</td>
                  <td>
                    <div
                      className={`${item.status.toLowerCase() === "INITIATED" || (item.status).toLowerCase() === "success" || item.status.toLowerCase() === "done"
                        ? "active_status"
                        : "inactive_status"
                        }`}
                    >
                      {item.status}
                    </div>
                  </td>

                  <td>{item.serviceType}</td>
                </tr>
              );
            })}

          {
          !loading && transactions && transactions.length < 1 && (
           < >
           <tr>
              <td
                colSpan={12}
                style={{ color: "red", textAlign: "center", height: "140px" }}
              >
                No Data Found
              </td>
            </tr></>
          )}
        </table>
      </div>
      {
        totalPages > 1 &&
        <Pagination
          items={transactionData}
          itemsPerPage={pageSize}
          setitemsPerPage={setPageSize}
          currentPage={currPage}
          setcurrentPage={setCurrPage}
          totalPages={totalPages}
        />
      }

    </div >
  );
};

export default TransactionTable;
