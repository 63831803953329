import React from "react";
import uploadImage from '../../assets/images/uploadImage.png'
import smile from '../../assets/images/smile.png'
import senderImage from '../../assets/images/senderImage.png'

const ViewTicket =()=>{
    
    return(
        <>
         <div className="userlisthead_container">
            <div className="subadmin_main_container">
                <strong>View Ticket</strong>
            
         

               <div className="flex align-item-center" style={{marginBottom:"45px"}}>
                
               <div className="subadmin_container" style={{width:"440px",borderRadius:"10px 0px 0px 10px",marginBottom:"0"}}>
              
              <div  style={{height:"524px", padding: "31px 0 0 20px"}}>
               <h3>Ticket Chat Details</h3>
               <div className="flex" style={{gap:"20%" ,marginTop:"37px"}}>
               <div>
               <ul style={{padding:"0"}}>
                <li className="fs mg-bottom17">Ticket ID</li>
                <li className="fs mg-bottom17">Name</li>
                <li className="fs mg-bottom17">Mobile No.</li>
                <li className="fs mg-bottom17">Ticket Date</li>
                <li className="fs mg-bottom17">Issue Category</li>
                <li className="fs mg-bottom17">Request Type</li>
                <li className="fs mg-bottom17">Discription</li>
                <li className="fs mg-bottom17">Issue Status</li>
                <li className="fs mg-bottom17">Last Update</li>
                <li className="fs mg-bottom17">Action</li>
               </ul>
               </div>
               <div>
               <ul style={{padding:"0"}}>
               <li className="fs mg-bottom17">P2P2458</li>
                <li className="fs mg-bottom17">Manoj Kumar</li>
                <li className="fs mg-bottom17">8527141474</li>
                <li className="fs mg-bottom17">07 Apr 2023 | 10:50AM</li>
                <li className="fs mg-bottom17">KYC</li>
                <li className="fs mg-bottom17">Issue</li>
                <li className="fs mg-bottom17"></li>
                <li className="fs mg-bottom17"><select><option>Open</option></select></li>
                <li className="fs mg-bottom17">Last Update</li>
                <li className="fs mg-bottom17"><select><option>Open</option></select></li>
               </ul>
               </div>
               
               </div>
               
              </div>
             
             
             </div>
             
             <div className="chat_container">

                <div className="flex reciver">
                <div className="pic"></div>
               <div className="mx-width fs reciver_path  chat_msg" style={{}}>Hi</div>
                </div>
                <div className="flex sender" style={{justifyContent: "flex-end"}}> 
                    <div className="mx-width fs sender_path chat_msg" style={{}} >Hi! How can I help you?</div>
                    <div className="pic"></div>
                </div>
                <div className="flex reciver">
                <div className="pic"></div>
               <div className="mx-width fs reciver_path  chat_msg" style={{}}>I can't see my balance and history after logging in</div>
                </div>

                <div className="flex sender" style={{justifyContent: "flex-end"}}> 
                    <div className="mx-width  fs sender_path chat_msg" style={{}} >Don't worry! This may be due to some technical issues on the our end</div>
                    <div className="pic flex align-item-center">
                        <img className="senderImage"  src={senderImage}></img>
                    </div>
                </div>
             
             
              <div className="chat_footer" style={{marginTop:"57px",marginBottom:"24PX"}}>
                <input type="text" className="chat_footer_text" placeholder="Type Your Msg Here..."></input>
                 <div>
                    <img className="position_chat" src={smile} style={{left:"20px"}}></img>
                 <img className="position_chat" src={uploadImage} style={{left: "51px"}}></img>
              <button className="userlist_btn" style={{position: "absolute",
    right:"10px",
    bottom: "13px"}}>Submit</button>
                 </div>

              </div>
              
             </div>
               

               </div>
               </div> 
         
         
         
         
         </div>
        </>
    )

}
export default ViewTicket;