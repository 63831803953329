import React, { useState, useEffect } from "react";
import "./style.scss";

function Dropdown({ options, dd_name ,dropdownValue, setDropdownValue, inWallet}) {
    const [dropdownState, setDropdownState] = useState(false);
    const [dd_options, setDd_Options] = useState([]);

    const [selectedVal, setSelectedVal] = useState("")
      useEffect(()=>{
        setSelectedVal(dropdownValue)
      },[dropdownValue])

    useEffect(() => {
        setDd_Options(options);
    }, []);

    const handleDropdownClick = () => {
        setDropdownState(!dropdownState);
    };
    const handleSetDropdownValue = (item) => {
        // operatorsData()
       
        setDropdownValue(item.value);
        setSelectedVal(item.label)
        setDropdownState(!dropdownState);

    };

    return (
        <div className="dd_container">
            <div className={`dropdown`}>
                <button onClick={handleDropdownClick} className="dropdown-btn d-flex justify-between align-item">
                    {selectedVal !=="" ? selectedVal : 'Select'}

                    <i className="fa-solid fa-sort-down mb-5"></i>
                </button>
                <div
                    className={`dropdown-items ${
                        dropdownState ? "isVisible" : "isHidden"
                    }`}
                >
                    <div className="dropdown-item">
                        {dd_options.map((item,i) => (
                            <div
                                key={i}
                                className="dropdown__link"
                                onClick={() =>
                                    handleSetDropdownValue(item)
                                }
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dropdown;
