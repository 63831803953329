import React from "react";
import LosBoxTop from "./LosBoxTop";
import carret from "../../assets/img/carret.svg";
import demo from "../../assets/img/demo.png";
import { Link } from "react-router-dom";

function ManageTeam() {
  return (
    <div className="mainBoxWrapper">
    <LosBoxTop step="2" />
    <div className="fromBox">
      <div className="mainfrom flex">
        <div class="from-group">
          <div class="from-field">
            <label for="">
            Team Member Name <span class="red">*</span>
            </label>
            <div class=" flex">
              <input
                class=" "
                placeholder="Enter Team Member Name"
                type="text"
              />
            </div>
          </div>
          <div class="msg"></div>
        </div>
        <div class="from-group">
          <div class="from-field">
            <label for="">
            Role Type  <span class="red">*</span>
            </label>
            <div class=" flex">
            <select name="" id="">
                <option value="">Admin</option>
                <option value="">Sub Admin</option>
                <option value="">Organization Type</option>
              </select>
              <img className="icon" src={carret} alt="icon" />
            </div>
          </div>
          <div class="msg"></div>
        </div>
        <div class="from-group">
          <div class="from-field">
            <label for="">
            Email ID<span class="red">*</span>
            </label>
            <div class=" flex">
              <input
                placeholder="Enter E-mail"
                type="text"
              />
            </div>
          </div>
          <div class="msg"></div>
        </div>
        <div class="from-group">
          <div class="from-field">
            <label for="">
            Phone No.<span class="red">*</span>
            </label>
            <div class=" flex">
              <input
                class=" "
                maxlength="10"
                placeholder="Enter Mobile No."
                type="text"
              />
            </div>
          </div>
          <div class="msg"></div>
        </div>
      

    
     
      </div>
      <div className="submitButtom">
          <Link className="btn" to="/documents-upload">
          NEXT
          </Link>
        </div>
    </div>
  </div>
  );
}

export default ManageTeam;
