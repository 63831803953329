import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Pagination from "../../Components/Pagination/Pagination";
import { CSVLink } from "react-csv";
import { useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png";
import { getWalletList } from "../../utils/api";
import LocalLoader from "../../Components/Loader/LocalLoader";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import DatePicker from "react-datepicker";
require('react-datepicker/dist/react-datepicker.css')

const options = [
    ,
    { value: "MAIN_WALLET", label: "Main Wallet" },
    { value: "AEPS_WALLET", label: "Aeps Wallet" },
];

const WalletSummary = () => {
    const [walletData, setWalletData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);

    const [currPage, setCurrPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalElement, setTotalElement] = useState("")
    const [dropdownState, setDropdownState] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("MAIN_WALLET");
    const [txnType, setTransactionType] = useState(null)
    const [status, setStatus] = useState(null)

    const [fromDate, setStartDate] = useState("");
    const [toDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [pdfData, setPdfData] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState('');

    const link = useRef();

    const [errors, setErrors] = useState({
        startDate: "",
        endData: "",
    });

    const handleApiData = async (e, key) => {
        setLoading(true)

        const param = {
            fromDate: fromDate,
            pagination: {
                pageNo: currPage,
                pageSize: pageSize
            },

            txnType: txnType,
            toDate: toDate,
            walletType: dropdownValue
        }
        if (key == "CSV" || key == "PDF") {
            if ((key === "CSV")) {
                setDownloadLoader("CSV")
            } else {
                setDownloadLoader("PDF")
            }
            param.pagination.pageSize = totalElement;
        }
        if (key === "search") {
            param.pagination.pageNo = 0;
        } else {
            setLoading(true)
        }
        if (key === "refresh") {
            dataRefresh()
            param.fromDate = "";
            param.serviceName = "";
            param.toDate = "";
        }
        getWalletList(param).then((resp) => {

            setLoading(false)
            setDownloadLoader("");
            if (resp?.data?.success) {
                setLoading(false)
                if (key == "CSV") {


                    setPdfData(resp?.data?.data?.content);

                    setTimeout(() => {
                        const csvlink = link.current
                        csvlink.link.click();
                    }, 100)
                }
                else if (key === "PDF") {


                    walletPdfData(resp?.data?.data?.content)


                }
                else {

                    setWalletData(resp?.data?.data?.content);
                    setTotalPages(resp?.data?.data?.totalPages);

                    setTotalElement(resp?.data?.data?.totalElements);
                }


            }
        })

    };

    useEffect(() => {
        handleApiData(null, null);
    }, [dropdownValue, currPage]);

    const walletPdfData = (data = []) => {

        const doc = new jsPDF();

        //Add image in PDF header
        const imgWidth = 25; // Adjust the width of the logo
        const imgHeight = 9; // Adjust the height of the logo
        const leftXPos = 14; // Adjust the X position
        const yPos = 2; // Adjust the Y position
        doc.addImage(companylogo, 'PNG', leftXPos, yPos, imgWidth, imgHeight);

        //Add fileName in the center
        const text = 'Wallet Summary';
        // Calculate the width of the text
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // Get the width of the PDF
        const pdfWidth = doc.internal.pageSize.width;
        const yPos1 = 10;
        // Calculate the X position for center alignment
        const centerXPos = (pdfWidth - textWidth) / 2;
        doc.text(text, centerXPos, yPos1);

        // Download Date
        var currentDate = new Date();
        var formattedDate = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');

        // Calculate the width of the date text
        const dateWidth = doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // Calculate the X position for right alignment
        const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge           
        doc.text(formattedDate, rightXPos, yPos1);
        // Create a table from API data
        const tableData = [];

        data.forEach((item, index) => {
            const rowData = [index + 1, item.transactionType, item.userId, item.transactionAmout, item.currentAmount, item.createdDate, item.transactionId]; // Customize as needed
            tableData.push(rowData);
        });

        doc.autoTable({
            head: [['S.No', 'Type', 'Retailer Name', 'Transaction Ammount', 'Current Ammount', 'Created Date', 'Transaction Id',]], // Customize table header
            body: tableData,
        });

        doc.save('WalletSummary.pdf');

    };
    //  disable future dates
    const disableFutureDate = () => {
        let today, date1;
        today = new Date();
        date1 = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');

        return date1
    }
    const dataRefresh = () => {
        // setSelectOperator("MOBILE");
        // setSelectStatus("Select")
        // setSelectType("Select")
        // setSelectMode("")
        // setService("")
        setStartDate("")
        setEndDate("")
    }
    return (

        <div style={{ padding: "30px" }}>
            <div className="transaction_main_container">
                <div className='transaction_head'>
                    <div className='transaction_heading'>
                        <div className='transaction_left'>
                            <label>Wallet List</label>
                        </div>
                        <div className='transaction_right'>

                            {downloadLoader !== "PDF" ? <button className='pdf' onClick={(e) => handleApiData(e, "PDF")}
                            >PDF</button> :
                                <button className='pdf'>
                                    <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                </button>
                            }

                            {downloadLoader !== "CSV" ? <button className='xls' onClick={(e) => handleApiData(e, "CSV")}
                            >CSV</button> :
                                <button className='xls'>
                                    <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                </button>
                            }
                            <CSVLink
                                ref={link}
                                data={pdfData}
                                filename="TransactionList.csv"
                            >

                            </CSVLink>
                        </div>
                    </div>
                </div>
                <FilterWrapper style={{ height: "120px" }}>
                    <div className="filterBox">
                        <div>
                            <div className="flex align-items gap-20">
                                <div className="input-field">
                                    <input
                                        type="text"
                                        placeholder="Mobile No"
                                        maxLength={10}

                                    ></input>
                                </div>

                                <div className="input-field">
                                    <input
                                        type="text"
                                        placeholder="Transaction Id"
                                    ></input>
                                </div>
                                <div>
                                    <Dropdown
                                        className="input-select_feild"
                                        options={options}
                                        dd_name={"Phone No."}
                                        dropdownValue={dropdownValue}
                                        setDropdownValue={setDropdownValue}
                                        dropdownState={dropdownState}
                                        setDropdownState={setDropdownState}
                                    />
                                </div>


                                {/* <div className="input-field">
                               
                                
                                    <div className="flex main_date_input">
                                        <div >
                                            <input className="fw-bold date_type"
                                                style={{width:"120px"}}
                                                value={fromDate}
                                                type="date"
                                                name=""
                                                onChange={(e) => setStartDate(e.target.value)}
                                                max={disableFutureDate()}
                                            />
                                            {errors.fromdate && (
                                                <p className="error fs-12">
                                                    {errors.fromdate}
                                                </p>
                                            )}

                                        </div>
                                       
                                        <div>
                                           
                                            <input className="fw-bold date_type"
                                                style={{marginLeft:"-10px",width:"120px" }}
                                                value={toDate}
                                                type="date"
                                                name=""
                                                onChange={(e) => setEndDate(e.target.value)}
                                                max={disableFutureDate()}
                                            />
                                            {errors.toDate && (
                                                <p className="error fs-12">
                                                    {errors.toDate}
                                                </p>
                                            )}
                                        </div>
                               

                                        
                                    </div>
                                   
                                </div> */}
                                <div className="input-field">
                                    <div className="flex">

                                        <DatePicker
                                            showIcon
                                            className="date-picker2 date_type"
                                            placeholderText="From Date"
                                            selected={fromDate}
                                            selectsStart
                                            startDate={fromDate}
                                            endDate={toDate}
                                            onChange={date => setStartDate(date)}
                                            onKeyDown={(e) => e.preventDefault()}
                                            yearDropdownItemNumber={100}
                                            scrollableYearDropdown={true}
                                            showYearDropdown
                                            maxDate={new Date()}
                                        />

                                    </div>

                                </div>
                                <div className="input-field">
                                    <div className="flex" >

                                        <DatePicker
                                            showIcon
                                            className="date-picker2 date_type"
                                            placeholderText="To Date"
                                            selected={toDate}
                                            selectsEnd
                                            startDate={fromDate}
                                            endDate={toDate}
                                            minDate={fromDate}
                                            yearDropdownItemNumber={100}
                                            scrollableYearDropdown={true}
                                            showYearDropdown
                                            onChange={date => setEndDate(date)}
                                            onKeyDown={(e) => e.preventDefault()}
                                            maxDate={new Date()}

                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="flex align-items gap-20 pdt-15">
                                <div>
                                    <div className="input-field">
                                        <select value={txnType}
                                            onChange={(e) => setTransactionType(e.target.value)}
                                            className="input_select_field">
                                            <option value="null">Transaction Type</option>
                                            <option value="DEBIT">Debit</option>
                                            <option value="CREDIT">Credit</option>

                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="input-field">
                                        <select value={status}
                                            onChange={(e) => setStatus(e.target.value)} className="input_select_field">
                                            <option value="null">Status</option>
                                            <option value="SUCCESS">Success</option>
                                            <option value="FAIL">Fail</option>
                                            <option value="REFUND">Refund</option>

                                        </select>
                                    </div>
                                </div>

                                <button className="userlist_btn"
                                    onClick={(e) => handleApiData(e, "search")}
                                >Search</button>

                                <button className='refresh-btn' onClick={(e) => handleApiData(e, "refresh")}>
                                    <i className="fa fa-refresh"></i>
                                </button>


                            </div>
                        </div>
                    </div>
                </FilterWrapper>
            </div>
            <div className='transaction_table'>
                {loading && <LocalLoader className="tableLoader" />}
                <table id="customers" >
                    <tr>

                        <th>Retailer Mobile No.</th>
                        <th>Transaction Id</th>
                        <th>Transaction Amount</th>
                        <th>Created Date</th>
                        <th>Opening Amount</th>
                        <th>Closing Amount</th>
                        <th>Charges</th>
                        <th>Commission</th>
                        <th>Final Amount</th>
                        <th>Transaction Type</th>
                        <th>Status</th>
                        <th>Remark</th>


                    </tr>

                    {/* {loading && (
                            <tr>
                                <td colSpan={12}>
                                    <LocalLoader />
                                </td>
                            </tr>
                        )} */}
                    {walletData && walletData && walletData.map((item, index) => {
                        // let createdDate = item.createdDate.split("T", 1);
                        const [date,time] =  item.createdDate.split("T");
                        let newTime = time.slice(0, 8);
                        let commision = item.commission
                       
                        return (
                            <tr className="wallet_border" key={Math.random() * 1000000}>


                                <td>{item.userId}</td>
                                <td>{item.transactionId}</td>

                                <td> ₹ {item.transactionAmout} </td>
                                <td>{date+" "+" "+newTime}</td>
                                <td> ₹ {item.openingAmount}</td>
                                <td> ₹ {item.currentAmount}</td>
                                <td>{item.charges}</td>
                                <td>{(Math.round(commision * 100) / 100).toFixed(2)}</td>
                                <td>{(Math.round(item.finalTxnAmount * 100) / 100).toFixed(2)}</td>

                                <td>{item.transactionType}</td>
                                <td>
                                    <div
                                        className={`${item.txnStatus == "SUCCESS"
                                            ? "active_status" 
                                            : item.txnStatus == "REFUND"? "refund_status":
                                            "inactive_status"
                                            }`}
                                    >
                                        {item.txnStatus}
                                    </div>
                                </td>
                                {/* <td>{item.txnStatus}</td> */}
                                <td>
                                    {item.remarks}

                                    {/* <div
                                            className={`${item.walletStatus === "ACTIVE"
                                                ? "active_status"
                                                : "inactive_status"
                                                }`}
                                        >
                                            
                                        </div> */}
                                </td>

                            </tr>
                        )

                    })}

                    {
                      !loading  && walletData && (walletData.length < 1) &&
                        <tr>
                            <td
                                colSpan={12}
                                style={{ color: "red", textAlign: "center", height: "140px" }}
                            >
                                No Data Found
                            </td>
                        </tr>
                    }
                </table>
            </div>

            {
                totalPages > 1 &&
                <Pagination
                    items={walletData}
                    itemsPerPage={pageSize}
                    setitemsPerPage={setPageSize}
                    currentPage={currPage}
                    setcurrentPage={setCurrPage}
                    totalPages={totalPages}
                />

            }

        </div>

    );
};

export default WalletSummary;