import React, { memo } from "react";
import { ModalWrapper } from "../../Components/Modal/Modal";
import "../../pages/Help&Support/Style/helpsupport.scss"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/common";
import Swal from "sweetalert2";


const AddNewTopic = memo(({
    setPopUpShow }) => {
    const closePopUpHandler = () => {
        setPopUpShow(false)
    }

    const INITIAL_VALUES = {
        Topic: "",
    }
    const INIT_ERROR = {
        topic: "",
    }

    const [newTopic, setNewTopic] = useState(INITIAL_VALUES);
    const [error, setError] = useState(INIT_ERROR);
    const navigate=useNavigate();

    const hanleInput = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        if((name==="Topic") && !isEmpty(value)){
            setError((prev) => ({
                ...prev,
                topic: ""
            }))
        }
        setNewTopic({ ...newTopic, [name]: value });
    }

    const addNewTopic = () => {
        if (newTopic.Topic === "") {
            setError((prev) => ({
                ...prev,
                topic: "topic can't be null",
            }));
        }
        else{
            Swal.fire({
                    icon: "success",
                    title: "New Tpoic is added succesfully",
                   
                    timer: 1200,
                    showConfirmButton: false,
                    customClass: 'swal-wide',

                });
                setPopUpShow(false)
        }
    }

    return (
        <ModalWrapper>
            <div className="modal-dialog modal" style={{ width: '100%' }} role="document">
                <div className="modal-content bg_image ">
                    <div>
                        <div className="modal-header flex justify-between item-center">
                            <label className="modal-title" id="exampleModalLabel">
                                Add New Topic
                            </label>
                            <span aria-hidden="true">
                                <i className="fa fa-times" onClick={closePopUpHandler} aria-hidden="true"
                                    style={{ color: "#00286F", height: "34px", width: "34px", fontSize: "34px" }}></i>
                            </span>
                        </div>
                        <div className='add_new_topic'>
                            <div className='add_newtopic_container'>
                                <div className='add_new_topic_flex'>
                                    <div className='add_new_topic_data'>
                                        <div className='add_new_topic_row'>
                                            <label>Type New Topic <span>*</span></label>
                                        </div>
                                        <div className='add_new_topic_row'>
                                            <input type='text'
                                                placeholder='type topic here....'
                                                name="Topic"
                                                value={newTopic.Topic}
                                                onChange={hanleInput}
                                            ></input>
                                            {error.topic && (
                                                <p className="error fs-12 mt-10">
                                                    {error.topic}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='add_new_topic_flex'>
                                    <div className='add_new_topic_data'>
                                        <div className='add_new_topic_row'>
                                            <button
                                            onClick={addNewTopic}>Add New Topic</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
}
)

export default AddNewTopic;

