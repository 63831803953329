import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../store/authSlice";
import Spinner from "../../Components/spinner/Spinner";
import { Link, Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import {
  forgetPasswordGenerateOtp,
  getUserLogin,
  LoginPasswordReset,
} from "../../utils/api";
import { isEmpty, saveAuthToken } from "../../utils/common";

import Swal from "sweetalert2";
const LoginRight = ({
  forgotPass,
  setForgotPass,
  generateOtp,
  setGenerateOtp,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState(""); // this state is for username in forgot password
  const [enteredOtp, setEnteredOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionTime, setSessionTime] = useState("");
  const [passwordType, setpasswordType] = useState("password");
  const [timer, setTimer] = useState(30);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
   
  });

  const handleTokenExpiry = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    jsonPayload = JSON.parse(jsonPayload);
    let expirytime = jsonPayload.exp;

    let sessionTime = new Date(expirytime * 1000);

    const expirationDate = new Date();

    // Calculate the expiration time in milliseconds
    expirationDate.setTime(expirationDate.getTime() + 2 * 60 * 1000);

    document.cookie = `token=${token}; expires=${expirationDate.toUTCString()}; path=/`;
  };

  const dispatch = useDispatch();

  function validatePhoneNumber(input_str) {
    var re = /^[0-9]+$/;

    return re.test(input_str);
  }

  const handleOtpChange = (e) => {
    if (!validatePhoneNumber(e.target.value) && e.target.value) {
      return false;
    }
    setErrors({})

    setOtp(e.target.value);
  };

  const handleLoginChange = (e) => {
    
    if (!validatePhoneNumber(e.target.value) && e.target.value) {
      return false;
    }

    setErrors({});

    setUsername(e.target.value);

  };

  const handlePasswordChange = (e) => {
    setErrors({});
    if(e.target.name === "password"){
        setPassword(e.target.value);
    }
    if(e.target.name === "newPassword"){
    setNewPassword(e.target.value);
    }
  };

  const handleUserData = async () => {
    let user = JSON.parse(sessionStorage.getItem("token"));
    if (user === "") {
      navigate("/");
    } else {
      handleTokenExpiry(user.id_token);
      setLoading(true);
    }
  };

  const handleLogin = async (e) => {

    let error = {}
    if (username.length < 10 || isEmpty(username)) {
      error.username = "Please enter valid mobile number";
    }

    if (password === "") {
      error.password = "Please enter your password";
    } else if (password.length < 8) {

      error.password = "Password should not be less than 8";
    } 

    setErrors(error);
    
    if(isEmpty(error)){

      let body = {
        username,
        password,
        roleCode: "ROLE_ADMIN",
      };

      setLoading(true);
      getUserLogin(body).then((resp) => {
        setLoading(false);

        if (resp?.data?.id_token) {
          saveAuthToken("token", resp?.data);
          dispatch(login(resp.data));
          handleUserData();
        } else {
            if( resp?.data?.errorCodeList.length > 0){
                setErrors((prev) => ({
                    ...prev,
                    response: resp?.data?.errorCodeList[0],
                  }));

            }else{
                setErrors((prev) => ({
                    ...prev,
                    response: resp?.data?.message,
                  }));
            }
          setTimeout(() => {
            setErrors((prev) => ({
              ...prev,
              response: "",
            }));
          }, 4000);
        }
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(errors.response)) {
      setTimeout(() => {
        setErrors((prev) => ({
          ...prev,
          response: "",
        }));
      }, 5000);
    }
  }, [errors.response]);

  const handleGenerateOtp = async (e) => {
    let error = {}
    setNewPassword("");
    setConfirmPassword("");
    setEnteredOtp("");
    if (otp === "") {
      error.otp = "Please enter Mobile Number";
    } else if (otp.length < 10) {
     
      error.otp = "Please enter valid phone number"
    } 
    
    setErrors(error);
    
     if(isEmpty(error)){
      setLoading(true);
      const param = {
        mobileNumber: otp,
        roleCode: "ROLE_ADMIN",
      };
      forgetPasswordGenerateOtp(param).then((resp) => {
        setLoading(false);

        if (resp.data.success) {
          setGenerateOtp(true);
        } else {
            if( resp?.data?.errorCodeList.length > 0){
                setErrors((prev) => ({
                    ...prev,
                    response: resp?.data?.errorCodeList[0],
                  }));

            }else{
                setErrors((prev) => ({
                    ...prev,
                    response: resp?.data?.message,
                  }));
            }
          
          setTimeout(() => {
            setErrors((prev) => ({
              ...prev,
              response: "",
            }));
          }, 4000);
        }
      });

      setErrors((prev) => ({
        ...prev,
        otp: "",
      }));
    }
  };

  const handleOtpVerification = async (e) => {
    let error = {};
    if (enteredOtp === "") {
      error.enteredOtp = "Please enter OTP";
    }else if(enteredOtp.length > 6){
        error.enteredOtp = "Please enter 6 digit OTP";
    }
    
    if (newPassword === "") {
      error.newPassword = "Please enter new password";
    }else if(newPassword.length < 8){

        error.newPassword = "Password should not be less than 8";
    } 
    if(confirmPassword === ""){
        error.confirmPassword = "Please enter confirm password";
    }else if (newPassword !== confirmPassword) {
      error.confirmPassword = "Confirm password are not matched";
    }
     
    setErrors(error);
    if (isEmpty(error)) {
      let body = {
        key: enteredOtp,
        newPassword: newPassword,
        mobileNumber: otp,
        roleCode: "ROLE_ADMIN",
      };

      setLoading(true);
      LoginPasswordReset(body).then((resp) => {
        setLoading(false);

        if (resp?.data?.success) {
          Swal.fire({
            icon: "success",
            title: "Password Changed succesfully",
            //timer: 2200,
            showConfirmButton: "#3bee4a",
            customClass: "swal-wide",
            preConfirm: async () => {
              handleBackToLogin();
            },
          });
        } else {
            if( resp?.data?.errorCodeList.length > 0){
                setErrors((prev) => ({
                    ...prev,
                    response: resp?.data?.errorCodeList[0],
                  }));

            }else{
                setErrors((prev) => ({
                    ...prev,
                    response: resp?.data?.message,
                  }));
            }
        }
      });
    }
  };
  const handleTogglePassword = () => {
    if (passwordType === "password") {
      setpasswordType("text");
      return;
    }
    setpasswordType("password");
  };
  const handleBackToLogin = () => {
    setForgotPass(false);
    setOtp("");
    setUsername("");
    setPassword("");
    setGenerateOtp(false);
    setErrors("");
  };
  const handleotpChnage = (e) => {
    if (!validatePhoneNumber(e.target.value) && e.target.value) {
      return false;
    }
    setErrors({});
    setEnteredOtp(e.target.value);
  };
  const otp_timer = () => {
    const myTimeout = setTimeout(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
        otp_timer();
      } else {
        clearTimeout(myTimeout);
      }
    }, 1000);
  };
  useEffect(() => {
     if(generateOtp){
        otp_timer();
     }
  }, [generateOtp]);


  const sendAgainOtp = () => {
    setTimer(30);
    otp_timer();
    handleGenerateOtp();
  };

  return (
    <div className="Loginform">
    <h1 className="fs-24 fw-700 mb22 text-center">
      {forgotPass ? 
      <h4 className="heading">
      You Can change your <span className="strong"><br />Pay2mobile  Password 
                 </span>
                 </h4>: <h4 className="heading">
      Sign into your <span className="strong">Pay2mobile <br /> Loan Dashboard 
                 </span>
                 </h4>}
    </h1>
    {!forgotPass &&<p>It's good to have you back!</p>}
    
    <div>
    <div>
    {/* wrong user detail response  */}
    {errors.response && !forgotPass && (
      <p className="alert_danger mt-16">{errors.response}</p>
    )}

    {/* forgot password response  */}
    {errors.response && (forgotPass || generateOtp) && (
      <p className="alert_danger mt-16">{errors.response}</p>
    )}

    {forgotPass && (
      <p className="fs-16 mt-14">
        You can reset your password here.
      </p>
    )}

    {/* login fields  */}
    {!forgotPass && !generateOtp && (
      <>
        <div className="input_field">
          <label className="label">
            Mobile No<span>*</span>
          </label>
          <input
            type="text"
            name="username"
            value={username}
            placeholder="Enter Mobile number"
            onChange={handleLoginChange}
            maxLength={10}
          />
        </div>
        {errors.username && (
          <div className="error fs-12 mt-10">{errors.username}</div>
        )}

        
      </>
    )}

    {!forgotPass && !generateOtp && (
      <>
        <div className="input_field">
          <label className="label">
            Password<span>*</span>
          </label>
          <input
            type={passwordType}
            value={password}
            placeholder="Enter Password"
            // autoComplete="new-password"
            name="password"
            onChange={handlePasswordChange}
            maxLength={30}
          />
          <button
            className="eye_icon"
            onClick={handleTogglePassword}
          >
            {passwordType === "password" ? (
              <i class="fa fa-eye-slash" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-eye" aria-hidden="true"></i>
            )}
          </button>
        </div>

        {errors.password && (
          <div className="error fs-12 mt-10">{errors.password}</div>
        )}
      </>
    )}

    {/* forgot password field  */}
    {forgotPass && !generateOtp && (
      <>
        <div className="input_field mt-30">
          <div className="icon flex">
            {/* <i className="fa-solid fa-mobile-screen-button"></i> */}
            <p className="fs-14 grey_font">Mobile No.</p>
          </div>
          <input
            type="text"
            name="otp"
            value={otp}
            onChange={handleOtpChange}
            placeholder="Enter mobile number"
            maxLength={10}
          />
        </div>

        {errors.otp && (
          <p className="error fs-12 mt-10">{errors.otp}</p>
        )}
      </>
    )}

    {/* generate Otp field */}

    {generateOtp && (
      <div className="input_field otp_field mt-30">
        <p className="fs-12 grey_font">
          Enter OTP
          <span className="required">*</span>
        </p>

        <input
          type="text"
          name="enteredOtp"
          value={enteredOtp}
          onChange={handleotpChnage}
          placeholder="* * * * * *"
          style={{ paddingLeft: "10px" }}
          maxLength={6}
        />

        <div className="timer send-again-otp">
          <p>
            Didn't get otp |{" "}
            {timer > 1 ? (
              <span>Resend in: {timer}</span>
            ) : (
              <button onClick={sendAgainOtp}>Resend Otp</button>
            )}{" "}
          </p>
        </div>

        {errors.enteredOtp && (
          <div className="error fs-12 mt-10">
            {errors.enteredOtp}
          </div>
        )}

        <div
          className="input_field"
          style={{ marginTop: "15px", display: "none" }}
        >
          <div className="icon flex">
            {/* <i className="fa-solid fa-mobile-screen-button"></i> */}
            <p className="fs-14 grey_font">Mobile No.</p>
          </div>
          <input
            type="text"
            name="otp"
            value={otp}
            // onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter mobile number"
            maxLength={10}
          />
        </div>
        <div className="input_field mt-10">
          <label className="label">
            New Password<span>*</span>
          </label>
          <input
            type={passwordType}
            name="newPassword"
            value={newPassword}
            // placeholder="Enter password"
            // autoComplete="new-password"
            onChange={handlePasswordChange}
            maxLength={10}
          />
          <button
            className="eye_icon"
            onClick={handleTogglePassword}
          >
            {passwordType === "password" ? (
              <i class="fa fa-eye-slash" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-eye" aria-hidden="true"></i>
            )}
          </button>
        </div>
        {errors.newPassword && (
          <div className="error fs-12 mt-10">
            {errors.newPassword}
          </div>
        )}
        <div className="input_field">
          <label className="label">
            Confirm Password<span>*</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            // placeholder="Enter password"
            // autoComplete="new-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            maxLength={10}
          />
        </div>
        {errors.confirmPassword && (
          <div className="error fs-12 mt-10">
            {errors.confirmPassword}
          </div>
        )}
      </div>
    )}

    {!forgotPass && (
      <p
        className="forgot_pass color_blue fs-14 mt-14 pointer"
        onClick={() => setForgotPass(true)}
      >
        Forgot your password?
      </p>
    )}

    <div className="actions">
      {/* login button  */}
      {!forgotPass && !generateOtp && (
        <>
          {!loading && (
            <button
              className="login_btn mt-14 btn w-100"
              onClick={handleLogin}
            >
              Login Now
            </button>
          )}

          {loading && (
            <button
              className="login_btn btn w-100"
              onClick={handleLogin}
              disabled
            >
              {/* {loading ? <Spinner/> : "Login"} */}
              <Spinner /> Login
            </button>
          )}
        </>
      )}

      {/* generateOtp button  */}
      {forgotPass && !generateOtp && (
        <button
          className="login_btn btn mt-20 w-100"
          onClick={handleGenerateOtp}
        >
          Generate OTP
        </button>
      )}

      {generateOtp && (
        <button
          className="login_btn btn w-100 mt-20"
          onClick={handleOtpVerification}
          // onClick={() => setGenerateOtp(false)}
        >
          Submit
        </button>
      )}

      {(forgotPass || generateOtp) && (
        <p
          className="fs-14 mt-20 pointer  fw-500 hover-blue text-center"
          onClick={handleBackToLogin}
        >
          &#8592; Back To Login
        </p>
      )}

      {!forgotPass && (
        <>
          {/* <span className="sign_up_link">
            Don't have a P2M Admin account ?
            <Link to="">Join Us</Link>
          </span> */}
        </>
      )}
    </div>
   </div>
  
    </div>
   
  </div>
  );
};

export default LoginRight;
