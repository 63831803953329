import React, {useState} from 'react'
import subaamin_bg from "../../assets/images/subadmin_bg.png";
import cross from "../../assets/images/cross.png";
import { Modal } from '@material-ui/core';


export default function ClientDetails() {
 

  const [open, setOpen] = useState(false);
 
  const handleClose = () => {
      setOpen(false);
  };

  const handleOpen = () => {
      setOpen(true);
  };
  return (
    <div>
    <Modal
    onClose={handleClose}
                open={open}
                style={{
                    position: 'absolute',
                    border: '2px solid #000',
                    backgroundColor: 'gray',
                    boxShadow: '2px solid black',
                    height: 80,
                    width: 240,
                    margin: 'auto'
                }}
            >
                <h2>How are you?</h2></Modal>
                </div>
    // <div>
    //   <div className='client_main_container'>
    //     {/* <div className='client_container'>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <div className='client_label'>
    //             <label>Client Details</label>
    //           </div>

    //         </div>
    //         <div className='client_column'>
    //         </div>
    //         <div className='client_column'>
    //           <label>Apply Date</label>
    //           <label>26/05/2023</label>
    //         </div>
    //         <div className='client_column'>
    //           <label>Customer Id</label>
    //           <label>RT2523</label>
    //         </div>
    //         <div className='navbar_pic-with_right_text'>
    //           <div className='client_column'>
    //             <label>Customer Id</label>
    //             <label>RT2523</label>
    //           </div>
    //         </div>

    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <div className='client_label'>
    //             <label style={{ color: "#001A16" }}>Personal Details</label>
    //           </div>

    //         </div>
    //       </div>
    //       <div className='clientdetail_row'>
    //         <div className='clientdetail_column'>
    //           <label>UserName</label>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <input type='text'></input>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <label>Email</label>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <input type='email'></input>
    //         </div>


    //       </div>
    //       <div className='clientdetail_row'>
    //         <div className='clientdetail_column'>
    //           <label>Password</label>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <input type='text'></input>
    //         </div>
    //       </div>
    //       <div className='clientdetail_row'>
    //         <div className='clientdetail_column'>
    //           <label>Aadhar Number</label>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <input type='text'></input>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <label>Aadhar Docs</label>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <input type='text'></input>
    //         </div>


    //       </div>
    //       <div className='clientdetail_row'>
    //         <div className='clientdetail_column'>
    //           <label>Pan Number</label>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <input type='text'></input>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <label>Pan Docs</label>
    //         </div>
    //         <div className='clientdetail_column'>
    //           <input type='text'></input>
    //         </div>
    //       </div>
    //     </div> */}
    //     <div className='client_container'>
    //     <div className='client_row_header'>
    //         <div className='client_column_header'>
              
    //         </div>
    //         <div className='client_column_header'>
    //           <div className='client_details'>
    //           <img src={cross}></img>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='client_row_header'>
    //         <div className='client_column_header'>
    //           <strong>Client Details</strong>
    //         </div>
    //         <div className='client_column_header'>
    //           <div className='client_details'>
    //           <label>Apply Date</label>
    //           <label>Customer Id</label>
    //           <label>Package Type</label>
    //           </div>
    //           <div className='client_details'>
    //           <label>21/05/2022</label>
    //           <label>RT253</label>
    //           <label>RT</label>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='client_row_header'>
    //         <div className='client_column_header'>
    //           <strong>Personal Details</strong>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>User Name</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text' value="Priti Singh"></input>
    //         </div>
    //         <div className='client_column'>
    //           <label>Email</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='email' value="Singh14@gmail.com"></input>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Password</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
              
    //         </div>
    //         <div className='client_column'>
            
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Aadhar Number</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='number' value="123456789"></input>
    //         </div>
    //         <div className='client_column'>
    //           <label>Aadhar Docs</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>    
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Pan Card</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text' value="ABC12345E"></input>
    //         </div>
    //         <div className='client_column'>
    //           <label>Pan Docs</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
            
    //       </div>

    //       {/* //shop Details */}
    //       <div className='client_row_header'>
    //         <div className='client_column_header'>
    //           <strong>Shop Details</strong>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Shop Name</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
    //           <label>Shop Address</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Contact Number</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
    //         <label>Signature</label>
    //         </div>
    //         <div className='client_column'>
    //         <img></img>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>TPIN</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
              
    //         </div>
    //         <div className='client_column'>
             
    //         </div>
            
    //       </div>
          
    //       {/* Bank Details */}
    //       <div>

    //       <div className='client_row_header'>
    //         <div className='client_column_header '>
    //           <strong>Bank Details</strong>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Account Number -1</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column '>
    //           <label>IFSC Code-1</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Account Number-2</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
    //         <label>IFSC Code-2</label>
    //         </div>
    //         <div className='client_column'>
    //         <input type='text'></input>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Account Number-3</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
    //         <label>IFSC Code-3</label>
    //         </div>
    //         <div className='client_column'>
    //         <input type='text'></input>
    //         </div>
            
    //       </div>
    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Address</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
          
    //         </div>
    //         <div className='client_column'>
            
    //         </div>
            
    //       </div>

    //       {/* buttons */}

    //       <div className='client_row'>
    //         <div className='client_column'>
    //           <label>Reject Resaon</label>
    //         </div>
    //         <div className='client_column'>
    //          <input type='text'></input>
    //         </div>
    //         <div className='client_column'>
    //           <button className='btn_reject'>Reject</button>
    //         </div>
    //         <div className='client_column'>
    //          <button className='btn_submit'> Submit </button>
    //         </div>
            
    //       </div>

    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}
