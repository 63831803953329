import React from "react";
import '../../assets/styles/loader.css'

 const LocalLoader = ({className =""})=>{
    return(


        <>
        <div className={`wrapper-loader ${className}`}>
     <div class="loader"></div>
    </div>
        </>
    )
 }
 export default LocalLoader;
