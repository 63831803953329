import React, { useState } from 'react'
import Input from "../../Components/Input/Input";
import { useLocation, useNavigate } from 'react-router-dom';
import { updateServiceStatus } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import Swal from 'sweetalert2';

function EditService() {

    const INIT_ERRORS = {
        servicename: "",
        status: "",
        imageurl: "",
    };

    const location = useLocation();
    const servicelistData = location.state
   
    const [showImageInput, setShowImageInput] = useState(false);
    const [updateService, setUpdateService] = useState({ ...servicelistData });
    const [errors, setErrors] = useState(INIT_ERRORS);
    const navigate = useNavigate();


    const showImage = () => {
        setShowImageInput(!showImageInput);
    }


    const handleInput = (e) => {

    };

    const hanleInput = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;
        if((name==="serviceName") && !isEmpty(value)){
            setErrors((prev) => ({
                ...prev,
                servicename: "",
            }));
        }
        if((name==="status") && !isEmpty(value)){
            setErrors((prev) => ({
                ...prev,
                status: "",
            }));
        }
        if((name==="serviceImage") && !isEmpty(value)){
            setErrors((prev) => ({
                ...prev,
                imageurl: "",
            }));
        }
        setUpdateService({ ...updateService, [name]: value });
    }
    const updateStatus = () => {
        if (updateService.serviceName === "") {
            setErrors((prev) => ({
                ...prev,
                servicename: "Service Name can't be Null",
            }));
        }
        if (updateService.status === "") {
            setErrors((prev) => ({
                ...prev,
                status: "Service status can't be Null",
            }));
        }
        if (updateService.serviceImage === "") {
            setErrors((prev) => ({
                ...prev,
                imageurl: "Service Image URL can't be Null",
            }));
        }

        const param = {
            createdAt: "2023-08-23T07:28:33.112Z",
            createdBy: "string",
            id: "string",
            isDeleted: true,
            isPublished: true,
            isStatus: true,
            serviceCode: "string",
            serviceImage: "string",
            serviceName: "string",
            serviceSku: "string",
            updatedAt: "2023-08-23T07:28:33.112Z",
            updatedBy: "string"
        }

        updateServiceStatus(param).then((resp) => {

            if (resp?.data?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Service Updated Succesfully",
                    timer: 1200,
                    showConfirmButton: false,
                    customClass: 'swal-wide',
                });
                navigate('/serviceList')
            }
            else {

            }

        })
    }

    return (
        <div className='edit_operator_list_main_container'>
            <div className='edit_operator_heading'>
                <label>Edit Service List</label>
            </div>
            <div className='edit_operator_container'>
                <div className='edit_operator'>
                    <div className='edit_operator_left_container'>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <text>Service Information</text>
                                </div>
                            </div>
                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Service Name <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='text'
                                        placeholder='please enter service name'
                                        name='serviceName'
                                        value={updateService.serviceName}
                                        onChange={hanleInput}
                                    ></input>
                                    {errors.servicename && (
                                        <p className="error fs-12 mt-10">
                                            {errors.servicename}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Service Code <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='text'
                                        placeholder='please enter your first name'
                                        value={updateService.serviceSku}>

                                    </input>
                                </div>
                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Status<span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='text'
                                        name='status'
                                        placeholder='please update status'
                                        value={updateService.status?"Active":"InActive"}
                                        onChange={hanleInput}>
                                    </input>
                                    {errors.status && (
                                        <p className="error fs-12 mt-10">
                                            {errors.status}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Service_Image<span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row' style={{ display: "flex" }}>

                                    <img
                                        style={{ maxWidth: "30px" }}
                                        src={servicelistData.serviceImage}
                                        onClick={() => showImage()}

                                    ></img>

                                    {showImageInput &&
                                        <input
                                            type='file'
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={handleInput}
                                        ></input>
                                    }
                                    {errors.imageurl && (
                                        <p className="error fs-12 mt-10">
                                            {errors.imageurl}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <button
                                        onClick={updateStatus}>Update Service</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EditService
