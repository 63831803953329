import React from "react";
import { BsUiRadios } from "react-icons/bs";
import './style.css'
import '../NewMember/RoleManagmentAndPermission/style.scss'
import uploadImage from '../../assets/images/uploadImage.png'

const CreateTicket =()=>{
    
    
return(
        <>
         <div className='subadmin_main_container'>
            <div className='subadmin_head flex space-between' style={{width:"97%"}}>
                <strong>Ticket</strong>

                
                <div>
                <button className="search_btn" style={{width:"136px"}}>Download CSV</button>
                </div>
                 
            
            </div>
          
          
          
          
            <div className='subadmin_container' style={{flexDirection:"column"}}>
                <div className='subadmin_col_container mg_spacing' style={{display: "inherit" }}>
                    <h3>Create Ticket</h3>
                    <div className="form-container mg_top21 flex">
                    <div className="left_container">
                        <div className="form_content flex ">
                        <div>
                        <lable className="filter_text fs">Category</lable>
                        <div className="mg-top3">
                          <select className="filter_select  fs input_padding">
                            <option>Transaction</option>
                          </select>
                         </div>
                    </div>
                   
                    <div>
                    <lable  className="filter_text fs">Request Type</lable>
                        
                        <div className="mg-top3">
                         <select className="filter_select fs input_padding">
                           <option>Issue</option>
                         </select>
                        </div>

                    </div>
                        
                        </div>
                        <div className="form_content flex mg_top21 ">
                        <div>
                        <lable className="filter_text fs">Select Assignee</lable>
                        <div className="mg-top3">
                          <select className="filter_select fs input_padding">
                            <option>Rahul Dev</option>
                          </select>
                         </div>
                    </div>
                   
                    <div>
                    <lable className="filter_text fs">Priority</lable>
                        
                        <div className="mg-top3">
                         <select className="filter_select fs input_padding">
                           <option>Priority</option>
                         </select>
                        </div>

                    </div>
                        
                        </div>

                        <div className="mg_top21">
                            <label className="fs">Discription</label>
                <div className="mg-top3">
                <textarea className="plane_text input_padding fs" placeholder="Type here..."></textarea>
                    </div>
                        </div>
                       
                        <div className="form_content mg_top21 flex ">
                        <div>
                        <lable className="filter_text fs">Ticket Status</lable>
                        <div className="mg-top3">
                          <select className="filter_select fs input_padding">
                            <option>Open</option>
                          </select>
                         </div>
                    </div>
                   
                    <div>
                        <lable className="filter_text fs">Image</lable>
                        
                        <div className="filter_select mg-top3 position-rl">
                        <input style={{opacity:"0"}} id ="input" type="file"  placeholder="upload image" />
                            <label htmlFor="input" style={{left:"5px", top:"0"}} className="input_padding fs position_abs ">upload File</label>
                            <img className="position_abs" src={uploadImage}></img>
                            
                        
                        
                        </div>

                        </div>
                        
                </div>

                         <div>
                         <button style={{width:"250px", height:"45px" ,marginTop:"38px"}} className="userlist_btn">Submit</button>
                         </div>
                    
                    </div>
                    <div className="rigth_container">
                        <div className="form_content flex ">
                        <div className="mg-top3">
                        <lable className="filter_text fs">Requester Name</lable>
                        <div>
                          <input type="text" className="filter_select fs input_padding">
                          </input>
                         </div>
                    </div>
                   
                    <div>
                    <lable className="filter_text fs">Email ID</lable>
                        
                        <div className="mg-top3">
                         <input type="email" className="filter_select fs input_padding">
                         </input>
                        </div>

                    </div>
                        </div>
                        <div className="mg_top21">
                <label className="fs">Summary</label>
                <div className="mg-top3">
                <textarea className="plane_text fs input_padding" style={{height:"35px"}} placeholder="Type here..."></textarea>
                    </div>
                        </div>
                        
                        <div className="mg_top21">
                <label className="fs">Remark</label>
                <div className="mg-top3">
                <textarea className="plane_text fs input_padding" style={{height:"35px"}} placeholder="Type here..."></textarea>
                    </div>
                        </div>
                        

                    </div>
                     
                    </div>
                    
                    
                </div>
            </div>

        </div>
        </>

    )
}
export default CreateTicket;