import React, { memo } from "react";
import { ModalWrapper } from "../../Components/Modal/Modal";
import { useState } from "react";
import { isEmpty } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";



const AddNewQuestion = memo(({
    setPopUpShows
}) => {
    const closePopUpHandler = () => {
        setPopUpShows(false)
    }
    const INIT_VALUES = {
        category: "",
        topic: '',
        userType: '',
        question: "",
        answer: '',
        imageIcon: null,
    }
    const INIT_ERROR = {
        category: "",
        topic: '',
        usertype: '',
        question: "",
        answer: '',
        image: '',

    }
    const [newQuestion, setNewQuestion] = useState(INIT_VALUES);
    const [error, setError] = useState(INIT_ERROR);
    const [previewImage, setPreviewImage] = useState(null);
    const [filename, setFilename] = useState("");


    const handleInput = (e) => {
        let name, value;
        name = e.target.name;
        value = e.target.value;

        if ((name === "category") && !isEmpty(value)) {
            setError((prev) => ({
                ...prev,
                category: ""
            }))
        }
        if ((name === "topic") && !isEmpty(value)) {
            setError((prev) => ({
                ...prev,
                topic: ""
            }))
        }
        if ((name === "userType") && !isEmpty(value)) {
            setError((prev) => ({
                ...prev,
                usertype: ""
            }))
        }
        if ((name === "question") && !isEmpty(value)) {
            setError((prev) => ({
                ...prev,
                question: ""
            }))
        }
        if ((name === "answer") && !isEmpty(value)) {
            setError((prev) => ({
                ...prev,
                answer: ""
            }))
        }
      
        if ((name === "imageIcon") && !isEmpty(value)) {
            setError((prev) => ({
                ...prev,
                image: ""
            }))
        }
        setNewQuestion({ ...newQuestion, [name]: value });
        
        

    }

    const handelImage=(e)=>{   
    
       
      if(e.target.files.length > 0){
      
        setFilename(e.target.files[0])
        //setNewQuestion({ ...newQuestion, imageIcon: filename});
        setError((prev) => ({
            ...prev,
            image: ""
        }))
     if (e.target.files[0] ) {

       const reader = new FileReader();
 
       reader.onload = (e) => {
         setPreviewImage(e.target.result);
       };
 
       reader.readAsDataURL(e.target.files[0] );
     }
      }
      else{
        
      }
        
    }

    const submitQuestion = () => {
  
        var arr = [];

        if (newQuestion.category === "") {
            setError((prev) => ({
                ...prev,
                category: "Please Select Category"
            }));
            arr.push("aa")
        }
        if (newQuestion.topic === "") {
            setError((prev) => ({
                ...prev,
                topic: "Please Select Topic"
            }));
            arr.push("aa")
        }
        if (newQuestion.userType === "") {
            setError((prev) => ({
                ...prev,
                usertype: "Please Select Category"
            }));
            arr.push("aa")
        }
        if (newQuestion.question === "") {
            setError((prev) => ({
                ...prev,
                question: "Question can't be null"
            }));
            arr.push("aa")
        }
        if (newQuestion.answer === "") {
            setError((prev) => ({
                ...prev,
                answer: "Answer can't be null"
            }));
            arr.push("aa")
        }
        if (isEmpty(filename?.name)) {
           
            setError((prev) => ({
                ...prev,
                image: "please select image"
            }));
            arr.push("aa")
        }else{
            newQuestion.imageIcon = filename
        }


        if(isEmpty(arr)){
            Swal.fire({
                icon: "success",
                title: "New Question is added succesfully",
                timer: 1200,
                showConfirmButton: false,
                customClass: 'swal-wide',
            });
            setPopUpShows(false)
        }
        
    }

    return (
        <ModalWrapper>
            <div className="modal-dialog modal" style={{ width: '100%' }} role="document">
                <div className="modal-content bg_image">
                    <div>
                        <div className="modal-header flex justify-between item-center">
                            <label className="modal-title" id="exampleModalLabel">
                                Add New Question
                            </label>
                            <span aria-hidden="true">
                                <i className="fa fa-times" onClick={closePopUpHandler} aria-hidden="true"
                                    style={{ color: "#00286F", height: "34px", width: "34px", fontSize: "34px" }}></i>
                            </span>
                        </div>
                        <div className='add_merchant'>
                            <div className='add_merchant_container'>

                                <div className='add_merchant_flex'>
                                    <div className="left">
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Category <span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'>
                                                <select name="category" onChange={handleInput}>
                                                    <option>select</option>
                                                    <option>Transaction</option>
                                                </select>
                                                {error.category && (
                                                    <p className="error fs-12 mt-10">
                                                        {error.category}
                                                    </p>
                                                )}
                                            </div>

                                        </div>
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>select User Type<span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row' >
                                                <select
                                                    name="userType"
                                                    onChange={handleInput}>
                                                    <option>select</option>
                                                    <option>Retailer</option>
                                                    <option>Agent</option>
                                                </select>
                                                {error.usertype && (
                                                    <p className="error fs-12 mt-10">
                                                        {error.usertype}
                                                    </p>
                                                )}
                                            </div>

                                        </div>
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Select Topic<span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'  >
                                                <select name="topic"
                                                    onChange={handleInput}>
                                                    <option value="">select Topic</option>
                                                    <option value="Transactions"> Transaction</option>
                                                </select>
                                                {error.topic && (
                                                    <p className="error fs-12 mt-10">
                                                        {error.topic}
                                                    </p>
                                                )}
                                            </div>

                                        </div>

                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Select Image<span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row' >
                                                <input type="file" style={{width:'250px',backgroundColor:"white"}}
                                                    name="imageIcon"                      
                                                    accept="image/png, image/jpeg"
                                                    onChange={handelImage}
                                                ></input>
                                                {previewImage && (
                                                   <div className="preview">
                                                     <img style={{marginTop:"5px",width:"250px",height:"150px",marginBottom:"-30px"}}
                                                        src={previewImage}
                                                        alt="Preview"
                                                        
                                                    />
                                                   </div>
                                                )}
                                                {error.image && (
                                                    <p className="error fs-12 mt-10">
                                                        {error.image}
                                                    </p>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Question<span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'>
                                                <input
                                                    type="text"
                                                    placeholder="type here..."
                                                    name="question"
                                                    value={newQuestion.question}
                                                    onChange={handleInput}>
                                                </input>
                                                {error.question && (
                                                    <p className="error fs-12 mt-10">
                                                        {error.question}
                                                    </p>
                                                )}
                                            </div>

                                        </div>

                                        <div className='add_merchant_data'>
                                            <div className='add_merchant_row'>
                                                <label>Answer <span>*</span></label>
                                            </div>
                                            <div className='add_merchant_row'>
                                                <textarea placeholder="type here....."
                                                    name="answer"
                                                    value={newQuestion.answer}
                                                    onChange={handleInput}
                                                ></textarea>
                                                {error.answer && (
                                                    <p className="error fs-12 mt-10">
                                                        {error.answer}
                                                    </p>
                                                )}
                                            </div>

                                        </div>
                                    </div>


                                </div>

                                <div className='add_merchant_flex'>
                                    <div className='add_merchant_data'>
                                        <div className='add_merchant_row'>
                                            <button style={{ width: "300px" }}
                                                onClick={submitQuestion}>Submit</button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </ModalWrapper>


    );
}
)
export default AddNewQuestion;