import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import "../NewMember/style/style.scss";


export default function AddNewMember() {


  const InitialValues = {
    role: "Retailer",
    userName: "",
    firstName: "",
    lastName: "",
    dob: "",
    password: "",
    phoneNumber: "",
    email: "",
    address1: "",
    address2: "",
    landmark: "",
    pincode: "",
    langKey: "system",
    authorities: [],
    tenantId: 0,
    qrCodeId: "",
  }
  const INIT_ERRORS = {
    role: "Retailer",
    packagetype: "",
    username: "",
    firstname: "",
    lastname: "",
    dob: "",
    password: "",
    phonenumber: "",
    email: "",
    address1: "",
    address2: "",
    state: "",
    country: "",
    pincode: "",
  }

  const [userData, setUserData] = useState(InitialValues);
  const [errors, setErrors] = useState(INIT_ERRORS);
  const [values,setValues] = useState("");
  
  const navigate = useNavigate()


  const handleUserDetail = async (e) => {
    let regemail = new RegExp(/^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/);

    if (userData.role === "") {
      setErrors((prev) => ({
        ...prev,
        role: "Member Type can't be null",
      }));
    }
    
    if (userData.userName === "") {
      setErrors((prev) => ({
        ...prev,
        username: "please enter contact number",
      }));
    }
    else if (userData.userName.length < 10) {
      setErrors((prev) => ({
        ...prev,
        username: "userName can't be less than 10 digits",
      }))
    }
    if (userData.firstName === "") {
      setErrors((prev) => ({
        ...prev,
        firstname: "please enter your First Name",
      }));
    }
    if (userData.lastName === "") {
      setErrors((prev) => ({
        ...prev,
        lastname: "please enter your Last Name",
      }));
    }
    if (userData.dob === "") {
      setErrors((prev) => ({
        ...prev,
        dob: "please enter your dob",
      }));
    }
    if (userData.password === "") {
      setErrors((prev) => ({
        ...prev,
        password: "please enter your password",
      }));
    }
    else if (userData.password.length < 5) {
      setErrors((prev) => ({
        ...prev,
        password: "password can't be less than 5",
      }));
    }
    if (userData.phoneNumber === "") {
      setErrors((prev) => ({
        ...prev,
        phonenumber: "please enter your phonenumber",
      }));
    }
    else if (userData.phoneNumber.length < 10) {
      setErrors((prev) => ({
        ...prev,
        phonenumber: "phonenumber can't be less than 10",
      }));
    }
    if (userData.email === "") {
      setErrors((prev) => ({
        ...prev,
        email: "please enter email address",
      }));
    }
    else if (regemail.test(userData.email) != true) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter valid email id"
      }))
    }
    if (userData.address1 === "") {
      setErrors((prev) => ({
        ...prev,
        address1: "please enter your address",
      }));
    }
    else if (userData.address1.length < 5) {
      setErrors((prev) => ({
        ...prev,
        address1: "address can't be less than 5 letters",
      }));
    }
    if (userData.address2 === "") {
      setErrors((prev) => ({
        ...prev,
        address2: "please enter your city",
      }));
    }
    if (userData.State === "") {
      setErrors((prev) => ({
        ...prev,
        state: "please select state",
      }));
    }
    if (userData.Country === "") {
      setErrors((prev) => ({
        ...prev,
        country: "please select country",
      }));
    }
    if (userData.pincode === "") {
      setErrors((prev) => ({
        ...prev,
        pincode: "please enter pincode",
      }));
    }
    else if (userData.pincode.length < 6) {
      setErrors((prev) => ({
        ...prev,
        pincode: "pincode can't be less than 6 digits",
      }));
    }
    else {

      let dob1 = new Date(userData.dob)
      dob1 = [dob1.getDate(), dob1.getMonth() + 1, dob1.getFullYear()]
        .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
      userData.dob = dob1;
      let body = userData;
      
      let user = JSON.parse(sessionStorage.getItem("token"));
      let response = await axios({
        method: "POST",
        url: `users`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.id_token,
        },
        data: body,
      });

     
      if (response.data.success === true) {
        Swal.fire({
          icon: "success",
          title: "User Created Successfully",
          timer: 1200,
          showConfirmButton: false,
        });
        navigate('/bankdetails', { state: userData })
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.msg,
          confirmButtonColor: '#ED1C24',
        })
      }
    }
  }

  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    if(name = "password"){
      if(value.length < 9)
      return;
    }


    setUserData({ ...userData, [name]: value });
  };
  // disable future Dates

  const disableFutureDate = () => {
    let today, date1;
    today = new Date();
    date1 = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
      .map(n => n < 10 ? `0${n}` : `${n}`).join('-');
  
    return date1
  }
  return (

    <div className='head_container'>
      <div className='header1'>
        <strong>Add New Member</strong>
      </div>
      <div className='member_container'>
        <div className='member_col_container'>
          <div className='member_container_item_left'>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <strong>Personal Details</strong>
                </div>
              </div>
            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Member Type <span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <select>
                    <option>Retailer</option>
                  </select>
                </div>
              </div>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Package Type<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <select>
                    <option>ABC</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Member Info */}

            <div className='flex_member' style={{ marginTop: "2%" }}>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <strong >Member Info <label>Detail fill as per your PAN card and Aadhar card</label></strong>
                </div>
              </div>
            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>UserName<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='text'
                    placeholder='please enter your username' 
                    
                    maxlength={10}
                    pattern='0-9'
                    name="userName"
                    value={userData.userName}
                    onChange={handleInput}

                  ></input>
                  {errors.username && (
                    <p className="error fs-12 mt-10">
                      {errors.username}
                    </p>
                  )}
                </div>
              </div>
              <div className='flex_item'>
                <div className='flex_item_row'>
                </div>
                <div className='flex_item_row'>
                </div>
              </div>
            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>First Name<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='text' placeholder='please enter your first name'
                    name="firstName"
                    value={userData.firstName}
                    onChange={handleInput}
                    maxLength={20}
                  ></input>
                  {errors.firstname && (
                    <p className="error fs-12 mt-10">
                      {errors.firstname}
                    </p>
                  )}
                </div>
              </div>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Last Name<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='text' placeholder='please enter your last name'
                    name="lastName"
                    value={userData.lastName}
                    onChange={handleInput}
                    maxLength={20}
                  ></input>
                  {errors.lastname && (
                    <p className="error fs-12 mt-10">
                      {errors.lastname}
                    </p>
                  )}

                </div>
              </div>
            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>DOB<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='date' placeholder='please enter your date of birth'
                    name="dob"
                    value={userData.dob}
                    onChange={handleInput}
                    max={disableFutureDate()}
                  ></input>
                  {errors.dob && (
                    <p className="error fs-12 mt-10">
                      {errors.dob}
                    </p>
                  )}
                </div>
              </div>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Password<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                <input
                 type= "password"
                  value={userData.password}
                 placeholder="Enter Password"
                // autoComplete="new-password"
                  onChange={handleInput}
                    maxLength={10}
                                        />

                  {errors.password && (
                    <p className="error fs-12 mt-10">
                      {errors.password}
                    </p>
                  )}

                </div>
              </div>
            </div>

            {/* Contact Information */}

            <div className='flex_member' style={{ marginTop: "2%" }}>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <strong>Contact Info.</strong>
                </div>
              </div>
            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Contact No.<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='text'
                    placeholder='please enter your phonenumber'
                    maxLength={10}
                    name="phoneNumber"
                    value={userData.phoneNumber}
                    onChange={handleInput}
                  ></input>
                  {errors.phonenumber && (
                    <p className="error fs-12 mt-10">
                      {errors.phonenumber}
                    </p>
                  )}
                </div>
              </div>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Email<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='email'
                    placeholder="enter your email"
                    name="email"
                    value={userData.email}
                    onChange={handleInput}
                
                  ></input>
                  {errors.email && (
                    <p className="error fs-12 mt-10">
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>
            </div>


            {/* AddressInfo */}


            <div className='flex_member' style={{ marginTop: "2%" }}>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <strong>Address Info</strong>
                </div>
              </div>
            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Address<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='text' placeholder='please enter address'
                    name='address1'
                    value={userData.address1}
                    onChange={handleInput}
                  ></input>
                  {errors.address1 && (
                    <p className="error fs-12 mt-10">
                      {errors.address1}
                    </p>
                  )}
                </div>
              </div>

            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>City<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='text' placeholder='please enter your city name'
                    name="address2"
                    value={userData.address2}
                    onChange={handleInput}
                  ></input>
                </div>
              </div>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>State<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <select>
                    <option>select</option>
                    <option>Bihar</option>
                    <option>Gurgaon</option>
                    <option>Delhi</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Postal Code<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <input type='text' placeholder='please enter your pincode'
                    name="pincode"
                    maxLength={6}
                    value={userData.pincode}
                    onChange={handleInput}
                  ></input>
                  {errors.pincode && (
                    <p className="error fs-12 mt-10">
                      {errors.pincode}
                    </p>
                  )}
                </div>
              </div>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <label>Country<span>*</span></label>
                </div>
                <div className='flex_item_row'>
                  <select>
                    <option>India</option>
                    <option>USA</option>

                  </select>
                </div>
              </div>
            </div>


            {/* Button */}

            <div className='flex_member'>
              <div className='flex_item'>
                <div className='flex_item_row'>
                  <button className='reset_btn'>Reset</button>
                  <button className='submit_btn' onClick={() => {
                    navigate(handleUserDetail())
                  }}>Submit</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    // <div className='head_container'>
    //   <div className='header1'>
    //     <strong>Add New Member</strong>
    //   </div>
    //   <div className='member_container'>
    //     <div className='member_col_container'>
    //       <div className='member_container_item_left'>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <strong>Personal Details</strong>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Member Type <span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <select>
    //                 <option>Retailer</option>
    //               </select>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Package Type<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <select>
    //                 <option>ABC</option>
    //               </select>
    //             </div>
    //           </div>
    //         </div>

    //         {/* Member Info */}

    //         <div className='flex_member' style={{ marginTop: "2%" }}>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <strong >Member Info <label>Detail fill as per your PAN card and Aadhar card</label></strong>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>First Name<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Last Name<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>

    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Aadhar Number<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Pan Number<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>

    //             </div>
    //           </div>
    //         </div>


    //         {/* AddressInfo */}


    //         <div className='flex_member' style={{ marginTop: "2%" }}>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <strong>Address Info</strong>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Address<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>

    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>City<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>State<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <select>
    //                 <option>Bihar</option>
    //                 <option>Gurgaon</option>
    //                 <option>Delhi</option>
    //               </select>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Postal Code<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Country<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <select>
    //                 <option>India</option>
    //                 <option>USA</option>

    //               </select>
    //             </div>
    //           </div>
    //         </div>


    //         {/* Button */}

    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <button className='reset_btn'>Reset</button>
    //               <button className='submit_btn'>Submit</button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='member_container_item_right'>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <strong>Contact Details</strong>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Email<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='email'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>contact number<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='number'></input>
    //             </div>
    //           </div>
    //         </div>

    //         {/* Business Info */}

    //         <div className='flex_member' style={{ marginTop: "2%" }}>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <strong>Business Info</strong>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Shop Name<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Acc Number<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='number'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>IFSC<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>

    //             </div>
    //           </div>
    //         </div>


    //         {/* Extra Account Info */}


    //         <div className='flex_member' style={{ marginTop: "2%" }}>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <strong>Extra Account Info</strong>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Acc Number-1<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='number'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>IFSC-1<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='flex_member'>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>Acc Number-2<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='number'></input>
    //             </div>
    //           </div>
    //           <div className='flex_item'>
    //             <div className='flex_item_row'>
    //               <label>IFSC-2<span>*</span></label>
    //             </div>
    //             <div className='flex_item_row'>
    //               <input type='text'></input>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

  )
}
