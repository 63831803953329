import React from 'react'

export default function FreeMemberIdPlan() {
    return (
        <div className='subadmin_main_container'>
            <div className='subadmin_head'>
                <strong>Free Member Id Plan</strong>
            </div>
            <div className='subadmin_container'>
                <div className='subadmin_col_container'>
                    <div className='subadmin_left_container'>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <strong>Member Information</strong>
                                </div>
                            </div>
                        </div>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <label>Select Memebr<span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                    <select>
                                        <option>Retailer</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <label>Select Member Type<span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                <select>
                    <option>Retailer</option>
                  </select>
                                </div>
                            </div>
                        </div>
                        <div className='flex_subadmin' style={{ marginTop: "8%" }}>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <button>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='subadmin_right_container'>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <strong>Contact Information</strong>
                                </div>
                            </div>
                        </div>
                        <div className='flex_subadmin'>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <label>Credit & Debit <span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                <select>
                    <option>Credit</option>
                    <option>Debit</option>
                  </select>
                                </div>
                            </div>
                            <div className='flex_subadmin_item'>
                                <div className='flex_subadmin_row'>
                                    <label>Number of Id's<span>*</span></label>
                                </div>
                                <div className='flex_subadmin_row'>
                                    <input type='number' placeholder='please enter your contact number'></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
