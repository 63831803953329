import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from '../../utils/common';
import Swal from 'sweetalert2';

function AddNewService() {

    const initialValues = {
        serviceName: "",
        serviceCode: "",
        status: "",
        imageUrl: "",
    };

    const INIT_ERRORS = {
        servicename: "",
        servicecode: "",
        status: "",
        imageurl: "",
    };

    const [newService, setNewService] = useState(initialValues);
    const [error, setError] = useState(INIT_ERRORS);
    const navigate = useNavigate()

    const handleInput=(e)=>{
        let name, value;
        name = e.target.name;
        value = e.target.value;
      
        if((name==="serviceName") && !isEmpty(value)){
            setError((prev) => ({
                ...prev,
                servicename: ""
            }))
        }
        else if((name==="serviceCode") && !isEmpty(value)){
            setError((prev) => ({
                ...prev,
                servicecode: ""
            }))
        }
        else if((name==="status") && !isEmpty(value)){
            setError((prev) => ({
                ...prev,
                status: ""
            }))
        }
        if((name==="imageUrl") && !isEmpty(value)){
            setError((prev) => ({
                ...prev,
                imageurl: ""
            }))
        }
        setNewService({ ...newService, [name]: value }); 
    }

    const newServiceData=()=>{
        if(newService.serviceName===""){
            setError((prev) => ({
                ...prev,
                servicename: "Service name can't be Null",
            }));
        }
        if(newService.serviceCode===""){
            setError((prev) => ({
                ...prev,
                servicecode: "Service code can't be Null",
            }));
        }
        if(newService.status===""){
            setError((prev) => ({
                ...prev,
                status: "Service code can't be Null",
            }));
        }
        if(newService.imageUrl===""){
            setError((prev) => ({
                ...prev,
                imageurl: "Service Image Url can't be Null",
            }));
        }

        else{
            Swal.fire({
                icon: "success",
                title: "New Service added succesfully",
                timer: 1200,
                showConfirmButton: false,
                customClass: 'swal-wide',
                
            });
            navigate("/serviceList")
        }
    }
    return (
        <div className='edit_operator_list_main_container'>
            <div className='edit_operator_heading'>
                <label>Add New Service</label>
            </div>
            <div className='edit_operator_container'>
                <div className='edit_operator'>
                    <div className='edit_operator_left_container'>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <text>Service Information</text>
                                </div>
                            </div>
                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Service Name <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='text'
                                        placeholder='please enter service name'
                                        name='serviceName'
                                        value={newService.serviceName}
                                        onChange={handleInput}
                                    ></input>
                                    {error.servicename && (
                                        <p className="error fs-12 mt-10">
                                            {error.servicename}
                                        </p>
                                    )}
                                </div>

                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Service Code <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='text'
                                        placeholder='please enter service code'
                                        name='serviceCode'
                                        value={newService.serviceCode}
                                        onChange={handleInput}
                                    >
                                    </input>
                                    {error.servicecode && (
                                        <p className="error fs-12 mt-10">
                                            {error.servicecode}
                                        </p>
                                    )}
                                </div>

                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Status<span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='text'
                                        placeholder='Please enter status'
                                        name='status'
                                        value={newService.status}
                                        onChange={handleInput}
                                    ></input>
                                    {error.status && (
                                        <p className="error fs-12 mt-10">
                                            {error.status}
                                        </p>
                                    )}
                                </div>

                            </div>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <label>Service Image <span>*</span></label>
                                </div>
                                <div className='flex_edit_operator_row'>
                                    <input type='file'
                                    accept="image/png, image/gif, image/jpeg"
                                        placeholder='please enter service image url'
                                        name='imageUrl'
                                        value={newService.imageUrl}
                                        onChange={handleInput}
                                    ></input>
                                    {error.imageurl && (
                                        <p className="error fs-12 mt-10">
                                            {error.imageurl}
                                        </p>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className='edit_operator_flex'>
                            <div className='flex_edit_operaor'>
                                <div className='flex_edit_operator_row'>
                                    <button
                                    onClick={newServiceData}>Add Service</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewService
