import styled from 'styled-components';

export const AlertWrapper = styled.div`
	padding: 10px 25px 10px 10px;
	position: relative;
	margin-bottom: 10px;
	border: 1px solid transparent;
	border-radius: 5px;

	&.error {
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1;
	}

	&.success {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
	}
	&.globle {
		position: fixed;
		//width: 50%;
		max-width: 70%;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		z-index: 11111;
		font-size: 14px;
		animation: mymove 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

		.close {
			font-size: 18px;
			position: absolute;
			top: 50%;
			right: 6px;
			transform: translateY(-50%);
		}
	}
	&.error.globle {
		background-color: #fee;
		border: 1px solid  #ffb8b8;
		color: #a66;
	}
	&.success.globle {
		background-color: #efe;
		border: 1px solid #ded;
		color: #9a9;
	}
	.close {
		position: absolute;
		top: 50%;
		right: 6px;
		transform: translateY(-50%);
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		filter: alpha(opacity=20);
		opacity: 0.6;
		&:hover {
			color: #000;
			text-decoration: none;
			cursor: pointer;
			filter: alpha(opacity=50);
			opacity: 0.5;
		}
	}
	@keyframes mymove {
		from {
			top: 0px;
		}
		to {
			top: 50px;
		}
	}
	@media only screen and (max-width: 767px) {
		&.globle {
			width: calc(100% - 50px);
		}
	}
`;