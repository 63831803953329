import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams ,useLocation} from "react-router-dom";
import EditUserForm from "./EditUserForm";
import { updateUserDaital } from "../../utils/api";

const EditUser = (props) => {
    const navigate = useNavigate();
    const location=useLocation()
    const item =location.state;


    const INIT_ERRORS = {
        role: "",
        address1: "",
        dob: "",
        phoneNumber: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        pincode: "",
        userName: "",
    };

    const [userData, setUserData] = useState({...item});
    const [errors, setErrors] = useState(INIT_ERRORS);

    const handleFormData = async (e) => {
        
        if (userData.address1 === "") {
            setErrors((prev) => ({
                ...prev,
                address1: "please enter your address",
            }));
        }
        else if(userData.address1.length<5){
            setErrors((prev) => ({
                ...prev,
                address1: "address can't be less than 5 letters",
            }));
        }
        if (userData.role === "") {
            setErrors((prev) => ({
                ...prev,
                role: "",
            }));
        }
        if (userData.langKey === "") {
            setErrors((prev) => ({
                ...prev,
                langKey: "",
            }));
        }
        if (errors.dob === "") {
            setErrors((prev) => ({
                ...prev,
                dob: "please enter your date of birth",
            }));
        }
        if (userData.phoneNumber === "") {
            setErrors((prev) => ({
                ...prev,
                phoneNumber: "please enter your phone number",
            }));
        }
        if (userData.email === "") {
            setErrors((prev) => ({
                ...prev,
                email: "please enter your email",
            }));
        }
        if (userData.firstName === "") {
            setErrors((prev) => ({
                ...prev,
                firstName: "please enter your first name",
            }));
        }
        if (userData.lastName === "") {
            setErrors((prev) => ({
                ...prev,
                lastName: "please enter your last name",
            }));
        }
        if (userData.password === "") {
            setErrors((prev) => ({
                ...prev,
                password: "please enter your password",
            }));
        }
        if (userData.pincode === "") {
            setErrors((prev) => ({
                ...prev,
                pincode: "please enter your pincode",
            }));
        }
        else if(userData.pincode.length<6){
            setErrors((prev) => ({
                ...prev,
                pincode: "pincode can't be less than 6",
            }));
        }
        if (userData.userName === "") {
            setErrors((prev) => ({
                ...prev,
                userName: "please enter your username",
            }));
        } else if (userData.userName.length < 10) {
            setErrors((prev) => ({
                ...prev,
                userName: "Username can't be less than 10 words",
            }));
        } else {
            let params = userData;
           
           

            updateUserDaital(params).then((response) =>{
                if (response?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        title: "User updated Successfully",
                        timer: 2200,
                        showConfirmButton: "#3bee4a",
                    });
                    navigate("/usersList");
                } else {
                    let msg = "";
                    if (response?.data?.errorCodeList.length > 0) {
                      msg = response?.data?.errorCodeList[0];
                    } else {
                      msg = response?.data?.msg;
                    }
                    Swal.fire({
                        icon: "error",
                        text: msg,
                        confirmButtonColor: "#ED1C24",
                        timer: 2200,
                    });
                }
                
            })
           
        }
    };

    let name, value;
    const handleInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    };
    return (
        <EditUserForm
            handleInput={handleInput}
            userData={userData}
            errors={errors}
            setErrors={setErrors}
            handleFormData={handleFormData}
            
        />
    );
};

export default EditUser;