import React from 'react'
import ServiceTable from './ServiceTable'
import  "../ServiceList/styles/dropdown.scss"

const Services = () => {
  return (
    <div className="transactions_container">
        <ServiceTable/>
    </div>
  )
}

export default Services