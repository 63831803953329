
import { AnimatePresence, motion} from "framer-motion";
import React, {useEffect} from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";


const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    x: "-100%",
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
  show: (i) => ({
    x: 0,
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
};

const SidebarMenu = ({
  route,showAnimation,
  isOpen,setIsOpen,
  isMenuOpen, setIsMenuOpen,permissionList}) => {
 
  const toggleMenu = (e,id) => {
    if(isMenuOpen === id){
      setIsMenuOpen("");
    }else{
      setIsMenuOpen(id);
    }
  
    setIsOpen(true);
  };
  
  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);  
    }
  }, [isOpen]);

  
  let num = 99
  const getRole = sessionStorage.getItem("token");
  const role = JSON.parse(getRole).authorities;
 
  return (
    <>
      <div  className={`menu submenu ${!isOpen && "center"} ${(isMenuOpen === route.id)? "submenu-active":""}`}
       onClick={(e) =>toggleMenu (e,route.id)}
       >


        {/* Menu..... */}
       <div className="menu_item">
          <div className="icon pointer">{route.icon}</div>
          <AnimatePresence>
            {isOpen && (
              <motion.aside
              initial={{ width: 0 }}
              animate={{
                width: 300
              }}
              exit={{
                width: 0,
                transition: { delay: 0.7, duration: 0.3 }
              }}
            >
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="link_text"
              >
                {route.name}
              </motion.div>
              </motion.aside>
            )}
          </AnimatePresence>
        </div>
        
        
        
        {isOpen && (
          <motion.div
          className="pointer"
            animate={
              isMenuOpen
                ? {
                    rotate: 0,
                  }
                : { rotate: -90 }
            }
          >
            <FaAngleDown />
          </motion.div>
        )}
      </div>
     
     
     
     
     {/* subMenu */}
      <AnimatePresence >
        {(isMenuOpen === route.id)?(
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit="hidden"
            className={`menu_container ${isMenuOpen?"menu_container_active":""}`}
          >
            {route.subRoutes.map((subRoute, i) => (
              <>
              {//(permissionList.includes(subRoute.Permissions) || subRoute?.role === role)
              true && <motion.div  variants={menuItemAnimation} key={num+i} custom={i}>
                <NavLink  to={subRoute.path} className={`link`} title={subRoute.name}>
                  <div className="icon">{subRoute.icon}</div>
                  <motion.div className="link_text">{subRoute.name}</motion.div>
                </NavLink>
              </motion.div>}
              </>
            ))}
          </motion.div>
        ):""}
      </AnimatePresence>
    </>
  );
};

export default SidebarMenu;
