import React, { useState } from "react";
import "./style.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../Components/Input/Input";
import axios from "axios";
import Swal from "sweetalert2";
import { verificationAadhar } from "../../utils/api";
import GloabalLoader from "../../Components/Loader/GloabalLoader";
import { isAlphabet, isEmpty, isIdPattern, isNumber } from "../../utils/common";

const Verification = () => {
  const initialValues = {
    pannumber: "",
    nameonpan: "",
    adharnumber: "",
    nameonadhar: "",
  };

  const INIT_ERRORS = {
    panNumber: "",
    nameOnPan: "",
    adharNumber: "",
    nameOnAdhar: "",
  };

  const location = useLocation();
  const navigate = useNavigate();
  const userData = location.state;
 
  let [adharData, setAdharData] = useState(false);
  const [detail, setDetail] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [verifyData, setVeruifyData] = useState();
  const [loading, setLoading] = useState(false);
  const [otpSendAgainParam, setOtpSendAgainParam] = useState({})

  function adharDetails() {
    setAdharData(true);
  }

  function panDetails() {
    setAdharData(false);
  }

  const handleData = async (e) => {
    let error = {};
    if (detail.nameonpan === "") {
      error.userName = "Please enter your name on Pan number";
      setErrors((prev) => ({
        ...prev,
        nameOnPan: "Please enter your name on Pan number",
      }));
    }else{
      setErrors((prev) => ({
        ...prev,
        nameOnPan: "",
      }));
    }
    if (detail.pannumber === "") {
      setErrors((prev) => ({
        ...prev,
        panNumber: "Please enter your Pan number",
      }));
    } else if (detail.pannumber.length < 10) {
      setErrors((prev) => ({
        ...prev,
        panNumber: "Pan number can't be less than 10",
      }));
    }
    if (detail.nameonadhar === "") {
      setErrors((prev) => ({
        ...prev,
        nameOnAdhar: "Please enter your name on Aadhar",
      }));
    }
    if (detail.adharnumber === "") {
      setErrors((prev) => ({
        ...prev,
        adharNumber: "Please enter your Aadhar number",
      }));
    } else if (detail.adharnumber.length < 12) {
      setErrors((prev) => ({
        ...prev,
        adharNumber: "Aadhar number can't be less than 12",
      }));
    } else {
      let latitude = "17.4442806";
      let longitude = "78.4809119";
      let consent = "Y";
      let body = detail;
  
      const param = {
        aadhaar: detail?.adharnumber,
        email: userData?.email,
        latitude: latitude,
        longitude: longitude,
        mobile: userData?.userName,
        pan: detail.pannumber,
        consent: consent,
      };
      setOtpSendAgainParam(param);
      setLoading(true);
      verificationAadhar(param).then((resp) => {
        setLoading(false);
        if (resp.data.success === true) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "OTP sent on your registered mobile to aadhar card",
            timer: 2200,
            showConfirmButton: "#3bee4a",
          });
          const datas = {
            userName: userData.userName,
            hash: resp.data.data.hash,
            otpReferenceId: resp.data.data.otpReferenceID,
            otpSendAgain:param,
          };
        
          navigate("/otpverification", { state: datas }); 

        } else {
          let msg = "";
          if (resp?.data?.errorCodeList.length > 0) {
            msg = resp?.data?.errorCodeList[0];
          } else {
            msg = resp?.data?.msg;
          }
          Swal.fire({
            icon: "error",
            text: msg,
            confirmButtonColor: "#ED1C24",
          });
        }
      });
    }
    // else {
    //     navigate("/otpverification");
    // }
  };

  const handleAdhaarData = async (e) => {

    if (detail.nameonadhar === "") {
      setErrors((prev) => ({
        ...prev,
        nameOnAdhar: "Please enter your name on Aadhar",
      }));
    }
    else{
      setErrors((prev) => ({
        ...prev,
        nameOnAdhar: "",
      }));
    }
    if (detail.adharnumber === "") {
      setErrors((prev) => ({
        ...prev,
        adharNumber: "Please enter your Aadhaar number",
      }));
    } else if (detail.adharnumber.length < 12) {
      setErrors((prev) => ({
        ...prev,
        adharNumber: "Pan number can't be less than 10",
      }));
    }
  
  };
  
  let name, value;
  const handleInput = (e, key = "") => {
    name = e.target.name;
    value = e.target.value;
    setErrors({});
    if (key === "number") {
      if (!isNumber(value) && !isEmpty(value)) return;
    } else if (key === "text") {
      if (!isAlphabet(value) && !isEmpty(value)) {
        return;
      }
    } else if (key === "textNum") {
      if (!isIdPattern(value) && !isEmpty(value)) {
        return;
      }
    }

    if (name === "pannumber") {
      let upperCase = value.toUpperCase();
      setDetail({ ...detail, [name]: upperCase });
    } else {
      setDetail({ ...detail, [name]: value });
    }

  
  };



  return (
    <div className="main_container">
      <div className="container1">
        <label>Create New Retailer</label>
   
      </div>
      <div className="data_details">
        <div className="container_progress1">
          <div className="round round-adduser">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-1" />
            <div className="lable">Retailer Detail</div>
          </div>
          <div className="round round-addBank">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-2" />
            <div className="lable ">Bank Detail</div>
          </div>
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox" className="active"></label>
            </div>
            <div className="bar bar-3" />
            <div className="lable">ID Verification</div>
          </div>{" "}
          <div className="round">
            <div>
              <input type="checkbox" checked id="checkbox" />
              <label htmlFor="checkbox"></label>
            </div>
            <div className="bar bar-4" />
            <div className="lable lable-last">Otp Verification</div>
          </div>
        </div>

        <div className="id_verification">
          <div className="verifications">
            <label className="label_head">Pan Details</label>
          </div>
        </div>
        <div className="id_verification">
          <div className="verification">
            <div className="labelname">
              <label>Pan Card Number</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                type="text"
                name="pannumber"
                pattern="[0-9]*"
                maxLength={10}
                placeholder="Enter your pan number"
                value={detail.pannumber}
                onChange={(e) => handleInput(e, "textNum")}
              />

              {errors.panNumber && <p className="error mt-10 fs-12">{errors.panNumber}</p>}
            </div>
          </div>
          <div className="verification">
            <div className="labelname">
              <label>Name on Pan Card</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              name="nameonpan"
              placeholder="Enter your name on pan card"
              value={detail.nameonpan}
              onChange={(e) => handleInput(e, "text")}
            />
            {errors.nameOnPan && <p className="error mt-10 fs-12">{errors.nameOnPan}</p>}
          </div>
        </div>

        <div className="id_verification">
          <div className="verifications">
            <label className="label_head">Aadhar Details</label>
          </div>
        </div>
        <div className="id_verification">
          <div className="verification">
            <div className="labelname">
              <label>Aadhar Card Number</label>
              <span className="required"> *</span>
            </div>
            <div>
              <Input
                type="text"
                name="adharnumber"
                pattern="[0-9]*"
                maxLength={12}
                placeholder="Enter your aadhar number"
                value={detail.adharnumber}
                onChange={(e) => handleInput(e, "number")}
              />

              {errors.adharNumber && (
                <p className="error mt-10 fs-12">{errors.adharNumber}</p>
              )}
            </div>
          </div>
          <div className="verification">
            <div className="labelname">
              <label>Name on Aadhar Card</label>
              <span className="required"> *</span>
            </div>
            <Input
              type="text"
              name="nameonadhar"
              placeholder="Enter your name on aadhar card"
              value={detail.nameonadhar}
              onChange={(e) => handleInput(e, "text")}
            />
            {errors.nameOnAdhar && (
              <p className="error mt-10 fs-12">{errors.nameOnAdhar}</p>
            )}
          </div>
        </div>

        <div className="id_verification">
          <div className="verification">
            <button
              className="submit_btn"
              onClick={() => {
                handleData();
                // navigate("/otpverification");
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {loading && <GloabalLoader />}
    </div>
  );
};

export default Verification;
