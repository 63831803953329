import React from 'react'
import "./input.scss"

const Input = (props) => {
  return (
    <div>
        <input {...props} style={{width: "330px"}} autoComplete="new-password"/>
    </div>
  )
}

export default Input