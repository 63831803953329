import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../Components/Pagination/Pagination";
import "../Users/style.scss";
import "./BorrorwerList.scss";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/img/logo.jpg";
import dropdownIcon from "../../assets/img/carret.svg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  deteleUserFromList,
  getBorrowerList,
  getUserList,
  searchUser,
} from "../../utils/api";
import {

  getDateFromDateObj,
  getTimeAndDate,
  isAlphabet,
  isEmpty,
  isNumber,
  keyExists,
} from "../../utils/common";
import { ModalPopUp } from "../../Components/ModalPopUp/ModalPopUp.jsx";
import LocalLoader from "../../Components/Loader/LocalLoader.js";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import userMainBalance from "../../assets/images/userMainBalance.svg";
import userAepsBalance from "../../assets/images/userAepsBalance.svg";
import modalpopup from "../../assets/images/modalPopubg.svg";
import DatePicker from "react-datepicker";
import GloabalLoader from "../../Components/Loader/GloabalLoader.js";
require("react-datepicker/dist/react-datepicker.css");

const BorrorwerList = () => {
  const [apiData, setApiData] = useState([]);

  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pdfData, setPdfData] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState("");

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cls, setCls] = useState(-1);
  const [sidebarCls, setSideBarCls] = useState("");
  const [userJourney, setUserJourney] = useState([]);
  const [userCsv, setUserCsv] = useState([]);

  const link = useRef();
  const linkCsv = useRef();
  console.log(linkCsv);
  // Date Format
  var currentDate = new Date();
  var formattedDate = [
    currentDate.getDate(),
    currentDate.getMonth() + 1,
    currentDate.getFullYear(),
  ]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join("-");

  const navigate = useNavigate();

  let _fromDate = "";
  let _toDate = "";

  if (startDate !== "" && endDate !== "") {
    _fromDate = getDateFromDateObj(startDate, "YYYY-MM-DD");
    _toDate = getDateFromDateObj(endDate, "YYYY-MM-DD");
  }
  //API to fetch userlist

  const handleApiData = async (key) => {
    const pagination = {
      currentPage: currPage,
      pageSize: pageSize,
    };
    if (key === "CSV" || key === "PDF") {
      if (key === "CSV") {
        setDownloadLoader("CSV");
      } else {
        setDownloadLoader("PDF");
      }
      pagination.pageSize = totalElements;
      pagination.currentPage = 0;
    }
    setLoading(true);
    getBorrowerList({}, pagination).then((resp) => {
      setLoading(false);
      setDownloadLoader("");
      console.log(resp?.data?.data?.content);
      if (resp.data?.code == "0000") {
        if (key === "CSV") {
          setPdfData(resp?.data?.data?.content);

          setTimeout(() => {
            const csvlink = link.current;
            csvlink.link.click();
          }, 100);
        } else if (key === "PDF") {
          userlistPdfData(resp?.data?.data?.content);
        } else {
          setApiData(resp?.data?.data?.content);
          setTotalPages(resp?.data?.data?.totalPages);
          setTotalElements(resp?.data?.data?.totalElements);
        }
      }
    });
  };
  useEffect(() => {
    handleApiData();
  }, [currPage]);

  //API to Export PDF file
   const handleGeneratePDF = (data) =>{
     
      const doc = new jsPDF();    
      var pageWidth = doc.internal.pageSize.getWidth();
      var contentWidth = 40; // Adjust as needed
      var colWidth = (doc.internal.pageSize.getWidth() / 2) - 15;
   

      doc.setFontSize(10);
   // doc.setTextColor(255, 0, 0); // RGB color
   let address = {}
   if(data?.aadharAddress){
     address  = JSON.parse(data?.aadharAddress)
     
   }
   console.log("abc",address)
   const dateTimeString = data?.createdAt;
    let [date, time] = dateTimeString.split("T");
    let newTime = time.slice(0, 8);
       console.log(data);
  const valPos = 55;
  doc.addImage(companylogo, 'JPEG', 20, 10, 30,10);
  doc.setFontSize(10);
  //doc.setTextColor(0, 186, 242);
  doc.setFont("helvetica", "bold")
      doc.text(`Mobile Number:` , 20, 30);
      doc.text(`Name On Aadhar:` , 20, 40);
      doc.text(`Name On PAN:` , 20, 50);
      doc.text(`Name On Bureau:` , 20, 60);
      doc.text(`Name On Bank:` , 20, 70);
      doc.text(`House Number:` , 20, 80);
      doc.text(`Village:` , 20, 90);
      doc.text(`Street:` , 20, 100);
      doc.text(`Subdistic:` , 20, 110);
      doc.text(`State:` , 20, 120);
      doc.text(`Pin Code:` , 20, 130);
      doc.text(`Settlement:` , 20, 140);
      doc.text(`Write Off:` , 20, 150);
      doc.text(`Active Dpd:` , 20, 160);
   


      //doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");
      doc.text(` ${data?.mobileNumber || "NA"}` , valPos, 30);
      doc.text(`${data?.nameOnAadhar || "NA"}` , valPos, 40);
      doc.text(`${data?.nameOnPan || "NA"}` , valPos, 50);
      doc.text(`${data?.nameOnBureau || "NA"}` , valPos, 60);
      doc.text(`${data?.nameOnBank || "NA"}` , valPos, 70);
      doc.text(`${address?.house || "NA"}` , valPos, 80);
      doc.text(`${address?.vtc || "NA"}` , valPos, 90);
      doc.text(`${address?.street || "NA"}` , valPos, 100);
      doc.text(`${address?.subdist || "NA"}` , valPos, 110);
      doc.text(`${address?.state || "NA"}` , valPos, 120);
      doc.text(`${address?.pincode || "NA"}` , valPos, 130);
      doc.text(`${data?.isSettlement === undefined?"NA":data?.isSettlement}` , valPos, 140);
      doc.text(`${data?.isWriteOff === undefined?"NA":data?.isWriteOff}` , valPos, 150);
      doc.text(`${data?.isActiveDpd === undefined?"NA":data?.isActiveDpd}` , valPos, 160);
    
      
   
  
      // Right column
    //  doc.setTextColor(0, 186, 242);
      doc.setFont("helvetica", "bold");
      doc.text(`Vefification Status:` , colWidth + 20, 30);
       doc.text(`Aadhar Number:` , colWidth + 20, 40);
       doc.text(`Pan Number:` , colWidth + 20, 50);
       doc.text(`DOB:` ,colWidth + 20, 60);
 
       doc.text(`Cibil Score:` , colWidth + 20, 70);
       doc.text(`Created Date:` ,colWidth + 20, 80);
       doc.text(`Post:` ,colWidth + 20, 90);
       doc.text(`Landmark:` ,colWidth + 20, 100);
       doc.text(`Distic:` ,colWidth + 20, 110);
       doc.text(`Country:` ,colWidth + 20, 120);
       doc.text(`Suite Filed:` ,colWidth + 20, 130);
       doc.text(`ChargeOff:` ,colWidth + 20, 140);
       doc.text(`Active Credit:` ,colWidth + 20, 150);
      
    
     // doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");
      doc.text(` ${data?.verificationStatus || "NA"}` , colWidth + valPos, 30);
      doc.text(`${data?.aadharNumber || "NA"}` , colWidth + valPos, 40);
      doc.text(`${data?.panNumber || "NA"}` , colWidth + valPos, 50);
      doc.text(`${data?.dob || "NA"}` , colWidth + valPos, 60);
       doc.text(` ${data?.cibilScore || "NA"}` , colWidth + valPos, 70);
       doc.text(`${date + " " + newTime}` ,colWidth + valPos, 80);
       doc.text(`${address?.po || "NA"}` ,colWidth + valPos, 90);
       doc.text(`${address?.landmark || "NA"}` ,colWidth + valPos, 100);
       doc.text(`${address?.subdist || "NA"}` ,colWidth + valPos, 110);
       doc.text(`${address?.country || "NA"}` ,colWidth + valPos, 120);
       doc.text(`${data?.isSuiteFiled === undefined?"NA":data?.isSuiteFiled }` ,colWidth + valPos, 130);
       doc.text(`${data?.isChargeOff === undefined?"NA":data?.isChargeOff}` ,colWidth + valPos, 140);
       doc.text(`${data?.isActiveCredit === undefined?"NA":data?.isActiveCredit}` ,colWidth + valPos, 150);
     

    

        // Save the PDF
    doc.save(`borrower-${data.nameOnAadhar}.pdf`);
   }
  
  const userlistPdfData = (data = []) => {
//     const doc = new jsPDF();
//     //Add image in PDF header
//     const imgWidth = 25;
//     const imgHeight = 9;
//     const leftXPos = 14;
//     const yPos = 2;
//     doc.addImage(companylogo, "PNG", leftXPos, yPos, imgWidth, imgHeight);

//     //Add fileName in the center
//     const text = "User List";
//     // Calculate the width of the text
//     const textWidth =
//       (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
//       doc.internal.scaleFactor;
//     // Get the width of the PDF
//     const pdfWidth = doc.internal.pageSize.width;
//     const yPos1 = 10;
//     // Calculate the X position for center alignment
//     const centerXPos = (pdfWidth - textWidth) / 2;
//     doc.text(text, centerXPos, yPos1);
//     const generalFontSize = 12;
//     doc.setFontSize(generalFontSize);
//     doc.setMargins(0, 0, 0, 0);
//     // Calculate the width of the date text
//     const dateWidth =
//       (doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize()) /
//       doc.internal.scaleFactor;

//     // Calculate the X position for right alignment
//     const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge
//     doc.text(formattedDate, rightXPos, yPos1);

//     // Create a table from API data
//     const tableData = [];
//     data.forEach((item, index) => {
//       const rowData = [
//         index + 1,
//         item.firstName + " " + item.lastName,
//         item.userName,
//         item.email,
//         item.address1,
//         "Approved",
//         item.userType,
//       ]; // Customize as needed
//       tableData.push(rowData);
//       console.log(" smx", tableData);
//     });

//     doc.autoTable({
//       head: [
//         [
//           "S.No",
//           "Name",
//           "Mobile No.",
//           "Email",
//           "Address",
//           "Status",
//           "UserType",
//         ],
//       ], // Customize table header
//       body: tableData,
//     });

//     doc.save("userlist.pdf");
   };

  const openDropdown = (index) => {
    if (index === cls) {
      setCls(-1);
    } else {
      setCls(index);
    }
  };

  const openSideBar = (arr) => {
    console.log(arr);
    setSideBarCls("active");
    setUserJourney(arr);
  };

  const headers = [
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Name On PAN", key: "nameOnPan" },
    { label: "Cibil Score", key: "cibilScore" },
    { label: "Verification Status", key: "verificationStatus" },
  ];

  const recordDownload = (items, key = "") => {
    if (key === "csv") {
      setUserCsv([items]);
      console.log(linkCsv);
      setTimeout(() => {
        const csvlink = linkCsv.current;
        console.log(csvlink);
        csvlink.link.click();
      }, 100);
    }
  };

  const closeUserJourney = () => {
    setSideBarCls(" active wait");
    setTimeout(() => {
      setSideBarCls("");
    }, 300);
  };

  return (
    <div className="userlisthead_container">
      <div>
        <div className="transaction_main_container">
          <div className="userlist_head">
            <div className="userlist_heading">
              <div className="userlist_left">
                <label>Borrowers List</label>
              </div>
              <div className="userlist_right">
                {downloadLoader !== "PDF" ? (
                  <button
                    className="pdf"
                    onClick={(e) => handleApiData(e, "PDF")}
                  >
                   
                    PDF
                  </button>
                ) : (
                  <button className="pdf">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}
                {downloadLoader !== "CSV" ? (
                  <button
                    className="xls"
                    onClick={(e) => handleApiData(e, "CSV")}
                  >
                    CSV
                  </button>
                ) : (
                  <button className="xls">
                    <div className="btn-loader">
                      <i class="fa-solid fa-spinner"></i>
                    </div>
                  </button>
                )}
                <CSVLink
                  ref={link}
                  data={pdfData}
                  filename="Userlist.csv"
                ></CSVLink>
                <CSVLink
                  ref={linkCsv}
                  headers={headers}
                  data={userCsv}
                  filename="user-details.csv"
                ></CSVLink>
              </div>
            </div>
          </div>

          {/* <FilterWrapper style={{ height: "auto" }}>
                        <div className='filterBox'>
                          
                                <div className="flex align-items gap-20">

                                    <div className="input-field">
                                        <input
                                            type="text"
                                            name="detailData"
                                            placeholder="Enter mobile"
                                            value={detailData}
                                            onChange={(e) => handleDetailData(e, "number")}
                                            maxLength={10}
                                        />
                                    </div>
                                    <div className="input-field">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Enter username"
                                            value={name}
                                            onChange={(e) => handleDetailData(e, "text")}

                                        />
                                    </div>
                                    <div className="input-field">
                                        <select className="input_select_field"
                                        value={userType}
                                        onChange={(e) => setUserType(e.target.value)}>
                                            <option value={""}>User Type</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Retailer">Retailer</option>
                                            <option value="Distributor">Distributor</option>

                                        </select>
                                    </div>
                                    <div className="input-field">
                                        <div className="flex">
                                           
                                            <DatePicker
                                                showIcon
                                                className="date-picker2 date_type"
                                                placeholderText="From Date"
                                                selected={startDate}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown={true}
                                                showYearDropdown
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={date => setStartDate(date)}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-field">
                                        <div className="flex" >
                                            <DatePicker
                                                showIcon
                                                className="date-picker2 date_type"
                                                placeholderText="To Date"
                                                selected={endDate}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown={true}
                                                showYearDropdown
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={date => setEndDate(date)}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>

                                    <button className="userlist_btn"
                                        onClick={(e) => handleApiData(e, "search")}
                                    >Search</button>
                                    <button className="userlist_btn"
                                        onClick={() => navigate('/addUser')}>Add Borrower
                                    </button>
                                    <button className='refresh-btn' onClick={(e) => handleApiData(e, "refresh")}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </div>
                               
                            
                        </div>
                    </FilterWrapper> */}
        </div>
        <div className="transaction_table">
          {loading && <GloabalLoader location="local" />}
          <table id="customers">
            <tr>
             
              <th>Name On Aadhar</th>
              <th>Mobile No.</th>
              <th>Cibil Score</th>
              <th>Date And Time</th>
              <th>Verification Status</th>
              <th>User Journey</th>
              <th>More</th>
              <th>Action</th>
            </tr>

            {apiData &&
              apiData?.map((item, index) => {
                const dateTimeString = item.createdAt;
                let [date, time] = dateTimeString.split("T");
                let newTime = time.slice(0, 8);

          
                   let address = {}
                   let addAddress = ""
                    if(item?.aadharAddress){
                      address  = JSON.parse(item?.aadharAddress)
                      addAddress = `${address?.house} ${address?.vtc} ${address?.po}`;
                      
                    }
                   // console.log("abc",address)
									

                return (
                  <tr
                  id={"GeneratePDF-"+index}
                    className={`border tableDropdownTr ${
                      cls === index ? "active" : ""
                    }`}
                    key={index}
                  >
                

                    <td><span>{item?.nameOnAadhar}</span></td>
                    <td><span>{item?.mobileNumber}</span></td>
                    <td><span>{item?.cibilScore}</span></td>
                    <td><span>{date + " " + newTime}</span></td>
                    <td><span>{item.verificationStatus}</span> </td>

                    <td className="user_action_btn">
                      <button
                        onClick={() => {
                          openSideBar(item?.userScreenDetails);
                        }}
                      >
                        <i class="fa-regular fa-eye fs-16 update"></i>
                      </button>
                    </td>
                    <td className="user_action_btn dropdown">
                      <button  onClick={() => openDropdown(index)}>
                        <img className="dropdown" src={dropdownIcon} alt="" />
                      </button>
                    </td>
                    <td className="flex gap-10">
                      <button
                        className="xls btn-round"
                        onClick={(e) => {
                          recordDownload(item, "csv");
                        }}
                      >
                        CSV
                      </button>
                      <button className="pdf btn-round " onClick={(e) => {handleGeneratePDF(item)}}>
                        PDF
                      </button>
                    </td>
                    <div className={`tableDropdown flex`}
                    id={`${"tableDropdown-"+index}`}>
                     
                   
                        <div className="fromBox userDeitals">
                       
                          <div className="mainfrom flex">
                            
                        
                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Name On Aadhar<span class="red"></span>
                                </label>
                                <div class=" flex">
                                  <input
                                    class=" "
                                    type="text"
                                    value={item?.nameOnAadhar || "NA"}
                                  />
                                </div>
                              </div>
                            
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Aadhar Number
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item.aadharNumber || "NA"}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Name On PAN
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item?.nameOnPan || "NA"}
                                  />
                                </div>
                              </div>
                            
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                PAN Number
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item?.panNumber || "NA"}
                                  />
                                </div>
                              </div>
                            
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Name On Bank
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item?.nameOnBank || "NA"}
                                  />
                                </div>
                              </div>
                            
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Mobile Number
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item?.mobileNumber || "NA"}
                                  />
                                </div>
                              </div>
                            
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                DOB
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item?.dob || "NA"}
                                  />
                                </div>
                              </div>
                            
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Name On Bureau
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item?.nameOnBureau || "NA"}
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Cibil Score
                                </label>
                                <div class=" flex">
                                  <input
                                    
                                    type="text"
                                    value={item?.cibilScore || "NA"}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                  Date & Time
                                </label>
                                <div class=" flex">
                                  <input
                               
                                    type="text"
                                    value={date + " " + newTime}
                                  />
                                </div>
                              </div>
                            
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Verification Status
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={item.verificationStatus || "NA"}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                               Address<span class="red">*</span>
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${addAddress || "NA"}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                               Street<span class="red">*</span>
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${address?.street || 'NA'}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Subdistic
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${address.subdist || "NA"}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Landmark
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${address?.landmark || "NA"}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Distic
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${address.dist || "NA"}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                State
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${address?.state || "NA"}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Country
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${address.country || "NA"}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                            <div class="from-group">
                              <div class="from-field">
                                <label for="">
                                Pin Code
                                </label>
                                <div class=" flex">
                                  <input
                                    type="text"
                                    value={`${address.pincode || "NA"}`}
                                  />
                                </div>
                              </div>
                             
                            </div>

                           { item?.isSuiteFiled === undefined ?
                            <div class="from-group">
                            <div class="from-field">
                              <label for="">
                              Suite Filed
                              </label>
                              <div class=" flex">
                                <input
                                  type="text"
                                  value={`${"NA"}`}
                                />
                              </div>
                            </div>
                           
                          </div>
                           :
                           <div class="from-group">
                           <label for="">Suite Filed</label><br />
                             <div className={`custom-toggle toggle_button mt-9 ${item?.isSuiteFiled? "active":""}`}>
                             <div className="slider"></div>
                              </div>
                       </div>}

                           { item?.isSettlement === undefined ?
                            <div class="from-group">
                            <div class="from-field">
                              <label for="">
                              Settlement
                              </label>
                              <div class=" flex">
                                <input
                                  type="text"
                                  value={`${"NA"}`}
                                />
                              </div>
                            </div>
                           
                          </div>
                           :
                           <div class="from-group">
                           <label for="">Settlement</label><br />
                             <div className={`custom-toggle toggle_button mt-9 ${item?.isSettlement? "active":""}`}>
                             <div className="slider"></div>
                              </div>
                       </div>}

                           { item?.isChargeOff === undefined ?
                            <div class="from-group">
                            <div class="from-field">
                              <label for="">
                              ChargeOff
                              </label>
                              <div class=" flex">
                                <input
                                  type="text"
                                  value={`${"NA"}`}
                                />
                              </div>
                            </div>
                           
                          </div>
                           :
                           <div class="from-group">
                           <label for="">ChargeOff</label><br />
                             <div className={`custom-toggle toggle_button mt-9 ${item?.isChargeOff? "active":""}`}>
                             <div className="slider"></div>
                              </div>
                       </div>}

                           { item?.isWriteOff === undefined ?
                            <div class="from-group">
                            <div class="from-field">
                              <label for="">
                              WriteOff
                              </label>
                              <div class=" flex">
                                <input
                                  type="text"
                                  value={`${"NA"}`}
                                />
                              </div>
                            </div>
                           
                          </div>
                           :
                           <div class="from-group">
                           <label for="">WriteOff</label><br />
                             <div className={`custom-toggle toggle_button mt-9 ${item?.isWriteOff? "active":""}`}>
                             <div className="slider"></div>
                              </div>
                       </div>}

                           { item?.isActiveCredit === undefined ?
                            <div class="from-group">
                            <div class="from-field">
                              <label for="">
                              Active Credit
                              </label>
                              <div class=" flex">
                                <input
                                  type="text"
                                  value={`${"NA"}`}
                                />
                              </div>
                            </div>
                           
                          </div>
                           :
                           <div class="from-group">
                           <label for="">Active Credit</label><br />
                             <div className={`custom-toggle toggle_button mt-9 ${item?.isActiveCredit? "active":""}`}>
                             <div className="slider"></div>
                              </div>
                       </div>}


                           { item?.isActiveDpd === undefined ?
                            <div class="from-group">
                            <div class="from-field">
                              <label for="">
                              ActiveDpd
                              </label>
                              <div class=" flex">
                                <input
                                  type="text"
                                  value={`${"NA"}`}
                                />
                              </div>
                            </div>
                           
                          </div>
                           :
                           <div class="from-group">
                           <label for="">ActiveDpd</label><br />
                             <div className={`custom-toggle toggle_button mt-9 ${item?.isActiveDpd? "active":""}`}>
                             <div className="slider"></div>
                              </div>
                       </div>}

                          </div>
                        </div>
                     
                    </div>
                  </tr>
                );
              })}
            {!loading && apiData && apiData.length < 1 && (
              <>
                <tr>
                  <td
                    colSpan={16}
                    style={{
                      textAlign: "center",
                      color: "red",
                      height: "120px",
                      fontSize: "20px",
                    }}
                  >
                    No Data Found
                  </td>
                </tr>
              </>
            )}
          </table>
        </div>
        {totalPages > 1 && (
          <Pagination
            items={apiData}
            itemsPerPage={pageSize}
            setitemsPerPage={setPageSize}
            currentPage={currPage}
            setcurrentPage={setCurrPage}
            totalPages={totalPages}
          />
        )}
      </div>
      <aside className={`user-journey flex ${sidebarCls}`}>
        <div className="grow-1" onClick={() => closeUserJourney()}></div>

        <div className="aside">
          <div className="top flex gap-10 justify-between align-items">
            <button
              className="pdf btn-round "
              onClick={() => closeUserJourney()}
            >
              Close
            </button>

            <h2>User Journey details</h2>
          </div>
          <div className="middle">
            <div className="thead flex justify-between">
              <div className="th">Details</div>
              <div className="th">Date & Time</div>
            </div>
            {userJourney &&
              userJourney.map((val, index) => {
                const step =
                  val?.screenCode === "CAPTURED_MOBILE"
                    ? "Capture Mobile"
                    : val?.screenCode === "VERIFIED_MOBILE"
                    ? "verified Mobile"
                    : val?.screenCode === "CAPTURE_LOAN_AMOUNT"
                    ? "Capture loan amount"
                    : val?.screenCode === "CAPTURE_OCCUPATION"
                    ? "Capture occupation"
                    : val?.screenCode === "CAPTURED_PAN"
                    ? "Capture pan"
                    : val?.screenCode === "CAPTURED_AADHAR"
                    ? "Capture aadhar"
                    : val?.screenCode === "CAPTURED_CIBIL_SOCRE"
                    ? "Capture cibil socre"
                    : val?.screenCode === "CAPTURED_BANK_DETAIL"
                    ? "Capture bank detail"
                    : val?.screenCode === "CAPTURE_DOC"
                    ? "Capture document"
                    : "";
                return (
                  <div className=" tr flex gap-10 justify-between">
                    <div className="td">{step}</div>
                    <div className="td">
                      {getTimeAndDate(val?.completionDate)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </aside>
    </div>
  );
};

export default BorrorwerList;
