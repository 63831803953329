import React from 'react'
import RegisterRight from './RegisterRight'
import "./_styles/register.scss"

const Register = () => {
  return (
    <div className='register_wrapper'>
        <div className="register_left">
            
        </div>

        <RegisterRight/>
    </div>
  )
}

export default Register