


import React, {  useState } from 'react';
import powerImg from "../../assets/img/power.svg";
import { logout } from '../../store/authSlice';
import { deleteUser } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';



function BackButtun({backUrl}) {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
     
    sessionStorage.clear();
    dispatch(logout());
    dispatch(deleteUser());
    navigate("/");
  };
  return (
    <>
       <button className='button-round center' >
          <img src={powerImg} alt="sign-out" onClick={handleLogout} /><span>SIGN OUT</span>
       </button>
     
      </>
  );
}

export default BackButtun;
