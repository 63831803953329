
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Pagination from "../../Components/Pagination/Pagination";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companylogo from "../../assets/images/companylogo.png"
import { useNavigate } from "react-router-dom";
import LocalLoader from "../../Components/Loader/LocalLoader";
import Swal from "sweetalert2";
import { FilterWrapper } from "../../assets/styles/FilterStyle";
import { serviceList, updateService, updateServiceStatus } from "../../utils/api";
import DatePicker from "react-datepicker";
require('react-datepicker/dist/react-datepicker.css')


const options = [
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
];

const ServiceTable = () => {

    const [services, setServices] = useState([]);
    const [pdfData, setPdfData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalElements, setTotalElements] = useState("");
    const [currPage, setCurrPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [dropdownState, setDropdownState] = useState(false);
    const [dropdownValue, setDropdownValue] = useState('');
    const [service, setService] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState('');
    const [status, setStatus] = useState(null)

    const [errors, setErrors] = useState({
        startDate: "",
        endData: "",
    });
    const link = useRef()

    const navigate = useNavigate()

    const handleservicesData = async (e, key) => {
        if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
            setErrors((prev) => ({
                ...prev,
                startDate: "From Date can't be greater than to Date",
            }));
            setServices([])
            return
        }
        setLoading(true)
        let startdate = new Date(services.startDate)
        startdate = [startdate.getFullYear(), startdate.getMonth() + 1, startdate.getDate()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        services.startDate = startdate

        const param = {
            fromDate: startDate,
            pageNo: currPage,
            pageSize: pageSize,
            serviceName: service,
            toDate: endDate,
        }

        if (key === "CSV" || key === "PDF") {
            if ((key === "CSV")) {
                setDownloadLoader("CSV")
            } else {
                setDownloadLoader("PDF")
            }
            param.pageSize = totalElements;
            param.pageNo = 0;
        }
        if (key === "search") {
            param.pageNo = 0;
        } else {
            setLoading(true)
        }
        if (key === "refresh") {
            dataRefresh()
            param.fromDate = "";
            param.serviceName = "";
            param.toDate = "";
        }
        serviceList(param).then((resp) => {
            setLoading(false);
            setDownloadLoader("");

            if (resp?.data?.success) {
                if (key === "CSV") {
                    setPdfData(resp?.data?.data?.content);
                    setTimeout(() => {
                        const csvlink = link.current
                        csvlink.link.click();
                    }, 100)

                } else if (key === "PDF") {
                    servicesPdfData(resp?.data?.data?.content)
                } else {
                    setServices(resp?.data?.data?.content);
                    setTotalPages(resp.data.data.totalPages);
                    setTotalElements(resp?.data?.data?.totalElements);
                }
            }
        })

    };

    useEffect(() => {
        handleservicesData();
    }, [currPage, service]);


    const servicesPdfData = (data = []) => {

        //  Date Format
        var currentDate = new Date();
        var formattedDate = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear()]
            .map(n => n < 10 ? `0${n}` : `${n}`).join('-');

        const doc = new jsPDF();

        //Add image in PDF header
        const imgWidth = 25;
        const imgHeight = 9;
        const leftXPos = 10;
        const yPos = 2;
        doc.addImage(companylogo, 'PNG', leftXPos, yPos, imgWidth, imgHeight);

        //Add fileName in the center
        const text = 'Service List';

        const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // Get the width of the PDF
        const pdfWidth = doc.internal.pageSize.width;
        const yPos1 = 10;
        // Calculate the X position for center alignment
        const centerXPos = (pdfWidth - textWidth) / 2;
        doc.text(text, centerXPos, yPos1);


        // Calculate the width of the date text
        const dateWidth = doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // Calculate the X position for right alignment
        const rightXPos = pdfWidth - dateWidth - 15; // 10 is for some margin from the right edge           
        doc.text(formattedDate, rightXPos, yPos1);

        // Create a table from API data
        const tableData = [];
        data.forEach((item, index) => {
            let service_time = item.createdAt.split("T", 1)
            const rowData = [index + 1, item.serviceName, item.serviceSku, item.createdby, service_time]; // Customize as needed
            tableData.push(rowData);
        });

        doc.autoTable({
            head: [['S.No', 'Service Name', 'Service Code', 'Service Created By', 'Service Created At']], // Customize table header
            body: tableData,
        });

        doc.save('ServiceList.pdf');
    };

    const updateStatusmodal = (status) => {
        Swal.fire({
            title: "Are you sure? you want to update status",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#00BAF2',
            confirmButtonText: 'Yes, I am sure!',
            cancelButtonText: "No, cancel it!",
            closeOnConfirm: false,
            closeOnCancel: false
        }).then((result) => {
            if (result.isConfirmed) {
                updateStatus(status)
            }
        });
    }

    const updateStatus = (status) => {

        const param = {
            createdAt: "2023-08-23T07:28:33.112Z",
            createdBy: "string",
            id: "string",
            isDeleted: true,
            isPublished: true,
            isStatus: !status,
            serviceCode: "string",
            serviceImage: "string",
            serviceName: "string",
            serviceSku: "string",
            updatedAt: "2023-08-23T07:28:33.112Z",
            updatedBy: "string"
        }

        updateServiceStatus(param).then((resp) => {

            if (resp?.data?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Service status updated successfully",
                    timer: 1200,
                    showConfirmButton: false,
                    customClass: 'swal-wide',
                });
                navigate('/serviceList')
            }
            else {

            }

        })
    }
    const dataRefresh = () => {
        setStartDate("")
        setEndDate("")
        setService("")
        setDropdownValue("")
    }
    return (
        <div className="userlisthead_container">
            <div style={{ padding: "30px" }}>
                <div className='transaction_main_container'>
                    <div className='userlist_head'>
                        <div className='userlist_heading'>
                            <div className='userlist_left'>
                                <label>Service List</label>
                            </div>
                            <div className='userlist_right'>
                                {downloadLoader !== "PDF" ? <button className='pdf' onClick={(e) => handleservicesData(e, "PDF")}
                                >PDF</button> :
                                    <button className='pdf'>
                                        <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                    </button>
                                }

                                {downloadLoader !== "CSV" ? <button className='xls' onClick={(e) => handleservicesData(e, "CSV")}
                                >CSV</button> :
                                    <button className='xls'>
                                        <div className='btn-loader'><i class="fa-solid fa-spinner"></i></div>
                                    </button>
                                }
                                <CSVLink
                                    ref={link}
                                    data={pdfData}
                                    filename="serviceList.csv"
                                >

                                </CSVLink>
                            </div>
                        </div>

                    </div>
                    <FilterWrapper style={{ height: "auto" }}>
                        <div className='filterBox'>
                            <div>
                                <div className="flex align-items gap-20" >

                                    <div>
                                        <input
                                            type="text"
                                            name=""
                                            placeholder="Enter Service Name"
                                            value={service}
                                            onChange={(e) => setService(e.target.value)} />
                                    </div>
                                    {/* <div>
                                        <Dropdown
                                            options={options}
                                            dd_name={"Phone No."}
                                            dropdownValue={dropdownValue}
                                            setDropdownValue={setDropdownValue}
                                            dropdownState={dropdownState}
                                            setDropdownState={setDropdownState}
                                        />
                                    </div> */}
                                    <div>
                                        <div className="input-field">
                                            <select value={status}
                                                onChange={(e) => setStatus(e.target.value)} className="input_select_field">
                                                <option value="null">Status</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>

                                            </select>
                                        </div>
                                    </div>


                                    <div className="input-field">
                                        <div className="flex">

                                            <DatePicker
                                                showIcon
                                                className="date-picker2 date_type"
                                                placeholderText="From Date"
                                                selected={startDate}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown={true}
                                                showYearDropdown
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={date => setStartDate(date)}
                                                maxDate={new Date()}
                                            />

                                        </div>

                                    </div>
                                    <div className="input-field">
                                        <div className="flex" >

                                            <DatePicker
                                                showIcon
                                                className="date-picker2 date_type"
                                                placeholderText="To Date"
                                                selected={endDate}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown={true}
                                                showYearDropdown
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={date => setEndDate(date)}
                                                maxDate={new Date()}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex align-items gap-20 pdt-15" >

                                    <div>
                                        <button className="userlist_btn"

                                            onClick={(e) => handleservicesData(e, "search")}
                                        >Search</button>
                                    </div >
                                    <div>
                                        <button className="userlist_btn"
                                            onClick={() => navigate('/addnewservice')}>Add New Feature</button>
                                    </div>
                                    <div >
                                        <button className='refresh-btn' onClick={(e) => handleservicesData(e, "refresh")}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </FilterWrapper>
                </div>
                <div className="transaction_table">
                    <table id="customers">
                        <tr>
                            <th>S.No</th>
                            <th>Service Name</th>
                            <th>Service Code</th>
                            <th>Service Image</th>
                            <th>Service Created At</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>

                        {loading && <tr>
                            <td colSpan={12}>
                                <LocalLoader />
                            </td>
                        </tr>}

                        {!loading && services && services.map((val, ind) => {
                            let service_time = val.createdAt.split("T", 1);
                            return (
                                <tr className="table_border" key={Math.random() * 1000000}>
                                    <td>{(currPage * pageSize) + ind + 1}</td>
                                    <td>{val.serviceName}</td>
                                    <td>{val.serviceSku}</td>
                                    <td>
                                        <img
                                            style={{ maxWidth: "30px" }}
                                            src={val.serviceImage}
                                            className="service-image"
                                        ></img>
                                    </td>
                                    <td>{service_time}</td>
                                    <td>

                                        <div
                                            className={`${val.status
                                                ? "active_status"
                                                : "inactive_status"
                                                }`}
                                        >
                                            {val.status ? "Active" : "InActive"}
                                            {/* {values ? "Active" : "InActive"}  */}


                                        </div>
                                    </td>

                                    <td className="user_action_btn">
                                        <button
                                            className=""
                                            style={{ marginRight: "5px" }}
                                            onClick={() => navigate("/editserviceList", { state: val })}
                                        >
                                            <i class="fa-solid fa-pen-to-square update"
                                            ></i>
                                        </button>
                                        <button onClick={updateStatusmodal}
                                        >
                                            <i class="fa-regular fa-lightbulb"
                                            ></i>
                                        </button>

                                        {/* Approved */}
                                    </td>
                                </tr>
                            )

                        })}

                        {
                            services && (services.length < 1) &&
                            <>
                                <tr>
                                    <td colSpan={14} style={{ textAlign: "center", color: "red", height: "120px", fontSize: "20px" }}>No Data Found</td>
                                </tr>
                            </>
                        }
                    </table>

                </div>
                {
                    totalPages > 1 &&
                    <Pagination
                        items={service}
                        itemsPerPage={pageSize}
                        setitemsPerPage={setPageSize}
                        currentPage={currPage}
                        setcurrentPage={setCurrPage}
                        totalPages={totalPages}
                    />
                }
            </div>
        </div>
    );
};

export default ServiceTable;
