import React from 'react'
import "../Operators/styles/operators.scss"
import OperatorsTable from './OperatorsTable'

const Operators = () => {
  return (
    <div className="transactions_container">
        <OperatorsTable/>
    </div>
  )
}

export default Operators