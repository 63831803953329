import React from 'react';
import "./spinner.scss"

const Spinner = ({fontSize}) => {
  return (
    <div className='spinner_container '>
        <i className={`fa-solid fa-circle-notch spinner ${fontSize}`}></i>
        
    </div>
  )
}

export default Spinner