import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import "./style.scss";

const Pagination = ({ itemsPerPage,totalPages, items, currentPage, setcurrentPage }) => {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    // const currentItems = items.slice(itemOffset, endOffset);
    // const pageCount = Math.ceil(items.length / itemsPerPage);

    // let displayPages = null;

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setcurrentPage(event.selected)

        setItemOffset(newOffset);
    };

    let displayPages = currentPage;
    

    return (
        <ReactPaginate
            // breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            // pageCount={totalPages > 100 ? displayPages + 10 : totalPages }
            pageCount={totalPages }
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
        />
    );
};

export default Pagination;
