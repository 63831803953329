import React from 'react'
import { useNavigate } from 'react-router-dom';
import togglw_button from "../../assets/images/toggle_button.png"

export default function  () {
 

  const dataset = [
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},
    {SNo : "1",MemberDetails:"EMI",Package:"100",MemberType:"200",Owner:"150",Date:"5%"},

  ];

  const navigate = useNavigate();

    const datatable = dataset.map((data)=>{
      return(
        <tr>
          <td>{data.SNo}</td>
          <td>{data.MemberDetails}</td>
          <td>
          <img src={togglw_button}></img>
          </td>
          <td>
            <img src={togglw_button}></img>
          </td>
          <td>
         <img src={togglw_button}></img>
          </td>
          <td>
         <img src={togglw_button}></img>
          </td>
          <td>
         <img src={togglw_button}></img>
          </td>
          <td>
         <img src={togglw_button}></img>
          </td>
          
          <td>{data.Package}</td>
          <td>{data.MemberType}</td>
          <td>{data.Owner}</td>
          <td>{data.Date}</td>
          <td>
         <img src={togglw_button}></img>
          </td>
          <td>
         <img src={togglw_button}></img>
          </td>
          <td className='user_action'>
          <button className='btn_view' 
          onClick={() => navigate("/clientdetails")}>view</button>
            <button className='btn_edit'>edit</button>
           
          </td>
        </tr>
      )
      
    })
  return (
    <div>
        <div className='memberlist_main_container'>
            <div className='memberlist_head'>
               <div className='memberlist_heading'>
                <div className='memberlist_left'>
                <strong>Member List</strong>
                </div>
                <div className='memberlist_right'>
                <button className='pdf'>PDF</button>  
                <button className='xls'>XLS</button> 
               </div>
               </div>
                
            </div>

            <div className='memberlist_container'>
                <div className='memberlist_col_container'>
                    <div className='memberlist_left_container'>
                        <div className='flex_memberlist'>
                            <div className='flex_memberlist_item'>
                                <div className='flex_memberlist_row'>
                                    <strong>Search Members</strong>
                                </div>
                            </div>
                        </div>
                        <div className='flex_memberlist'>
                            <div className='flex_memberlist_item'>
                                <div className='flex_memberlist_row'>
                                  <select>
                                    <option>MemberId</option>
                                  </select>
                                </div>
                            </div>
                            <div className='flex_memberlist_item'>
                                <div className='flex_memberlist_row'>
                                  <input  type='text' placeholder='enter your name'></input>
                                </div>
                            </div>
                            <div className='flex_memberlist_item'>
                                <div className='flex_memberlist_row'>
                                 <button>search</button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className='subadmin_right_container'>
                        
                    </div>
                </div>
            </div>

        </div>
        <div className='memberlist_table'>
        
      <table id="customers">
          <tr>
            <th>S.No.</th>
            <th>Member Details</th>
            <th>AEPS </th>
            <th>Aadhar Pay</th>
            <th>Recharge</th>
            <th>Bill Payout</th>
            <th>DMR</th>
            <th>UIT PAN</th>
            <th>Package.</th>
            <th>Member Type</th>
            <th>Owner</th>
            <th>Date</th>
            <th>Account Status</th>
            <th>Wallet</th>
            <th>Action</th>
          </tr>
          <tbody style={{alignItems:"center"}}>{datatable}</tbody>

          {/* <tbody>
                                {apiData
                                    ? createServiceListItems()
                                    : "No DATA  AVAILABLE"}
                            </tbody> */}
                            </table>
    </div>
    </div>
    
  )
}
