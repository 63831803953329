import styled from 'styled-components';
export const FilterWrapper = styled.div`
height: 74px;
width: 100%;
background: transparent linear-gradient(90deg, #00BAF2 0%, #002970 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 2px 3px #0029700D;
border: 1px solid #00297024;
border-radius: 10px;
opacity: 1;
overflow: auto;
overflow-y: hidden;
display: flex;
align-items: center;
padding: 10px 20px 10px 30px;
font-family: 'Noto Serif', serif;
margin-bottom: 24px;

.filterBox{
	display: flex;
	gap: 10px;
	align-items: center;
	width: 100%;&>div{
		flex-wrap: wrap;
	}
	
	.filter-text{
		font-size: 15px;
	  color: #fff; 
	  font-weight: 600;
	}
	.main_date_input{
		background-color: #fff;
		width: 240px !important;
		align-items: center;
		border-radius: 5px;
	}
	.date_input{
		padding: 0 !important;
		box-shadow: none;
		border-radius: 0;
		width: 105px !important;
	}
	.input-field{
		.input_select_field, option,input{
			padding: 8px 8px;
			box-shadow: 0px 1px 2px #00297026;
            border-radius: 5px;
			background-color: #fff;
			font-size: 12px;
			width: 180px;
			border: 1px solid transparent;
			&:focus{
				border: 1px solid #ffd700;
				outline: none;
			}
		}
		&.date-filter{
			padding: 0;
			box-shadow: 0px 0px 0px #00297026 !important;
            border-radius: 5px;
			background-color: #fff;
			font-size: 12px;
			display: flex;
            align-items: center;
			position: relative;
			 img{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				&.img-1{
					left: 8px;
				}
				&.img-2{
					left: 125px;
				}
				&.img-3{
					left: 240px;
				}
			 }
			& > select{
				
				background-color: #EDFAFE;
				padding-left: 22px;
				max-width: 120px;
				box-shadow: none;
				border-width: 0;
				&:focus-visible,
				&:focus{
					border-width: 0;
					outline: none;
				}
			}

			& > input{
				padding-left: 22px;
				max-width: 120px;
				border-width: 0;
				box-shadow: none;
				&:focus-visible,
				&:focus{
					border-width: 0;
				}
			}
		}
		.filterButton{
			background: #00BAF2 0% 0% no-repeat padding-box;
			box-shadow: 0px 2px 3px #0029700D;
			border: 1px solid #00297024;
			padding: 8px 12px;
			border-radius: 5px;
			color: #fff;
			cursor: pointer;

		}
		&.csv{
	margin-left: auto;
}
	}
}
@media  screen and (max-width: 1280px) {
padding: 0px;
margin-bottom: 24px;
.filterBox{
padding: 0px 10px 0px 10px;
}
}
`;

