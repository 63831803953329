import React from "react";

import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData1 from "../../assets/lotties/msg_success.json";

const MobileVerificationMsg = () => {
    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData1,

    };


    return (
        <div className="main_container">
            <div className="container1">
                <h1>Create New Retailer</h1>
            </div>
            <div className="container_progress">
                <div className="round">
                    <div>
                        <input type="checkbox" checked id="checkbox" />
                        <label for="checkbox"></label>

                    </div>
                    <div style={{ backgroundColor: "#CDCDCD", height: "3px", marginTop: "-33px", width: "400px", border: "1px dashed" }} />
                    <div style={{ marginTop: "4%", fontSize: "12px" }}>Retailer Detail</div>
                </div>
                <div className="round">
                    <div>
                        <input type="checkbox" checked id="checkbox" />
                        <label for="checkbox"></label>
                    </div>
                    <div style={{ backgroundColor: "#CDCDCD", height: "3px", marginTop: "-33px", width: "400px", border: "1px dashed" }} />
                    <div style={{ marginTop: "4%", fontSize: "12px" }}>ID Verification</div>
                </div> <div className="round">
                    <div>
                        <input type="checkbox" checked id="checkbox" />
                        <label for="checkbox"></label>
                    </div>
                    <div style={{ backgroundColor: "#CDCDCD", height: "3px", marginTop: "-33px", border: "1px dashed", width: "0px" }} />
                    <div style={{ marginTop: "4%", fontSize: "12px" }}>Biometric Verification</div>
                </div>
            </div>

            <div className="container_otp">
                <div className="container_lottie">
                    <Lottie options={defaultOptions} height={232} width={232}></Lottie>
                </div>
                <div className="msg">
                    <p style={{ fontSize: "15px" }}>Mobile Verification done Successfully|</p>
                </div>
                <div className="create_button_container">
                    <button className="submit_btn" onClick={() => {
                        navigate("/biometricverification")

                    }}>Sumbit</button>
                </div>
            </div>

        </div>
    );
}
export default MobileVerificationMsg