import React from "react";
import "./Footer.scss";
function Footer() {
  return (
    <footer className="">
      <div className="container-los">
        <div className="footer-text text-center ">
          © 2022 · Terms of use · Privacy policy
        </div>
      </div>
    </footer>
  );
}

export default Footer;
