
import React from "react";
import "./Header.scss";
import logo from "../../assets/img/logo.svg";
import BackButtun from "./BackButtun";



function Header({back=true, backUrl=""}) {
  return (
    <header className="header-weapper">
      <div className="container-los">
        <div className="header flex justify-between">
          <div className="logo">
            <img src={logo} alt="Pay2Mobiles Logo" width={117} height={50} />
          </div>
          <div className="back">
           {  back && <BackButtun backUrl={backUrl} />}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
